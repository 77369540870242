import ClientAccount from "@/modules/clientPortal/account/ClientAccount";
import { ClientPortalLayout } from "@/components";

export default [
  {
    path: "account",
    name: "cp-account",
    component: ClientAccount,
    meta: { layout: ClientPortalLayout },
  },
];

class WeekDay {
  static MONDAY = "MONDAY";
  static TUESDAY = "TUESDAY";
  static WEDNESDAY = "WEDNESDAY";
  static THURSDAY = "THURSDAY";
  static FRIDAY = "FRIDAY";
  static SATURDAY = "SATURDAY";
  static SUNDAY = "SUNDAY";

  static weekdays = {
    [WeekDay.MONDAY]: "Monday",
    [WeekDay.TUESDAY]: "Tuesday",
    [WeekDay.WEDNESDAY]: "Wednesday",
    [WeekDay.THURSDAY]: "Thursday",
    [WeekDay.FRIDAY]: "Friday",
    [WeekDay.SATURDAY]: "Saturday",
    [WeekDay.SUNDAY]: "Sunday",
  };

  static getWeekdayLabel = (weekday) => WeekDay.weekdays[weekday];

  static getWeekDayOptions = () => Object.keys(WeekDay.weekdays).map((weekday) => ({ id: weekday, label: WeekDay.getWeekdayLabel(weekday) }));
}

export default WeekDay;

import gql from "graphql-tag";

export const GET_CLIENT_LOCATION_DETAILS_BY_PK = gql`
  subscription getClientLocation($id: uuid!) {
    clientLocation: clientLocations_by_pk(id: $id) {
      id
      name
      businessType
      insuranceSetting
      client {
        id
        name
        departmentManagers {
          manager {
            id
            firstName
            lastName
            user {
              id
              role
            }
            teamsManaged {
              team {
                id
                name
                accountManager {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      address {
        id
        address1
        address2
        address3
        city
        state
        postalCode
        country
      }
      startDate
      timeZone
      phone
      ageRequirement
      federalTaxID
      isRecruiting
      taxProgram
      deliveryAreaMap
      locationConfiguration
      team {
        id
        name
        accountManager {
          firstName
          lastName
        }
      }
      teamSpecialist {
        id
        employee {
          id
          firstName
          lastName
          role
        }
      }
      partners_aggregate {
        aggregate {
          count
        }
      }
      funnel {
        id
        steps(order_by: { index: asc }) {
          index
          displayName
        }
        questions(order_by: { index: asc }) {
          index
          questionText
        }
        funnelDocuments(order_by: { order: asc }) {
          id
          order
          documentTemplateUse {
            id
            template {
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS_BY_CLIENT = gql`
  query getClientLocations($id: uuid!, $startDate: date, $endDate: date, $limit: Int = 10, $offset: Int = 10, $launchingDate: date) {
    client: clients_by_pk(id: $id) {
      id
      name
      address {
        id
        state
      }
      total: locations_aggregate {
        aggregate {
          count
        }
      }
      locations(where: { startDate: { _gte: $launchingDate } }, limit: $limit, offset: $offset) {
        id
        name
        isRecruiting
        recruitingGoal
        stageCounts {
          stage
          count
        }
        headCountRequests(where: { startDate: { _eq: $startDate } }) {
          count
        }
        onboardingPartners: partners_aggregate(where: { status: { _eq: ONBOARDING } }) {
          aggregate {
            count
          }
        }
        approvedPartners: partners_aggregate(where: { status: { _eq: APPROVED }, approvalDate: { _gte: $startDate, _lte: $endDate } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_DETAILS_LOCATIONS_TAB = gql`
  query getClientLocations($id: uuid!) {
    timePeriod: clients_by_pk(id: $id) {
      id
      timePeriods(where: { type: { _eq: "RECRUITING" } }) {
        id
        startDay
        endDay
      }
    }
  }
`;
export const GET_ALL_LOCATIONS_BY_CLIENT_LIMITED = gql`
  query getClientLocations($id: uuid!) @cached(ttl: 300) {
    client: clients_by_pk(id: $id) {
      id
      name
      address {
        id
        state
      }
      total: locations_aggregate(where: { deleted: { _eq: false } }) {
        aggregate {
          count
        }
      }
      locations(where: { deleted: { _eq: false } }) {
        id
        name
        funnel {
          funnelConfiguration
          onboardingFunnelStatus
        }
      }
    }
  }
`;
export const GET_ALL_LOCATIONS_BY_CLIENT = gql`
  query getClientLocations($id: uuid!, $startDate: date, $endDate: date) @cached(ttl: 300) {
    client: clients_by_pk(id: $id) {
      id
      name
      address {
        id
        state
      }
      total: locations_aggregate(where: { deleted: { _eq: false } }) {
        aggregate {
          count
        }
      }
      locations(where: { deleted: { _eq: false } }) {
        id
        name
        isRecruiting
        recruitingGoal
        startDate
        stageCounts {
          stage
          count
        }
        headCountRequests(where: { startDate: { _eq: $startDate } }) {
          id
          startDate
          endDate
          count
          approved
          total
        }
        onboardingPartners: partners_aggregate(where: { status: { _eq: ONBOARDING } }) {
          aggregate {
            count
          }
        }
        approvedPartners: partners_aggregate(where: { status: { _eq: APPROVED }, approvalDate: { _gte: $startDate, _lte: $endDate } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

//! For temporary funnel
export const AVAILABLE_FUNNEL_DOCUMENTS = gql`
  query AvailableFunnelDocuments($id: uuid) {
    documentTemplateUse(where: { clientLocationID: { _eq: $id }, partnerID: { _is_null: true }, template: { status: { _eq: "published" } } }) {
      id
      status
      template {
        id
        title
        status
      }
    }
  }
`;

export const GET_FUNNEL_DOCUMENTS_TEMPLATE_ID = gql`
  query getTemplateUseID($id: uuid!) {
    clientLocation: clientLocations_by_pk(id: $id) {
      id
      name
      funnel {
        id
        funnelDocuments(order_by: { order: asc }) {
          documentTemplateUse {
            id
          }
        }
      }
    }
  }
`;

<template>
  <flyout name="single-comms-flyout" title="Single-Communications" full-screen @cancel="done">
    <!-- <template #header>
      <sticky-header title="Send Message">
        <div class="button-group">
          <button :disabled="quillEmpty" class="button" @click="triggerSend">Send</button>
          <button class="button secondary" @click="done">Cancel</button>
        </div>
      </sticky-header>
    </template> -->
    <main class="off-white" v-if="$props.singleObj[0].name">
      <div class="container bulk-email-container">
        <div class="content">
          <div class="to-bulk-emails-tags">
            <h4>To</h4>
            <div class="tags">
              <div class="tag">
                <div class="info">
                  <p>{{ $props.singleObj[0].name }}</p>
                </div>
              </div>
            </div>
          </div>
          <tabs :tabs="commsTabs" :use-history="false">
            <template #email>
              <validation-observer ref="emailForm" slim>
                <div class="grid email">
                  <div class="col-4_md-6_sm-12">
                    <div class="field">
                      <form-input v-model="subject" name="subject" label="Subject" required placeholder="Email Subject" @input="update($event)" />
                    </div>
                  </div>
                  <div class="col-6_md-6_sm-12"></div>
                  <div class="col-6_md-12">
                    <new-editor @json="saveJSON" @html="saveHTML" />
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="quillEmpty" class="button" @click="sendEmail">Send</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
            <template #sms>
              <validation-observer ref="smsForm" slim>
                <div class="grid sms">
                  <div class="col-6_md-12">
                    <textarea v-model="smsBody" class="template-preview" @input="messageVal($event.target.value)"></textarea>
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="quillEmpty" class="button" @click="sendText">Send</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
          </tabs>
        </div>
      </div>
    </main>
  </flyout>
</template>
<style>
.bulk-email-container .to-bulk-emails-tags .tags {
  display: inline-flex;
  flex-flow: wrap;
  background: white;
  padding: 10px;
  max-height: 120px;
  overflow-y: scroll;
}
.bulk-email-container .to-bulk-emails-tags .tags .tag {
  margin-right: 8px;
}
.bulk-email-container .white-well {
  display: none;
}
</style>
<script>
import { Flyout } from "@/components";
import NewEditor from "@/components/editor/NewEditor";
import { ValidationObserver } from "vee-validate";
// import StickyHeader from "@/components/layout/StickyHeader";
import Tabs from "@/components/tabs/Tabs";
import FormInput from "@/components/forms/fields/FormInput";
import { mapGetters } from "vuex";
// import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  components: {
    FormInput,
    Flyout,
    // StickyHeader,
    NewEditor,
    Tabs,
    ValidationObserver,
  },
  props: {
    candidate: {
      type: Object,
      required: false,
      default: () => {},
    },
    singleObj: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentStep: {
      type: Object,
      required: false,
      default: () => {},
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {},
    },
    displayEditor: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data: () => ({
    quillEmpty: true,
    invokeSms: false,
    invokeEmail: false,
    emailText: {},
    smsText: {},
    template: {
      title: "",
      status: "",
    },
    html: "",
    // body: `Hi, You have some information in your account that needs to be updated. Please login to the DDI app and update the necessary information. Failure to do so may result in a hold on your pay.Contact us if you need anything`,
    subject: "",
    smsBody: "",
    partners: [],
  }),
  // mounted() {
  //   this.emailText = { ops: [{ insert: this.body }] };
  //   this.smsText = { ops: [{ insert: this.body }] };
  // },
  computed: {
    ...mapGetters(["getUserID", "getUsername"]),
    charsUsed() {
      return this.smsText.length;
    },
    commsTabs() {
      return [
        { key: "email", label: "Email Message" },
        { key: "sms", label: "Text Message" },
      ];
    },
    startTab() {
      return this.$props.commsType == "EMAIL" ? 0 : 1;
    },
    userName() {
      if (this.$props.singleObj.length > 0) {
        return this.$props.singleObj[0].name;
      } else {
        return "";
      }
    },
  },
  methods: {
    update(val) {
      if (val.length > 0) {
        this.quillEmpty = false;
      } else {
        this.quillEmpty = true;
      }
    },
    messageVal(value) {
      if (value.length > 0) {
        this.quillEmpty = false;
        this.invokeSms = true;
        this.invokeEmail = false;
      } else {
        this.quillEmpty = true;
      }
    },
    sendText() {
      let objects = {};
      this.$props.singleObj.forEach((item) => {
        let customObj = {
          phone: item.phone,
          text: encodeURIComponent(this.smsBody),
          partnerID: item.partnerID,
          userID: item.userID,
          // sendDate: DateTime.utc().toFormat("yyyy-MM-dd"),
        };
        objects=customObj;
      });
      return restApi.post('/onboard/sendSMS',encodeWithParam(objects))
        .then(() => {
          this.done();
        })
        .catch((err) => this.$log.error(err));
    },

    sendEmail() {
      let objects = {};
      this.$props.singleObj.forEach((item) => {
        let customObj = {
          userID: item.userID,
          from: this.getUsername,
          html: encodeURIComponent(this.html),
          partnerID: item.partnerID,
          subject: this.subject,
          to: item.communication_email,
        };
        objects=customObj;
      });
      return restApi.post('/onboard/sendDirectEmail',encodeWithParam(objects))
        .then(() => {
          this.done();
        })
        .catch((err) => this.$log.error(err));
      // return this.$apollo.mutate({ mutation: SEND_EMAIL_BULK, variables: { objects } }).then(() => {
      //   this.done();
      // });
    },
    isQuillEmpty(status) {
      this.quillEmpty = status;
    },
    done() {
      if (this.invokeEmail) {
        this.$refs.emailForm.reset();
      }
      this.subject = '';
      this.smsBody = '';
      this.invokeSms = false;
      this.invokeEmail = false;
      this.$props.onClose();
    },
    saveHTML(data) {
      this.html = data;
    },
    // userName() {
    //   debugger
    //   if (this.$props.singleObj.length > 0) {
    //     return this.$props.singleObj[0].name;
    //   } else {
    //     return "";
    //   }
    // },
    saveJSON(data) {
      this.json = data;
      if (data.body.rows[0].columns[0].contents.length > 0 && this.$props.singleObj.length > 0) {
        this.quillEmpty = false;
        this.invokeEmail = true;
        this.invokeSms = false;
      } else {
        this.quillEmpty = true;
      }
    },
    triggerSend() {
      if (this.invokeSms) {
        this.sendText();
      }
      if (this.invokeEmail) {
        this.sendEmail();
      }
    },
    reformatPhoneNumber(phone){
      const number = phone.replace(/[^\d]/g, ''); 
        return number
    }
  },
};
</script>

<template>
  <div v-if="getRole != 'LOCATION_MANAGER' ">
    <hr />
    <expansion-panel ref="personalFormPanel" label="View/edit vehicle info">
      <form class="personal-details-form">
        <div class="module">
          <a v-if="!isRoleCoordinator && !editingPersonalDetails" class="button secondary" @click="editPersonalDetails">Edit Vehicle Info</a>
          <save-cancel v-if="editingPersonalDetails" emit-cancel @onSave="saveVehicleDetails" @onCancel="cancelPersonalDetails" />
        </div>
        <p v-if="errorFlag" class="error-message">Please correct errors before proceeding.</p>
        <div class="module">
          <h2>Vehicle Info</h2>
          <form-input v-model="piiDetails.make" label="Make" required :disabled="!editingPersonalDetails || isRoleOS" />
          <form-input v-model="piiDetails.model" label="Model" required :disabled="!editingPersonalDetails || isRoleOS" />
          <form-input v-model="piiDetails.year" label="Year" required :disabled="!editingPersonalDetails" />
          <form-input v-model="piiDetails.color" label="Colour" required :disabled="!editingPersonalDetails" />
          <form-input v-model="piiDetails.licensePlate" label="Licence Plate" required :disabled="!editingPersonalDetails" />
        </div>
        <div class="module">
          <save-cancel  v-if="editingPersonalDetails" emit-cancel @onSave="saveVehicleDetails" @onCancel="cancelPersonalDetails" />
        </div>
      </form>
    </expansion-panel>
  </div>
</template>
<script>
import { ExpansionPanel, FormInput, SaveCancel } from "@/components";
import { mapGetters } from "vuex";

// import { restApi } from "@/http/http.config";
// import {encodeWithParam} from "@/util/Base64Encoding";


export default {
  components: { FormInput, ExpansionPanel, SaveCancel },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: true,
      default: () =>{}
    }
  },
  data: () => ({
    editingPersonalDetails: false,
    errorFlag: false,
    clientName:"",
    disableAddress: false,
    addressSuggestions: [],

    piiDetails: {
      make: "",
      model: "",
      color: "",
      year: "",
      licensePlate: ""
    }
  }),
  watch: {
    personalDetails(value) {
      this.piiDetails.make = value.vehicle_make;
      this.piiDetails.color = value.vehicle_color
      this.piiDetails.year = value.vehicle_year;
      this.piiDetails.licensePlate = value.vehicle_plate;
      this.piiDetails.model = value.vehicle_model;

      this.editingPersonalDetails = false;
    },
    
  },
  mounted() {
    this.clientName=process.env.VUE_APP_CLIENT_NAME;
    this.piiDetails.make = this.$props.personalDetails.vehicle_make;
    this.piiDetails.color =this.$props.personalDetails.vehicle_color
    this.piiDetails.year =this.$props.personalDetails.vehicle_year;
    this.piiDetails.licensePlate =this.$props.personalDetails.vehicle_plate;
    this.piiDetails.model =this.$props.personalDetails.vehicle_model;
  },
  computed: {
    ...mapGetters(["getRole"]),
    candidateID() {
      return this.$props.candidate ? this.$props.candidate.id : null;
    },
    isRoleCoordinator() {
      return localStorage.getItem("role") == "COORDINATOR";
    },
    isRoleOS() {
      return localStorage.getItem("role") == "ONBOARDING_SPECIALIST";
    },
    
  },
  methods: {
    refresh() {
      this.$emit("refresh", {});
    },
    editPersonalDetails() {
      this.editingPersonalDetails = true;
      this.toggleMaskDetails(false);
      this.disableAddress = false;

    },
    toggleMaskDetails(mask) {
      const decoded = mask ? this.$props.personalDetails : this.$props.personalDetails.decoded;
      this.piiDetails.make = decoded.vehicle_make;
      this.piiDetails.model = decoded.vehicle_model;
      this.piiDetails.year = decoded.vehicle_year;
      this.piiDetails.color = decoded.vehicle_color;
      this.piiDetails.licensePlate = decoded.vehicle_plate;
    },
    saveVehicleDetails() {

      if(this.piiDetails.make == '' || this.piiDetails.model == '' || this.piiDetails.year == '' || this.piiDetails.color == '' || this.piiDetails.licensePlate == ''){
        this.errorFlag = true;
      }else{
        this.errorFlag = false;
        this.piiDetails.make = this.piiDetails.make.trim();
        this.piiDetails.model = this.piiDetails.model.trim();
        this.piiDetails.year = this.piiDetails.year.trim();
        this.piiDetails.color = this.piiDetails.color.trim();
        this.piiDetails.licensePlate = this.piiDetails.licensePlate.trim();
        
    
        const obj = Object.assign({}, this.piiDetails);
        this.$emit("onSaveVehicleInfo", obj);
        this.cancelPersonalDetails();
      }

      
    },
    cancelPersonalDetails() {
      this.editingPersonalDetails = false;
      this.toggleMaskDetails(true);
      this.disableAddress = false;
      this.errorFlag = false;
      this.addressSuggestions = []
    },
    isMasked(value){
      return value.includes("*")
    },

  },
};
</script>
<style scoped>
.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 5;
  cursor: pointer;
}
.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}
.suggestion-item:hover {
  background-color: lightgray;
}
</style>
<template>
  <base-modal :id="invoiceModalName" :full="true">
    <sticky-header title="Create Custom Invoice">
      <div class="button-group">
        <h4>{{ clientName }} - {{ invoiceName }}</h4>
        <div>
          <button class="button" @click="save()">Create</button>
          <button class="button secondary" @click="backBtn">Back</button>
          <button class="button secondary" @click="cancelBtn">Cancel</button>
        </div>
      </div>
    </sticky-header>
    <div class="container background-style">
      <a class="pdf-btn" @click="downloadPDF"><i class="icon-download"></i>PDF</a>
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="htmlToPdfOptions"
        ><section slot="pdf-content" v-html="invoiceHtml"></section>
      </vue-html2pdf>
      <section slot="content" ref="content">
        <div v-if="$apollo.queries.invoice.loading || loadingFile" class="content">...loading</div>
        <div v-if="!$apollo.queries.invoice.loading || !loadingFile" class="content" :style="{ backgroundColor: '#fff', padding: '0 50px' }">
          <main>
            <div class="module-xlarge">
              <div class="grid">
                <div class="col-4">
                  <div class="module">
                    <h4>Delivery Drivers, Inc.</h4>
                    <div class="businessAddress">
                      <p>2 Venture, Suite 403</p>
                      <p>Irvine, CA 92618</p>
                      <p>(888) 334-9675 Ext. 4</p>
                      <p>billing@ddiwork.com</p>
                      <p>Vendor # 042513</p>
                    </div>
                  </div>
                  <div class="module">
                    <h4>Bill To</h4>
                    <div class="businessAddress">
                      <p>{{ clientName }}</p>
                      <p>{{ clientAddress1 }}</p>
                      <p>{{ clientAddress2 }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="grid-right" :style="{ height: '50px', marginBottom: '75px' }">
                    <div v-if="invoice.client.logo"><file-viewer :image-id="invoice.client.logo" height="50px" width="auto" @loading="fileLoading" /></div>
                    <img src="@/assets/images/logos/ddi-logo.svg" alt="" :style="{ height: '75px', width: 'auto' }" />
                  </div>
                  <h2>Invoice # {{ invoiceNumber }}</h2>
                  <div class="table-container">
                    <table>
                      <tbody>
                        <tr>
                          <td>Invoice Date</td>
                          <td class="flex-right">{{ invoiceDate | formatDate }}</td>
                        </tr>
                        <tr>
                          <td>Work Period</td>
                          <td class="flex-right">{{ workPeriod }}</td>
                        </tr>
                        <tr>
                          <td>Invoice Due Date</td>
                          <td class="flex-right">{{ invoiceDueDate | formatDate }}</td>
                        </tr>
                        <tr>
                          <td>Terms</td>
                          <td class="flex-right">{{ terms }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Memo</th>
                    <th class="right-align">Amount</th>
                    <th class="cell5"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in totalLineItems" :key="index">
                    <td>{{ item.chartOfAccounts }}</td>
                    <td>{{ item.memo }}</td>
                    <td class="cell20 right-align"><span class="icon-dollar-sign" />{{ formatAmount(item.amount) }}</td>
                    <td class="cell5">
                      <a v-if="item.editable && editing" @click="deleteLineItem(index)"> <i class="icon-trash-2"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td class="weight700">Total</td>
                    <td></td>
                    <td class="weight700 right"><span class="icon-dollar-sign" />{{ sum }}</td>
                    <td class="cell5"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p :style="{ 'margin-top': '40px' }">
              <a @click="add"><i class="icon-plus-circle"></i>Add Invoice Line Item</a>
            </p>
          </main>
        </div>
      </section>
    </div>
    <editable-invoice-flyout v-if="isFlyoutShowing" @save="addLineItem" />
    <div id="modal-delete-line-1" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <h5>Positive Adjustments</h5>
              <p>Deleting a line item from an invoice cannot be undone.</p>
            </div>
            <div class="button-group close-top">
              <a class="button warning" data-micromodal-close @click="deleteLineModal">Delete</a>
              <a class="button secondary" data-micromodal-close>Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import VueHtml2pdf from "vue-html2pdf";
import { GET_CUSTOM_INVOICE } from "@/modules/admin/accounting/archive/graph/queries";
import { ADD_LINEITEM } from "@/modules/admin/accounting/invoicing/mutations";
import { formatDate } from "@/filters";
import FileViewer from "@/components/files/FileViewer";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { mapActions, mapGetters } from "vuex";
import EditableInvoiceFlyout from "@/modules/admin/accounting/invoicing/EditableInvoiceFlyout";

export default {
  name: "CreateInvoiceModal",
  components: { BaseModal, FileViewer, StickyHeader, VueHtml2pdf, EditableInvoiceFlyout },
  props: {
    invoiceId: {
      required: true,
      default: "",
    },
    invoiceModalName: {
      type: String,
      required: false,
      default: "invoiceModalName",
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    dateInvoiced: {
      type: String,
      required: true,
    },
    defaultAddress: {
      type: Boolean,
      required: true,
    },
    addressObj: {
      type: Object,
      required: false,
      default: () => {},
    },
    status: String,
  },
  data() {
    return {
      invoiceHtml: "",
      loadingFile: false,
      editing: true,
      flyoutOverlay: false,
      invoice: { client: { logo: "" } },
      htmlToPdfOptions: {
        filename: "Invoice",
        html2canvas: { useCORS: true, height: 1024, logging: true },
      },
      lineItems: [],
      tempLineItems: [],
      totalLineItems: [],
      deleteIndex: "",
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    invoiceDueDate() {
      return this.invoice?.dueDate ? this.invoice?.dueDate : "";
    },
    invoiceDate() {
      return this.dateInvoiced;
    },
    clientName() {
      return this.invoice?.client?.name ? this.invoice.client.name : "";
    },
    buttonText() {
      return this.editing ? "Save" : "Approve Invoice";
    },
    clientAddress1() {
      if (Object.keys(this.$props.addressObj).length > 1) {
        const address = this.$props.addressObj.form;
        return `${address.address1} ${address.address2}`;
      } else if (this.invoice?.address) {
        return `${this.invoice.address?.address1} ${this.invoice.address.address2}`;
      } else if (this.invoice?.invoiceTemplate?.address) {
        return `${this.invoice?.invoiceTemplate?.address?.address1} ${this.invoice?.invoiceTemplate?.address?.address2}`;
      } else {
        return "";
      }
    },
    clientAddress2() {
      if (Object.keys(this.$props.addressObj).length > 1) {
        const address = this.$props.addressObj.form;
        return `${address.city} ${address.state} ${address.postalCode}`;
      } else if (this.invoice?.address) {
        return `${this.invoice.address?.city} ${this.invoice.address?.state} ${this.invoice.address?.postalCode}`;
      } else if (this.invoice?.invoiceTemplate?.address) {
        return `${this.invoice?.invoiceTemplate?.address?.city} ${this.invoice?.invoiceTemplate?.address?.state} ${this.invoice?.invoiceTemplate?.address?.postalCode}`;
      } else {
        return "";
      }
    },
    terms() {
      return this.invoice.terms ? this.invoice.terms : "";
    },
    workPeriod() {
      return `${formatDate(this.startDate)} - ${formatDate(this.endDate)}`;
    },
    invoiceNumber() {
      if (this.invoice.invoiceNumber) {
        return this.invoice.invoiceNumber.toString().padStart(10 - this.invoice.invoiceNumber.toString().length, "0");
      } else {
        return 0;
      }
    },
    sum() {
      return this.totalLineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },
    invoiceName() {
      return this.invoice?.invoice?.name;
    },
  },

  watch: {
    tempLineItmes() {
      this.totalLineItems = [...this.lineItems, ...this.tempLineItmes];
    },
  },
  methods: {
    addLineItem(data) {
      let customObj = {
        amount: Number(data.amount),
        chartOfAccounts: data.chartOfAccounts,
        memo: data.memo,
        editable: true,
        type: "CUSTOM",
      };
      if (this.tempLineItmes == undefined) {
        this.tempLineItmes = [];
      }
      this.tempLineItmes.push(customObj);
      this.totalLineItems = this.tempLineItmes;
    },
    deleteLineItem(index) {
      this.deleteIndex = index;
      micromodal.show("modal-delete-line-1");
    },

    deleteLineModal() {
      this.totalLineItems.splice(this.deleteIndex, 1);
    },
    fileLoading(value) {
      this.loadingFIle = value;
    },
    ...mapActions(["showFlyout"]),
    add() {
      this.flyoutOverlay = true;
      this.showFlyout("edit-invoice");
      document.body.style.overflow = "hidden";
    },
    editCancel() {
      this.editing = false;
      this.tempLineItmes = [];
    },
    cancelBtn() {
      this.totalLineItems = [];
      this.tempLineItmes = [];
      this.$emit("resetForm");
      this.editing = true;
      micromodal.close("create-invoice", {});
      micromodal.close("InvoiceArchiveCreateCustomInvoice", {});
      document.body.style.overflow = "visible";
    },
    backBtn() {
      micromodal.close(this.invoiceModalName);
    },
    downloadPDF() {
      const content = this.$refs.content;
      this.invoiceHtml = content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    formatAmount(int) {
      return parseFloat(int).toLocaleString();
    },

    save() {
      this.editing = false;
      const lineItemsAdd = this.invoice.invoice;
      lineItemsAdd.lineItems = this.totalLineItems;
      lineItemsAdd.total = this.sum;
      this.$apollo
        .mutate({
          mutation: ADD_LINEITEM,
          variables: {
            invoiceId: this.invoiceId,
            invoice: lineItemsAdd,
          },
        })
        .then(() => {
          this.cancelBtn();
        });
    },
  },
  apollo: {
    invoice: {
      fetchPolicy: "no-cache",
      query: GET_CUSTOM_INVOICE,
      skip() {
        return !this.invoiceId;
      },
      variables() {
        return {
          id: this.invoiceId,
        };
      },
      result({ data }) {
        if (data?.invoices_by_pk) {
          this.invoice = data.invoices_by_pk;
          this.lineItems = data?.invoices_by_pk?.invoice?.lineItems ? data.invoices_by_pk.invoice.lineItems : [];
          this.totalLineItems = this.lineItems;
        }
      },
    },
  },
};
</script>
<style scoped>
.businessAddress p {
  margin-bottom: 8px;
}

.table-container tbody:nth-child(2) tr:nth-last-child(2) {
  border-bottom: 4px solid var(--primary);
}

.weight700 {
  font-weight: 700;
}

.background-style {
  background-color: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>

<template>
  <fragment>
    <h2>Request Partners</h2>
    <div class="module-xlarge">
      <h3>Current Active Partners</h3>
      <div class="flex">
        <div class="current-active-partners">
          <div class="active-partners">{{ activePartners }}</div>
          <div class="title">Current Active Roster</div>
        </div>
        <tooltip
          id="active-partners-tooltip"
          title=""
          description="Active partners are partners who have rendered services for this location in the latest completed pay cycle and newly approved partners."
          hover
        />
      </div>
    </div>
    <div class="module-xlarge">
      <h3>New and Active Requests</h3>
      <data-table :headers="activeRequestHeaders" :entries="activeRequests">
        <template #week="{ item }">{{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}</template>
        <template #count="{ item }">
          <strong v-if="!item.count && !item.editing">no request</strong>
          <fragment v-if="item.count && !item.editing">{{ item.count }}</fragment>
          <div v-if="item.editing" class="spread-number">
            <input
              :ref="item.key"
              v-model="item.count"
              :name="item.key"
              type="number"
              min="1"
              max="10"
              @input="updateCount(item, $event)"
              @keyup.enter="submit(item)"
              @focus="$event.target.select()"
            />
          </div>
        </template>
        <template #actions="{ item }">
          <a v-if="!item.count && !item.editing && !item.locked" @click="edit(item)">Make Request</a>
          <a v-if="item.count && !item.editing && !item.locked" @click="edit(item)">Edit</a>
          <fragment v-if="item.locked">locked</fragment>
          <a v-if="item.editing" class="button secondary small" @click="submit(item)">Submit</a>
        </template>
      </data-table>
    </div>
    <div class="module-xlarge">
      <h3>Past Requests</h3>
      <p class="label">Filters</p>
      <base-filter name="Show the Last" :options="filterOptions" type="radio" @onFilter="selectedFilters = $event" />
      <data-table :headers="headers" :entries="filteredList">
        <template #week="{ item }">{{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}</template>
        <template #fulfilled="{ item }"><small-progress-bar :complete="item.approved" :total="item.count" /></template>
      </data-table>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { Tooltip, BaseFilter, DataTable, TableHeader, SmallProgressBar } from "@/components";
import { GET_LOCATION_REQUESTS } from "@/modules/clientPortal/queries";
import DateTime from "luxon/src/datetime";
import { SAVE_HEAD_COUNT_REQUEST } from "@/modules/clientPortal/mutations";

import { mapGetters } from "vuex";
export default {
  components: { SmallProgressBar, DataTable, Tooltip, Fragment, BaseFilter },
  filters: {
    formatDate(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("dd LLL");
    },
  },
  data: () => ({
    selectedFilters: [],
    activeRequests: [],
    requestUpdate: {},
  }),
  computed: {
    ...mapGetters(["getUsername"]),
    filterOptions() {
      return [
        { id: "filter-show-last-1", name: "last1", label: "1 Month" },
        { id: "filter-show-last-3", name: "last3", label: "3 Months" },
        { id: "filter-show-last-6", name: "last6", label: "6 Months" },
        { id: "filter-show-last-year", name: "lastYear", label: "1 Year" },
        { id: "filter-show-last-all", name: "allRequests", label: "All Requests" },
      ];
    },
    headers() {
      return [
        new TableHeader({ name: "week", cellContents: "week", headerClass: "cell20", label: "Week" }),
        new TableHeader({ name: "count", cellContents: "count", headerClass: "cell20", label: "Additional Partners Requested" }),
        new TableHeader({ name: "approved", cellContents: "approved", headerClass: "cell20", label: "Partners Approved" }),
        new TableHeader({ name: "fulfilled", cellContents: "fulfilled", headerClass: "cell20", label: "Request Fulfilled" }),
        new TableHeader({ name: "total", cellContents: "total", headerClass: "cell20", label: "Resulting Active Partners" }),
      ];
    },
    filteredList() {
      if (this.location && this.location.headCountRequests) {
        return this.location.headCountRequests.filter((headCountRequest) => {
          if (this.isThisWeek(headCountRequest.startDate) || this.isNextWeek(headCountRequest.startDate) || this.isFiltered(headCountRequest.startDate)) {
            return false;
          }
          return true;
        });
      }
      return [];
    },
    startOfWeek() {
      const startweek = 6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      return DateTime.utc().setZone("America/Los_Angeles").startOf("day").set({ weekNumber: startweek, weekday: 6 });
    },
    endOfWeek() {
      return DateTime.fromISO(this.startOfWeek).endOf("day").plus({ days: 6 });
    },
    startOfNextWeek() {
      return DateTime.fromISO(this.startOfWeek).startOf("day").plus({ days: 7 });
    },
    endOfNextWeek() {
      return DateTime.fromISO(this.endOfWeek).endOf("day").plus({ days: 7 });
    },
    activePartners() {
      if (this.location && this.location.partners) {
        return this.location.partners.aggregate.count;
      }
      return 0;
    },
    noRequest() {
      if (this.location && this.location.headCountRequests) {
        return this.location.headCountRequests.filter((headCountRequest) => headCountRequest.startDate === this.startOfWeek).length === 0;
      }
      return true;
    },
    activeRequestHeaders() {
      return [
        new TableHeader({ name: "week", cellContents: "week", headerClass: "cell60", label: "Week" }),
        new TableHeader({
          name: "count",
          cellContents: "count",
          headerClass: "cell20",
          label: "Additional Partners Requested",
          cellClass: (item) => (!item.count && !item.editing ? "red" : ""),
        }),
        new TableHeader({ name: "actions", cellContents: "actions", headerClass: "cell20", label: "", cellClass: "table-actions" }),
      ];
    },
    activeWeeks() {
      return [
        { key: "thisweek", startDate: this.startOfWeek, endDate: this.endOfWeek },
        { key: "nextweek", startDate: this.startOfNextWeek, endDate: this.endOfNextWeek },
      ];
    },
    locationID() {
      return this.$route.params.locationID;
    },
  },
  methods: {
    isThisWeek(date) {
      return this.$options.filters.formatDate(date) === this.$options.filters.formatDate(this.startOfWeek);
    },
    isNextWeek(date) {
      return this.$options.filters.formatDate(date) === this.$options.filters.formatDate(this.startOfNextWeek);
    },
    isFiltered(date) {
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        const filter = this.selectedFilters[0];
        const datetime = DateTime.fromISO(date);
        switch (filter) {
          case "last1":
            return datetime < DateTime.utc().startOf("day").minus({ months: 1 });
          case "last3":
            return datetime < DateTime.utc().startOf("day").minus({ months: 3 });
          case "last6":
            return datetime < DateTime.utc().startOf("day").minus({ months: 6 });
          case "lastYear":
            return datetime < DateTime.utc().startOf("day").minus({ years: 1 });
          case "allRequests":
            return true;
        }
      }
    },
    updateCount(request, event) {
      this.requestUpdate[`${request.key}`] = event.target.value;
    },
    edit(request) {
      this.activeRequests = this.activeRequests.map((activeRequest) => {
        if (activeRequest.key === request.key) {
          return { ...activeRequest, editing: true };
        }
        return activeRequest;
      });
      this.$nextTick(() => this.$refs[`${request.key}`].focus());
    },
    submit(request) {
      const headCountRequest = {
        ...request,
        startDate: DateTime.fromISO(request.startDate).toFormat("yyyy-MM-dd"),
        endDate: DateTime.fromISO(request.endDate).toFormat("yyyy-MM-dd"),
        requestedBy: this.getUsername,
        requestDate: new Date(),
        clientLocationID: this.locationID,
      };
      delete headCountRequest.locked;
      delete headCountRequest.key;
      delete headCountRequest.editing;
      delete headCountRequest["__typename"];
      this.$apollo.mutate({ mutation: SAVE_HEAD_COUNT_REQUEST, variables: { headCountRequest } }).finally(() => {
        const requestCount = this.requestUpdate[`${request.key}`];
        this.activeRequests = this.activeRequests.map((activeRequest) => {
          if (activeRequest.key === request.key) {
            return {
              ...activeRequest,
              editing: false,
              count: request.count || requestCount,
            };
          }
          return activeRequest;
        });
      });
    },
  },
  apollo: {
    location: {
      fetchPolicy: "no-cache",
      query: GET_LOCATION_REQUESTS,
      variables() {
        return {
          locationID: this.$route.params.locationID,
        };
      },
      result({ data }) {
        const location = data.location;
        if (location && location.headCountRequests) {
          this.activeRequests = this.activeWeeks.map((week) => {
            if (week.key === "thisweek") {
              const local = DateTime.local().setZone("America/Los_Angeles");
              const rezoned = local.setZone("America/Los_Angeles").set({ weekday: 4, hour: 12, minutes: 0, seconds: 0 });
              const locked = rezoned < local;
              week.locked = locked;
            }
            const findWeek = this.location.headCountRequests.findIndex((headCountRequest) => {
              return this.$options.filters.formatDate(headCountRequest.startDate) === this.$options.filters.formatDate(week.startDate);
            });
            if (findWeek >= 0) {
              const request = this.location.headCountRequests[findWeek];
              return { ...request, ...week };
            }
            return { ...week };
          });
        } else {
          this.activeRequests = this.activeWeeks;
        }
      },
    },
  },
};
</script>

<template>
  <fragment>
    <div class="filters">
      <div v-click-outside="hidePopup('nameChooser')">
        <ul class="button-bar list-unstyled pod-switcher" @click="showPopup('nameChooser')">
          <li class="non-button"><i class="icon icon-user-plus"></i></li>
          <li class="non-button">{{ selectedEmployee.name }} ({{ roleAbbreviation(selectedEmployee.role) }})</li>
          <li class="button ghost">Change</li>
        </ul>
        <nav v-show="popup === 'nameChooser'" class="popup-nav pod-switcher-menu name-chooser">
          <div class="name-chooser-content">
            <label><a class="button xs ghost right" @click="resetToMe">Reset to Myself</a><i class="icon icon-user-plus"></i> Onboarding Rep</label>
            <input v-model="employeeFilter" name="filterName" type="text" placeholder="Filter Name" />
            <div class="employee-names">
              <data-table table-id="employeeNames" :entries="filteredEmployees" :headers="employeeHeaders" hide-headers row-clickable @onRowSelected="updateSelection" />
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="filters">
      <div v-click-outside="hidePopup('weekChooser')">
        <ul class="button-bar list-unstyled pod-switcher" :class="{ 'next-week': isNextWeek, 'previous-week': isPreviousWeek }" @click="showPopup('weekChooser')">
          <li class="non-button"><i class="icon icon-calendar"></i></li>
          <li class="non-button time-period">{{ getTimePeriod }}</li>
          <!-- <li class="button ghost">Change</li> -->
        </ul>
        <nav v-show="popup === 'weekChooser'" class="popup-nav pod-switcher-menu week-chooser">
          <div class="week-chooser-content">
            <label><i class="icon icon-calendar"></i> Recruiting Week</label>
            <div class="radio-custom">
              <input id="this-week" v-model="timePeriod" type="radio" name="recruiting" value="THIS_WEEK" checked="checked" @change="updateWeek" />
              <label for="this-week">
                <span class="label-container">This Week: {{ startOfWeek | formatDateDDMMM }} - {{ endOfWeek | formatDateDDMMM }}</span>
              </label>
            </div>
            <!-- <div class="radio-custom">
              <input id="next-week" v-model="timePeriod" type="radio" name="recruiting" value="NEXT_WEEK" @change="updateWeek" />
              <label for="next-week">
                <span class="label-container">Next Week: {{ startOfNextWeek | formatDateDDMMM }} - {{ endOfNextWeek | formatDateDDMMM }}</span>
              </label>
            </div>
            <div class="radio-custom">
              <input id="previous-week" v-model="timePeriod" type="radio" name="recruiting" value="PREVIOUS_WEEK" @change="updateWeek" />
              <label for="previous-week">
                <span class="label-container">Last Week: {{ startOfPreviousWeek | formatDateDDMMM }} - {{ endOfPreviousWeek | formatDateDDMMM }}</span>
              </label>
            </div> -->
          </div>
        </nav>
      </div>
    </div>
  </fragment>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Fragment } from "vue-fragment";
import DateTime from "luxon/src/datetime";
import ClickOutside from "vue-click-outside";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { formatDateDDMMM } from "@/filters";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "PodSwitcher",
  components: { DataTable, Fragment },
  directives: { ClickOutside },
  props: {
    employees: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    selectedEmployee: {},
    popup: "",
    employeeFilter: "",
    timePeriod: "THIS_WEEK",
    existingHCR: []
  }),
  methods: {
    ...mapActions(["updateSelectedEmployee", "updateStartDate", "updateEndDate","updateLocationsFilterOptions"]),
    showPopup(id) {
      if (this.popup === id) {
        this.hidePopup(id)();
      } else {
        this.popup = id;
      }
    },
    hidePopup(id) {
      return () => {
        if (id === this.popup) {
          this.popup = "";
        }
      };
    },
    roleAbbreviation(role) {
      if (role) {
        const words = role.split("_");
        return words.map((word) => word.charAt(0)).join("");
      }
      return "";
    },
    updateSelection(employee) {
      //console.log(employee,"emp")
      this.selectedEmployee = employee;
      this.hidePopup("nameChooser")();
      this.updateSelectedEmployee(this.selectedEmployee);
      this.getUserLocations();
    },
    getUserLocations() {
      restApi.post('onboard/getUserLocations',encodeWithParam({userID: this.$store.getters.getSelectedEmployee.id})).then((data)=>{
      this.updateLocationsFilterOptions(data.data.result);
    })
    },
    updateWeek() {
      this.hidePopup("weekChooser")();
      this.updateStartDate(this.startDate);
      this.updateEndDate(this.endDate);
    },
    resetToMe() {
      this.updateSelection({ id: this.getUserID, name: this.getFullName, role: this.getRole });
      this.updateWeek();
    },
  },
  computed: {
    ...mapGetters(["getFullName", "getRole", "getUserID"]),
    startOfWeek() {
      return DateTime.fromISO(this.existingHCR[0]?.startDate)
    },
    endOfWeek() {
      return DateTime.fromISO(this.existingHCR[0]?.endDate)
    },
    startOfNextWeek() {
      return DateTime.fromISO(this.startOfWeek).startOf("day").plus({ days: 7 });
    },
    endOfNextWeek() {
      return DateTime.fromISO(this.endOfWeek).endOf("day").plus({ days: 7 });
    },
    startOfPreviousWeek() {
      return DateTime.fromISO(this.startOfWeek).startOf("day").minus({ days: 7 });
    },
    endOfPreviousWeek() {
      return DateTime.fromISO(this.endOfWeek).endOf("day").minus({ days: 7 });
    },
    employeeHeaders() {
      return [new TableHeader({ name: "name", cellContents: "name", headerClass: "cell80" }), new TableHeader({ name: "role", cellContents: (employee) => this.roleAbbreviation(employee.role) })];
    },
    filteredEmployees() {
      return this.$props.employees.filter((employee) => employee.name.toLowerCase().includes(this.employeeFilter.toLowerCase()) && employee.status == "ACTIVE");
    },
    getTimePeriod() {
      return this.isThisWeek
        ? `This Week: ${formatDateDDMMM(this.startOfWeek)} - ${formatDateDDMMM(this.endOfWeek)}`
        : this.isNextWeek
        ? `Next Week: ${formatDateDDMMM(this.startOfNextWeek)} - ${formatDateDDMMM(this.endOfNextWeek)}`
        : `Last Week: ${formatDateDDMMM(this.startOfPreviousWeek)} - ${formatDateDDMMM(this.endOfPreviousWeek)}`;
    },
    isThisWeek() {
      return this.timePeriod === "THIS_WEEK";
    },
    isNextWeek() {
      return this.timePeriod === "NEXT_WEEK";
    },
    isPreviousWeek() {
      return this.timePeriod === "PREVIOUS_WEEK";
    },
    startDate() {
      const startweek = 6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      let start = DateTime.utc().setZone("America/Los_Angeles").startOf("day").set({ weekNumber: startweek, weekday: 6 });
      if (this.isNextWeek) {
        start = start.plus({ days: 7 });
      } else if (this.isPreviousWeek) {
        start = start.minus({ days: 7 });
      }
      return start;
    },
    endDate() {
      const startweek = 6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      let end = DateTime.utc()
        .setZone("America/Los_Angeles")
        .endOf("day")
        .set({ weekNumber: startweek + 1, weekday: 5 });
      if (this.isNextWeek) {
        end = end.plus({ days: 7 });
      } else if (this.isPreviousWeek) {
        end = end.minus({ days: 7 });
      }
      return end;
    },
  },
  watch: {
    $route(to) {
      if (to.name === "onboarding") {
        const selected = this.$store.getters.getSelectedEmployee;
        if (selected?.id) {
          this.selectedEmployee = selected;
        } else {
          this.resetToMe();
        }
      }
    },
    employees(newValue, oldValue) { 
      if (oldValue.length === 0 && newValue.length !== 0) {
        const selected = this.$store.getters.getSelectedEmployee;
        if (selected?.id) {
          this.selectedEmployee = selected;
        } else {
          this.resetToMe();
        }
      }
    },
  },
  mounted() {
    // NOTE this could be modified if the business decides that we should keep track of who was selected when returning to this page.
    // this.resetToMe();
    restApi.get(`/location/getPendingHCR`).then((data) => {
      this.existingHCR = data.data.pendingHCR
    })
  },
};
</script>

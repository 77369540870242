<template>
  <div class="module-form">
    <h3>Settings</h3>

    <!-- <form-input :value="value.federalTaxID" name="federalTaxID" label="Tax ID" @input="update('federalTaxID', $event)" /> -->

    <!-- <div class="multi-input-group">
      <p>Tax Program*</p>
      <div class="inner">
        <div class="radio-custom">
          <input id="tax-program-1099K" :checked="value.taxProgram === '1099K'" value="1099K" type="radio" name="tax-program" @change="update('taxProgram', $event.target.value)" />
          <label for="tax-program-1099K"><span class="label-container">1099K</span></label>
        </div>
        <div class="radio-custom">
          <input id="tax-program-1099NEC" :checked="value.taxProgram === '1099NEC'" value="1099NEC" type="radio" name="tax-program" @change="update('taxProgram', $event.target.value)" />
          <label for="tax-program-1099NEC"><span class="label-container">1099NEC</span></label>
        </div>
        <div class="radio-custom">
          <input id="tax-program-hybrid" :checked="value.taxProgram === 'Hybrid'" value="Hybrid" type="radio" name="tax-program" @change="update('taxProgram', $event.target.value)" />
          <label for="tax-program-hybrid"><span class="label-container">Hybrid</span></label>
        </div>
      </div>
    </div> -->
    <div class="multi-input-group">
      <p>Recruiting*</p>
      <div class="inner">
        <div class="radio-custom">
          <input id="recruiting-on" :checked="value.isRecruiting === true" :value="true" type="radio" name="recruiting" @change="update('isRecruiting', $event.target.value)" />
          <label for="recruiting-on"><span class="label-container">On</span></label>
        </div>
        <div class="radio-custom">
          <input id="recruiting-off" :checked="value.isRecruiting === false" :value="false" type="radio" name="recruiting" @change="update('isRecruiting', $event.target.value)" />
          <label for="recruiting-off"><span class="label-container">Off</span></label>
        </div>
      </div>
    </div>
    <div v-if="isRecruiting" class="multi-input-group">
      <day-selector :value="value.startDay" label="Startday" :width="424" :required="true" @change="update('startDay', $event)" />
      <day-selector :value="value.endDay" label="Endday" :width="424" :required="true" @change="update('endDay', $event)" />
    </div>
    <div class="multi-input-group">
      <p>HNO Age Requirement*</p>
      <div class="inner">
        <div class="radio-custom">
          <input id="age-requirement-18+" :checked="value.ageRequirement === '18'" value="18" type="radio" name="age-requirement" @change="update('ageRequirement', $event.target.value)" />
          <label for="age-requirement-18+"><span class="label-container">18+</span></label>
        </div>
        <div class="radio-custom">
          <input id="age-requirement-21+" :checked="value.ageRequirement === '21'" value="21" type="radio" name="age-requirement" @change="update('ageRequirement', $event.target.value)" />
          <label for="age-requirement-21+"><span class="label-container">21+</span></label>
        </div>
      </div>
    </div>
    <!-- <div class="multi-input-group">
      <p>Business type*</p>
      <div class="inner">
        <div class="radio-custom">
          <input id="bt-enterprise" :checked="value.businessType === 'ENTERPRISE'" value="ENTERPRISE" type="radio" name="business-type" @change="update('businessType', $event.target.value)" />
          <label for="bt-enterprise"><span class="label-container">Enterprise</span></label>
        </div>
        <div class="radio-custom">
          <input id="bt-smb" :checked="value.businessType === 'SMB'" value="SMB" type="radio" name="business-type" @change="update('businessType', $event.target.value)" />
          <label for="bt-smb"><span class="label-container">Small Medium Business (SMB)</span></label>
        </div>
      </div>
    </div> -->
    <!-- <div class="multi-input-group">
      <p>Insurance Setting*</p>
      <div class="inner">
        <div class="radio-custom">
          <input id="is-routed" :checked="value.insuranceSetting === 'ROUTED'" value="ROUTED" type="radio" name="insurance-setting" @change="update('insuranceSetting', $event.target.value)" />
          <label for="is-routed"><span class="label-container">Routed</span></label>
        </div>
        <div class="radio-custom">
          <input id="is-on-demand" :checked="value.insuranceSetting === 'ONDEMAND'" value="ONDEMAND" type="radio" name="insurance-setting" @change="update('insuranceSetting', $event.target.value)" />
          <label for="is-on-demand"><span class="label-container">On-Demand</span></label>
        </div>
        <div class="radio-custom">
          <input
            id="is-non-ddi-converage"
            :checked="value.insuranceSetting === 'NONDDI'"
            value="NONDDI"
            type="radio"
            name="insurance-setting"
            @change="update('insuranceSetting', $event.target.value)"
          />
          <label for="is-non-ddi-converage"><span class="label-container">Non-DDI Coverage</span></label>
        </div>
        <div class="radio-custom">
          <input
            id="id-do-not-qualify"
            :checked="value.insuranceSetting === 'DOESNOTQUALIFY'"
            value="DOESNOTQUALIFY"
            type="radio"
            name="insurance-setting"
            @change="update('insuranceSetting', $event.target.value)"
          />
          <label for="id-do-not-qualify"><span class="label-container">Does Not Qualify</span></label>
        </div>
      </div>
    </div> -->
    <!-- <label>MVR Package Name</label>
    <item-picker
      :search-results="$props.packageList"
      :selected="$props.selectedMvr"
      :display-add-item-button="false"
      :max-size="1"
      auto-select
      no-title
      placeholder=""
      @itemsChanged="update('mvrPackageName', $event)"
    /> -->
    <!-- <label>Screening Package Name</label> -->
    <!-- <item-picker
      :search-results="$props.packageList"
      :selected="$props.selectedScreening"
      :display-add-item-button="false"
      :max-size="1"
      auto-select
      no-title
      placeholder=""
      @itemsChanged="update('screeningPackageName', $event)"
    /> -->
  </div>
</template>
<script>
// import FormInput from "@/components/forms/fields/FormInput";
import {  DaySelector } from "@/components";

export default {
  name: "Settings",
  // components: { FormInput, DaySelector },
  components: { DaySelector },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    packageList: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedMvr: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedScreening: {
      type: Array,
      required: false,
      default: () => [],
    },
    client: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      packages: [],
      recruiting: false,
    };
  },
  computed: {
    isRecruiting() {
      return (this.recruiting === "true" || this.$props.value.isRecruiting === true) && this.$props.client;
    },
  },
  methods: {
    update(key, value) {
      if (key === "mvrPackageName" || key === "screeningPackageName") {
        this.$emit("input", { ...this.$props.value, [key]: value?.itemAdded?.name });
      } else {
        key === "isRecruiting" ? (this.recruiting = value) : "";
        this.$emit("input", { ...this.$props.value, [key]: value });
      }
    },
  },
};
</script>

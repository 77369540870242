<template>
  <div id="modal-incomplete-settlement" class="modal" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
      <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <div class="modal-content">
          <div class="module">
            <h4>Incomplete Settlement</h4>
            <p>Closing will erase any info entered into this settlement.</p>
          </div>
          <div class="button-group close-top"><a class="button warning" href="#0">Close Submission</a><a class="button secondary" href="#0">Cancel</a></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

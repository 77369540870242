<template>
  <fragment>
    <button-bar :buttons="$props.buttons" @onSelect="changeTab" />
    <fragment v-for="(button, index) in $props.buttons" :key="button.id">
      <slot v-if="showTab(button.id, index)" :name="button.id" :button="button" />
    </fragment>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import ButtonBar from "../buttons/ButtonBar.vue";

export default {
  components: { ButtonBar, Fragment },
  props: {
    buttons: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    selectedButton: null,
  }),
  methods: {
    changeTab(button) {
      this.selectedButton = button.id;
    },
    showTab(buttonId, index) {
      return buttonId === this.selectedButton || (this.selectedButton === null && index === 0);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"id":_vm.reconModalName,"full":true}},[_c('sticky-header',{attrs:{"title":""}},[_c('div',{staticClass:"button-group"},[_c('h2',[_vm._v(_vm._s(_vm.viewType == "disbursement-view" ? "Disbursement View" : "Settlement View"))]),_c('div',[_c('button',{staticClass:"button secondary",on:{"click":_vm.back}},[_vm._v("Back")])])])]),(_vm.viewType==='disbursement-view')?_c('div',{staticClass:"header-alignment flex space-between"},[_c('div',[_c('h4',[_vm._v(" Disbursement Date: "+_vm._s(_vm.formatDate(_vm.disbursementDate))+" ")])]),_c('div',{staticClass:"col-0.5 tooltip-i",staticStyle:{"padding-right":"0px","margin-right":"24px"},on:{"click":function($event){return _vm.downloadUnsettledTxns()}}},[(!_vm.downloadLoader)?_c('a',[_c('img',{attrs:{"src":require("@/assets/images/logos/download.svg")}})]):_c('span',{staticClass:"loading"},[_c('Loader')],1),_c('span',{staticClass:"tooltip-text summary-tooltip-align"},[_vm._v("Click to download")])])]):(_vm.viewType==='settlement-view')?_c('div',{staticClass:"header-alignment"},[_c('h4',[_vm._v(" Invoice No: "+_vm._s(_vm.invoiceNo)+" ")])]):_vm._e(),_c('div',{staticClass:"content-align"},[(!_vm.loader)?_c('p',{staticClass:"black"},[_vm._v("Showing "+_vm._s(_vm.tableEntries.length)+" of "+_vm._s(_vm.totalCount)+" Transactions")]):_vm._e(),_c('data-table',{attrs:{"headers":_vm.headers,"entries":_vm.tableEntries,"pagination-total-count":_vm.totalCount,"pagination-per-page":_vm.perPage,"pagination-current-page":_vm.currentPage,"loading":_vm.loader},on:{"onPaginate":function($event){return _vm.onPaginate($event)}},scopedSlots:_vm._u([{key:"driver_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tooltip-i"},[_c('a',{on:{"click":function($event){return _vm.redirectProfile(item['Driver Id'])}}},[_vm._v(_vm._s((".........." + (item['Driver Id'].substr(-4)))))]),_c('span',{staticClass:"tooltip-modal"},[_vm._v(_vm._s(item['Driver Id']))])])]}},{key:"disbursed_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item['Disbursement Amount']))+" ")]}},{key:"transaction_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-14"},[_vm._v(" "+_vm._s(item['Transaction ID'])+" ")])]}},{key:"disbursed_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTableDate(item['Disbursement Date']))+" ")]}},{key:"settled_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTableDate(item['Settlement Date']))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
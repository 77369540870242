class TimePeriod {
  static ONE_TIME = "ONE_TIME";
  static DAILY = "DAILY";
  static WEEKLY = "WEEKLY";
  static PAY_PERIOD = "PAY_PERIOD";
  static SEMI_MONTHLY = "SEMI_MONTHLY";
  static MONTHLY = "MONTHLY";
  static QUARTERLY = "QUARTERLY";
  static SEMI_ANNUAL = "SEMI_ANNUAL";
  static YEARLY = "YEARLY";

  static timePeriods = {
    [TimePeriod.ONE_TIME]: { label: "One Time", display: "One Time" },
    [TimePeriod.DAILY]: { label: "Daily", display: "Day" },
    [TimePeriod.WEEKLY]: { label: "Weekly (Mon-Sun)", display: "Week" },
    [TimePeriod.PAY_PERIOD]: { label: "Per Pay Period", display: "Pay Period" },
    [TimePeriod.SEMI_MONTHLY]: { label: "Semi-Monthly", display: "Half-month" },
    [TimePeriod.MONTHLY]: { label: "Monthly (Cal month)", display: "Month" },
    [TimePeriod.QUARTERLY]: { label: "Quarterly", display: "Quarter" },
    [TimePeriod.SEMI_ANNUAL]: { label: "Semi-Annual", display: "Half-year" },
    [TimePeriod.YEARLY]: { label: "Yearly", display: "Year" },
  };

  static getTimePeriodDisplay = (timePeriod) => TimePeriod.timePeriods[timePeriod].display;
  static getTimePeriodLabel = (timePeriod) => TimePeriod.timePeriods[timePeriod].label;
  static getTimePeriodOptions = () => Object.keys(TimePeriod.timePeriods).map((timePeriod) => ({ id: timePeriod, label: TimePeriod.getTimePeriodLabel(timePeriod) }));
}

export default TimePeriod;

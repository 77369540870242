class TransactionTaxSetting {
  static TAX_1099K = "TAX_1099K";
  static TAX_1099NEC = "TAX_1099NEC";
  static FEE_PRE_TAX = "FEE_PRE_TAX";
  static FEE_POST_TAX = "FEE_POST_TAX";
  static TAX_1099M = "TAX_1099M";

  static CREDIT = "Credit to Partner";
  static DEBIT = "Debit from Partner";

  static taxSettings = {
    [TransactionTaxSetting.TAX_1099K]: "1099K Income",
    [TransactionTaxSetting.TAX_1099NEC]: "1099NEC Income",
    [TransactionTaxSetting.FEE_PRE_TAX]: "Fee (pre tax)",
    [TransactionTaxSetting.FEE_POST_TAX]: "Fee (post tax)",
  };

  static partnerTaxSettings = {
    [TransactionTaxSetting.TAX_1099K]: "1099k Income",
    [TransactionTaxSetting.TAX_1099M]: "1099m Income",
    [TransactionTaxSetting.FEE_PRE_TAX]: "Fee (pre tax)",
    [TransactionTaxSetting.FEE_POST_TAX]: "Fee (post tax)",
  };

  static isFee = (taxSetting) => taxSetting.id === TransactionTaxSetting.FEE_PRE_TAX || taxSetting.id === TransactionTaxSetting.FEE_POST_TAX;
  static isCredit = (taxSetting) => taxSetting.id === TransactionTaxSetting.TAX_1099K || taxSetting.id === TransactionTaxSetting.TAX_1099NEC || taxSetting.id === TransactionTaxSetting.TAX_1099M;

  static getTaxSettingsLabel = (taxSetting) => TransactionTaxSetting.taxSettings[taxSetting];
  static getPartnerTaxSettingsLabel = (taxSetting) => TransactionTaxSetting.partnerTaxSettings[taxSetting];

  static getTaxSettingsOptions = () =>
    Object.keys(TransactionTaxSetting.taxSettings).map((taxSetting) => ({
      id: taxSetting,
      label: `${TransactionTaxSetting.getTaxSettingsLabel(taxSetting)} - ${TransactionTaxSetting.isCredit({ id: taxSetting }) ? TransactionTaxSetting.CREDIT : TransactionTaxSetting.DEBIT}`,
    }));
  static getPartnerTaxSettingsOptions = () =>
    Object.keys(TransactionTaxSetting.partnerTaxSettings).map((taxSetting) => ({
      id: taxSetting,
      label: `${TransactionTaxSetting.getPartnerTaxSettingsLabel(taxSetting)} - ${TransactionTaxSetting.isCredit({ id: taxSetting }) ? TransactionTaxSetting.CREDIT : TransactionTaxSetting.DEBIT}`,
    }));
}

export default TransactionTaxSetting;

<template>
  <div class="field">
    <div class="input-has-prepend">
      <div class="input-prepend">$</div>
      <input type="number" @input="update" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
      this.$emit("change", event.target.value);
    },
  },
};
</script>

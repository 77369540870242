<template>
  <div id="modal-prepare-upload-in-progress" class="modal" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
      <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <div class="modal-content">
          <div class="module">
            <h4>Preparing Upload</h4>
            <div class="well no-background">
              <div class="loading">
                <p><img src="@/assets/images/svg/loader.svg" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

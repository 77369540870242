import { TimePeriod, SettlementItemType, TransactionTaxSetting, TransactionType } from "@/modules/shared/finances/model";

class TransactionModifierFactory {
  newSelectedModifier = {
    name: "",
    description: "",
    category: "",
    transactionType: "",
    taxSetting: "",
    modifierType: "",
    billTo: "CLIENT",
  };

  additionalDataForCount = { includeRate: true, rate: "" };
  additionalDataForCustom = { rateType: "percent", rate: "", existingTransaction: "" };
  additionalDataForPresetClientRevenue = { amount: "", payCycle: "" };
  additionalDataForPreset = { amount: "", frequency: "", hasEnd: false, quantity: "" };
  additionalDataForYesNo = { yesCondition: { transactionType: "" }, noCondition: { transactionType: "" } };
  additionalDataForCostPer = { rate: "", payCycle: "" };
  additionalDataForStructuredRate = { rateStructure: [] };
  additionalDataForStructuredPartnerRate = { rateStructure: [] };
  additionalDataForCreditDebit = { amount: "" };
  additionalDataForPresetAmount = { amount: "", frequency: "", hasEnd: "" };

  newServiceCount = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForCount } });
  newServiceCredit = () => ({ ...this.newSelectedModifier });

  newAdjustmentCount = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForCount } });
  newAdjustmentCredit = () => ({ ...this.newSelectedModifier, transactionType: TransactionType.CREDIT });
  newAdjustmentDebit = () => ({ ...this.newSelectedModifier, transactionType: TransactionType.DEBIT });
  newAdjustmentCreditDebit = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForCreditDebit } });
  newAdjustmentCustom = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForCustom } });
  newAdjustmentPreset = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForPreset } });
  newAdjustmentYesNo = () => ({ ...this.newSelectedModifier, additionalData: { ...this.additionalDataForYesNo } });

  newPaymentCredit = () => ({ ...this.newSelectedModifier, transactionType: TransactionType.CREDIT });
  newPaymentDebit = () => ({ ...this.newSelectedModifier, transactionType: TransactionType.DEBIT });

  additionalData = (modifierType, transactionType) => {
    switch (transactionType) {
      case TransactionType.COUNT:
        return { ...this.additionalDataForCount };
      case TransactionType.CUSTOM:
        return { ...this.additionalDataForCustom };
      case TransactionType.PRESET:
        if (SettlementItemType.isClientRevenue(modifierType)) {
          return { ...this.additionalDataForPresetClientRevenue };
        }
        return { ...this.additionalDataForPreset };
      case TransactionType.YES_NO:
        return { ...this.additionalDataForYesNo };
      case TransactionType.COST_PER_LOCATION:
      case TransactionType.COST_PER_PARTNER:
        return { ...this.additionalDataForCostPer };
      case TransactionType.STRUCTURED_RATE:
        return { ...this.additionalDataForStructuredRate };
      case TransactionType.STRUCTURED_PARTNER_RATE:
        return { ...this.additionalDataForStructuredPartnerRate };
      case TransactionType.CREDIT_DEBIT:
        return { ...this.additionalDataForCreditDebit };
      case TransactionType.PRESET_AMOUNT:
        return { ...this.additionalDataForPresetAmount };
    }
  };

  computeRate = (modifier) => {
    switch (modifier.transactionType) {
      case TransactionType.COUNT:
        if (modifier.additionalData.includeRate && modifier.additionalData.rate) {
          return `$${modifier.additionalData.rate}/${this.transactionTypeDisplay(modifier)}`;
        }
        return `Client Input/${this.transactionTypeDisplay(modifier)}`;
      case TransactionType.CUSTOM:
        console.log(modifier.additionalData);
        return `${modifier.additionalData.rateType === "fixed" ? "$" : ""}${modifier.additionalData.rate}${modifier.additionalData.rateType === "percent" ? "%" : ""}/${
          modifier.additionalData.transactionName
        } Transaction`;
      case TransactionType.CREDIT_DEBIT:
      case TransactionType.CREDIT:
      case TransactionType.DEBIT:
      case TransactionType.CLIENT_INPUT:
        return "Client Input";
      case TransactionType.PRESET:
        return `$${modifier.additionalData.amount}${this.timePeriodDisplay(modifier)}`;
      case TransactionType.YES_NO:
        return this.computeYesNoRate(modifier);
      case TransactionType.STRUCTURED_RATE:
      case TransactionType.STRUCTURED_PARTNER_RATE:
        return `Variable `;
    }
  };

  computeYesNoRate = (modifier) => {
    const { yesCondition, noCondition } = modifier.additionalData;
    const rate = {
      yes: this.computeConditionRate(yesCondition),
      no: this.computeConditionRate(noCondition),
    };
    return `${rate.yes}/Yes ${rate.no}/No`;
  };

  computeConditionRate = (condition) => {
    let rate = "Nothing";
    if (condition && condition.transactionType && condition.transactionType.length > 0) {
      rate = this.computeRate(condition);
    }
    return rate;
  };

  transactionTypeDisplay = (modifier) => {
    return TransactionType.getDisplay(modifier.transactionType);
  };

  timePeriodDisplay = (modifier) => {
    return modifier.additionalData.frequency ? `/${TimePeriod.getTimePeriodDisplay(modifier.additionalData.frequency)}` : "";
  };

  getTaxSettingsLabel = (modifier) => {
    if (TransactionType.isYesNo(modifier)) {
      const { yesCondition, noCondition } = modifier.additionalData;
      const yesTaxSetting = TransactionTaxSetting.getTaxSettingsLabel(yesCondition.taxSetting);
      const noTaxSetting = TransactionTaxSetting.getTaxSettingsLabel(noCondition.taxSetting);
      return `Yes: ${yesTaxSetting} <br /> No: ${noTaxSetting}`;
    }
    return TransactionTaxSetting.getTaxSettingsLabel(modifier.taxSetting);
  };

  isCredit = (modifier) => {
    let taxSetting = modifier.taxSetting;
    if (TransactionType.isYesNo(modifier)) {
      if (modifier.yes) {
        taxSetting = modifier.additionalData.yesCondition.taxSetting;
      } else {
        taxSetting = modifier.additionalData.noCondition.taxSetting;
      }
    }
    return taxSetting === TransactionTaxSetting.TAX_1099NEC || taxSetting === TransactionTaxSetting.TAX_1099K;
  };

  isAllowableCustomTypes = (transactionModifier) => {
    return (
      TransactionType.isCount(transactionModifier) ||
      TransactionType.isCredit(transactionModifier) ||
      TransactionType.isCreditDebit(transactionModifier) ||
      TransactionType.isDebit(transactionModifier) ||
      TransactionType.isPreset(transactionModifier) ||
      TransactionType.isClientInput(transactionModifier)
    );
  };

  computeAmount(modifier, newValue) {
    switch (modifier.transactionType) {
      case TransactionType.COUNT:
        if (modifier.additionalData.includeRate) {
          return modifier.additionalData.rate * newValue;
        }
        return newValue;
      case TransactionType.CREDIT_DEBIT:
        return newValue;
      case TransactionType.PRESET:
        return modifier.additionalData.amount;
    }
    return 0;
  }

  computeYesNoAmount = (modifier, allModifiers) => {
    let condition = modifier.condition === "YES" ? modifier.additionalData.yesCondition : modifier.additionalData.noCondition;
    if (TransactionType.isPreset(condition)) {
      return condition.additionalData.amount && condition.additionalData.amount > 0 ? condition.additionalData.amount : 0;
    }
    if (TransactionType.isCustom(condition)) {
      const modifierID = condition.additionalData.existingTransaction;
      const modifiers = allModifiers.filter((transactionModifier) => transactionModifier.id === modifierID);
      if (modifiers && modifiers.length === 1) {
        const selectedModifier = modifiers[0];
        // TODO... we need to find the newValue (which is the count, credit, or preset value)...
        return this.computeCustomAmount(condition, selectedModifier, 0);
      }
    }
    return 0;
  };

  computeCustomAmount = (customModifier, modifier, newValue) => {
    const transactionType = modifier.transactionType;
    const rateType = customModifier.additionalData.rateType;
    const isPercent = rateType === "percent";
    const rate = customModifier.additionalData.rate;
    switch (transactionType) {
      case TransactionType.COUNT:
        if (modifier.additionalData.includeRate) {
          return isPercent ? (modifier.additionalData.rate * newValue * rate) / 100 : newValue * rate;
        }
        return newValue;
      case TransactionType.CREDIT_DEBIT:
        return isPercent ? (newValue * rate) / 100 : newValue > 0 ? rate : 0;
      case TransactionType.PRESET:
        return isPercent ? (modifier.amount * rate) / 100 : newValue > 0 ? rate : rate;
      default:
        return 0;
    }
  };
}

export default new TransactionModifierFactory();

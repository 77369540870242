import Vue from "vue";
import Vuex from "vuex";
import flyout from "./modules/flyouts";
import user from "./modules/user";
import teams from "./modules/teams";
import onboarding from "./modules/onboarding";
import client from "./modules/client";
import permission from "./modules/permission";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authentication: {},
  },
  modules: { user, flyout, teams, onboarding, client, permission },
  strict: process.env.VUE_APP_ENV !== "production",
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});

export default store;

<template>
  <fragment>
    <div class="module-large">
      <h2>Finances</h2>
      <h3>Partner Settlements</h3>
    </div>
    <div class="module-xlarge">
      <h4>Upload Settlement</h4>
      <p v-if="!file" class="file-upload">
        <a class="button secondary">Upload Complete Template</a>
        <input type="file" accept=".csv" @change="changeFile" />
      </p>
      <div v-if="file" class="file-upload-full">
        <div class="full-upload">
          <div class="file-name">
            <p>{{ file.name }}</p>
          </div>
          <a class="button secondary" @click="file = null">Cancel</a>
        </div>
        <a class="button" @click="uploadTemplate">Review Uploaded Entries</a>
      </div>
      <p>
        <vue-json-to-csv csv-title="Settlement_Template" :json-data="clientCSVLabels" :show-labels="true">
          <a class="button ghost" @click="generateCSVLabels"><i class="icon-download"></i>Download The Template </a>
        </vue-json-to-csv>
      </p>
    </div>
    <div class="module-large">
      <h4>Settlement Manual Entry</h4>
      <p><router-link class="button secondary" :to="{ name: 'cp-location-settlements' }">Input Manual Entries</router-link></p>
    </div>
    <div v-if="needsCorrection.length > 0" class="module-large">
      <h4 class="red">Settlement Correction Needed</h4>
      <data-table :headers="needsCorrectionHeaders" :entries="needsCorrection" table-id="needs-correction-table">
        <template #date="{ item }"><i class="icon-alert-octagon"></i> {{ item.date }}</template>
        <template #notes="{ item }">
          <tooltip :id="`note-${item.id}`" title="Notes" :description="item.notes" hover icon-class="icon-file" />
        </template>
        <template #actions="{ item }"><a @click="editSettlement(item.id)">Correct Settlement</a></template>
      </data-table>
    </div>
    <div class="module-large">
      <h4>Previously Submitted Settlements</h4>
      <data-table :headers="otherSettlementsHeaders" :entries="otherSettlements" table-id="other-settlements-table">
        <template #date="{ item }"><i class="icon-octogon"></i>{{ item.date }}</template>
        <template #notes="{ item }">
          <tooltip :id="`note-${item.id}`" title="Notes" :description="item.notes" hover icon-class="icon-file" />
        </template>
        <template #actions="{ item }"><a @click="editSettlement(item.id)">Correct Settlement</a></template>
      </data-table>
    </div>
    <prepare-upload-modal @continue="continueUpload" />
    <upload-in-progress-modal />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import Tooltip from "@/components/popups/Tooltip";
import TableHeader from "@/components/table/TableHeader";
import PrepareUploadModal from "@/modules/clientPortal/locations/finances/PrepareUploadModal";
import UploadInProgressModal from "@/modules/clientPortal/locations/finances/UploadInProgressModal";
import VueJsonToCsv from "vue-json-to-csv";
import micromodal from "micromodal";

export default {
  components: { Tooltip, DataTable, Fragment, PrepareUploadModal, UploadInProgressModal, VueJsonToCsv },
  data: () => ({
    file: null,
    clientCSVLabels: [],
    settlements: [
      {
        date: "11 Sep 2020",
        notes: "Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
        status: "Needs Correction",
        completeEntries: 14,
        payout: 1774.5,
      },
      {
        date: "11 Sep 2020",
        notes: "Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
        status: "Invoiced",
        completeEntries: 14,
        payout: 1774.5,
      },
    ],
    dummyData: [
      {
        partnerId: "a362b620-b259-4f37-9547-643710376035",
        partnerName: "annette",
        check: {
          deliveryMoney: "220.00",
          payPeriod: "5 Oct 2020 - 11 Oct 2020",
          deliveries: "11",
          tips: "110.00",
          walmartReward: "57.00",
          brokeridgeFees: "11.00",
          childcareSupport: "52.50",
        },
      },
      {
        partnerId: "g123b620-b259-4f37-9547-64371012321",
        partnerName: "shazam",
        check: {
          deliveryMoney: "220.00",
          payPeriod: "5 Oct 2020 - 11 Oct 2020",
          deliveries: "11",
          tips: "110.00",
          walmartReward: "57.00",
          brokeridgeFees: "11.00",
          childcareSupport: "52.50",
        },
      },
    ],
    // partnerDetails : [{name : "annette", id: '101'},{name : "lucas", id: '102'},
    // {name : "john", id: '103'},{name : "matt", id: '104'},{name : "Will", id: '105'}]
  }),
  computed: {
    needsCorrection() {
      if (this.settlements && this.settlements.length) {
        return this.settlements.filter((settlement) => settlement.status === "Needs Correction");
      }
      return [];
    },
    otherSettlements() {
      if (this.settlements && this.settlements.length) {
        return this.settlements.filter((settlement) => settlement.status !== "Needs Correction");
      }
      return [];
    },
    needsCorrectionHeaders() {
      return [
        new TableHeader({ name: "date", label: "Date Sent Back", headerClass: "cell20", cellClass: "red", cellContents: "date" }),
        new TableHeader({ name: "notes", label: "Notes", headerClass: "cell5", cellContents: "notes" }),
        new TableHeader({ name: "status", label: "Status", headerClass: "cell20", cellClass: "red", cellContents: "status" }),
        new TableHeader({ name: "completeEntries", label: "Number of Complete Entries", headerClass: "cell20", cellContents: "completeEntries" }),
        new TableHeader({ name: "payout", label: "Total Payout", headerClass: "cell15", cellContents: (item) => this.format(item.payout) }),
        new TableHeader({ name: "actions", label: "", headerClass: "cell20", cellClass: "table-actions", cellContents: "actions" }),
      ];
    },
    otherSettlementsHeaders() {
      return [
        new TableHeader({ name: "date", label: "Submission Date", headerClass: "cell20", cellContents: "date" }),
        new TableHeader({ name: "notes", label: "Notes", headerClass: "cell5", cellContents: "notes" }),
        new TableHeader({ name: "status", label: "Status", headerClass: "cell20", cellContents: "status" }),
        new TableHeader({ name: "completeEntries", label: "Number of Complete Entries", headerClass: "cell20", cellContents: "completeEntries" }),
        new TableHeader({ name: "payout", label: "Total Payout", headerClass: "cell15", cellContents: (item) => this.format(item.payout) }),
        new TableHeader({ name: "actions", label: "", headerClass: "cell20", cellClass: "table-actions", cellContents: "actions" }),
      ];
    },
  },
  methods: {
    changeFile(event) {
      this.file = event.target.files[0];
    },
    format(money) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(money);
    },
    editSettlement(settlementID) {
      console.log("Editing settlement", settlementID);
    },
    generateCSVLabels() {
      const labels = [];
      this.dummyData.map((item) => {
        const settlementLabels = {};
        const { check, ...rest } = item;
        const value = { ...check, ...rest };
        for (const item in value) {
          const header = (item.charAt(0).toUpperCase() + item.slice(1)).split(/(?=[A-Z])/).join(" ");
          item == "partnerId" || item == "partnerName" ? (settlementLabels[header] = value[item]) : (settlementLabels[header] = "");
        }
        labels.push(settlementLabels);
      });
      this.clientCSVLabels = labels;
    },
    uploadTemplate() {
      micromodal.show("modal-prepare-upload-in-progress", {});
      setTimeout(() => {
        this.$papa.parse(this.file, {
          header: true,
          transformHeader: (header) => {
            return (header.charAt(0).toLowerCase() + header.slice(1)).split(" ").join("");
          },
          skipEmptyLines: true,
          complete: (results) => {
            console.log("Parsing complete:", results.data);
            micromodal.close("modal-prepare-upload-in-progress");
            micromodal.show("modal-prepare-upload", {});
          },
        });
      }, 4000);
    },
    continueUpload() {
      micromodal.close("modal-prepare-upload", {});
      this.file = null;
    },
  },
};
</script>

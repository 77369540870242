
<template>
  <div class="search-container">
    <form>
      <label>
        <input
          v-model="searchTerm"
          @input="searchStr"
          type="search"
          placeholder="Zones, Driver"
          title="
Location: Name
Partner: Name, Email, Username, ID, Phone, SSN, External DI"
        />
      </label>
      <button v-if="searchTerm.length === 0" disabled><span class="icon-search padding-top-4"></span></button>
      <button v-else @click.prevent="clearSearch"><span class="icon-x-circle padding-top-4"></span></button>
    </form>
    <button @click="openModal('advance-search')" class="advs-search"><span class="icon-filter padding-top-4"></span></button>
      <advance-search @click="closeModal" />
    <!--    <div v-if="$apollo.queries.search.loading">-->
    <!--      <table-loader min-height="20px" min-width="240px" />-->
    <!--    </div>-->
    <div class="searchSubContainer">
      <ul v-if="searchResults.total > 0 && searchTerm.length !== 0" class="searchResult" @click.prevent="clearSearch">
        <li v-if="searchResults.partners.length !== 0" class="inline-header">Partners</li>
        <li v-for="result in searchResults.partners" :key="result.id">
          <div class="searchResultRow">
            <span v-if="result.type === 'partner'" class="icon-user link-color" title="Partner"></span>
            <a v-if="result.type === 'partner'"   @click="openinNewTab({ name: 'partner', params: { id: result.id } })">
              {{ result.firstName }} {{ result.lastName }}
              <p class="small gray-light" :style="{margin:0}">{{ result.email }}</p>
              <p class="small gray-light">{{ result.phone | formatPhone }}</p>
            </a
            >
          </div>
        </li>
        <!-- <li v-if="searchResults.clients.length !== 0" class="inline-header">Clients</li>
        <li v-for="result in searchResults.clients" :key="result.id">
          <div class="searchResultRow">
            <span v-if="result.type === 'client'" class="icon-office link-color" title="Client"></span>
            <a v-if="result.type === 'client'" @click="openinNewTab({ name: 'client', params: { id: result.id } })">{{ result.name }}</a>
          </div>
        </li> -->
        <li v-if="searchResults.locations.length !== 0" class="inline-header">Zones</li>
        <li v-for="result in searchResults.locations" :key="result.id">
          <div class="searchResultRow">
            <span v-if="result.type === 'location'" class="icon-map-pin link-color" title="Location"></span>
            <a v-if="result.type === 'location'"  @click="openLocation( result.id )">{{ result.name }}
              <!-- <p class="small gray-light">{{ result.name }}</p> -->
            </a>
          </div>
        </li>
      </ul>
      <ul v-if="searchResults.total === 0 && searchTerm.length > 0" class="searchResultNone">
        <!-- <table-loader min-height="20px" min-width="240px" /> -->
        No results found
      </ul>

      <!-- <ul v-else class="searchResultNone">
        <table-loader min-height="20px" min-width="240px" />
      </ul> -->
    </div>
  </div>
</template>
<script>
// import { SEARCH_CLIENT_LOCATION_PARTNERS, SEARCH_CLIENT_LOCATIONS, SEARCH_CLIENTS, SEARCH_PARTNERS, SEARCH_PDLOG } from "@/components/graph/queries";
// import TableLoader from "@/components/loader/TableLoader";
import { restApi } from "@/http/http.config";
import debounce from "debounce";
import {encodeWithParam} from "@/util/Base64Encoding";
import AdvanceSearch from "@/components/modals/AdvanceSearch.vue";
import micromodal from "micromodal";

export default {
  name: "Search",
  components: {
    AdvanceSearch,
},
  data: function () {
    return {
      searchTerm: "",
      search: [],
      clients: [],
      clientLocations: [],
      clientLocationPartners: [],
      partners: [],
      pdLogs: [],
    };
  },
  computed: {
    searchResults() {
      const clients = this.clients.map((item) => {
        item.type = "client";
        return item;
      });
      const locations = this.clientLocations.map((item) => {
        item.type = "location";
        return item;
      });
      const partnerResults = this.partners.map((item) => {
        item.type = "partner";
        return item;
      });
      const clientLocationPartnerResults = this.clientLocationPartners.map((item) => {
        item.partner.type = "partner";
        return item.partner;
      });
      const pdLogResults = this.pdLogs.map((item) => {
        item.type = "partner";
        return item;
      });
      const partners = [partnerResults, clientLocationPartnerResults, pdLogResults].flat();
      const total = clients.length + locations.length + partnerResults.length + clientLocationPartnerResults.length + pdLogResults.length;
      return { clients, locations, partners, total };
    },
    isUUID() {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/g;
      return this.searchTerm.match(regex);
    },
  },
  methods: {
    agreeContinue() {
      this.$emit("accept");
    },
    clearSearch() {
      this.searchTerm = "";
    },
    nonExistingUUID() {
      return "00000000-0000-0000-0000-000000000000";
    },
    openinNewTab(data){
      let route= this.$router.resolve(data);
      window.open(route.href, '_blank');
    },

      openLocation(id){
        let route = this.$router.resolve(`/admin/clients/${process.env.VUE_APP_CLIENT_ID}/locations/${id}`);
        window.open(route.href, '_blank');
    },

    searchStr() {
      const partnerParam = {searchTerm : this.searchTerm}
         restApi.post('onboard/partnerSearch', encodeWithParam(partnerParam))
        .then(data => {
          this.partners = data.data.result;
          if(data.data.result.length == 0){
        const usernameParam = {searchTerm : `%${this.searchTerm}%`}
        restApi.post('onboard/usernameSearch', encodeWithParam(usernameParam))
        .then(data => {
          this.partners = data.data.result;
      if(data.data.result.length == 0){
        const externalIDParam = {searchTerm : this.searchTerm}
        restApi.post('onboard/externalIdSearch', encodeWithParam(externalIDParam))
        .then(data => {
          this.partners = data.data.result;
                });
              }
              });
            }
          });
        const locationParam = {searchTerm : `%${this.searchTerm}%`}
         restApi.post('onboard/locationSearch', encodeWithParam(locationParam))
        .then(data => {
          this.clientLocations = data.data.result;
        });
    },
    openModal(modal) {
      micromodal.show(modal);
    },
    closeModal() {
      micromodal.close("advance-search");
    }
  },

  mounted(){
      this.searchStr = debounce(this.searchStr, 500)
  }
};
</script>
<style scoped>
button {
  height: 35px;
}
.searchResultNone {
  position: absolute;
  right: 11px;
  max-height: 500px;
  overflow: scroll;
  z-index: 100;
  list-style: none;
  min-width: 216px;
  width: auto;
  padding: 10px;
  background-color: #fdfdfd;
  margin-top: 3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
}
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 5px;
}
.searchSubContainer {
  width: 238px;
  /*border: 1px solid red;*/
}
.searchResult {
  position: absolute;
  right: 11px;
  max-height: 500px;
  overflow: scroll;
  z-index: 100;
  list-style: none;
  min-width: 280px;
  width: auto;
  padding-left: 0;
  background-color: #fdfdfd;
  margin-top: 3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
}
.searchResultRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  border-bottom: 1px solid lightgray;
}
.searchResult li {
  margin-bottom: 0;
}
.searchResult li a {
  width: 100%;
  padding: 10px 5px;
  text-decoration: none;
}
.searchResultRow:hover {
  background-color: #eaeaea;
}
.inline-header {
  background-color: #797979;
  color: white;
  padding: 5px;
}
.padding-top-4 {
  padding-top: 4px;
}
.link-color {
  color: var(--link);
}
.advs-search {
    position: absolute;
    right: -27px;
}
</style>

<template>
  <div id="modal-prepare-upload" class="modal" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
      <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <div class="modal-content">
          <div class="module">
            <h4>Preparing Upload</h4>
            <p>
              The following partners were not recognized in the system; their settlement information cannot be submitted. You will need to enter them into the system in Partner Roster, or contact DDI
              for help at (901) 234-5678.
            </p>
            <table class="no-zebra">
              <thead>
                <tr>
                  <th>Driver Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Albert Flores</td>
                </tr>
                <tr>
                  <td>Bessie Cooper</td>
                </tr>
                <tr>
                  <td>Cameron Williamson</td>
                </tr>
                <tr>
                  <td>Darrell Steward</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="button-group close-top flex">
            <a class="button" @click="continueIt">Continue</a>
            <a class="button secondary" @click="close">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import micromodal from "micromodal";
export default {
  methods: {
    continueIt() {
      this.$emit("continue");
    },
    close() {
      micromodal.close("modal-prepare-upload");
    },
  },
};
</script>

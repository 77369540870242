import gql from "graphql-tag";

export const SAVE_SETTLEMENT = gql`
  mutation saveSettlement($settlement: submissions_insert_input!) {
    insert_submissions_one(object: $settlement, on_conflict: { constraint: settlement_pkey, update_columns: [status] }) {
      id
    }
  }
`;

export const SAVE_PAYOUTS = gql`
  mutation savePayouts($partnerPayouts: [partnerPayouts_insert_input!]!) {
    insert_partnerPayouts(objects: $partnerPayouts) {
      affected_rows
    }
  }
`;

<template>
  <fragment>
    <div class="module-xlarge">
      <h2>Payment Distributions</h2>
    </div>
    <button-bar :buttons="buttons" @onSelect="buttonsChanged" />
    <div v-if="toBeDistrubutedAccount" class="bar-info-contents">
      <h3>To Be Distributed</h3>
      <p>Payments that need to be sent to payment processor to distribute funds to partners.</p>
      <div class="module-xlarge payment-distributions">
        <data-table :entries="toBeDistrubutedObj" :headers="toBeDistributedHeaders" :loading="toBedistributionloader">
          <template #meatball="{ item }">
            <meatball :id="item.Id" right @selected="currentElement($event)">
              <menu-item v-if="item.PaymentProcessor == 'Branch'" label="Send Payments to Branch" :on-click="branchPayment" />
              <menu-item v-if="item.PaymentProcessor != 'Branch'" label="Mark as Distributed" :on-click="showPaidPopUp" />
              <div v-for="invoice in item.invoices" :key="invoice.id">
                <menu-item :label="`Invoice: ${invoice.name}`" :on-click="() => viewInvoice(invoice.id)" />
              </div>
              <menu-item label="View Settlement" :on-click="viewSettlement" />
            </meatball>
          </template>
        </data-table>
      </div>
    </div>

    <div v-if="recentlyDistrubutedAccount" class="bar-info-contents">
      <h3>Recently Distributed</h3>
      <div class="module-xlarge payment-distributions">
        <data-table-header v-for="(header, index) in recentlyDistributedHeaders" :key="index" :index="index" :header="header" />
        <data-table :entries="recentlyDistrubutedObj" hide-headers :headers="recentlyDistributedHeaders" :loading="recentlydistributionloader">
          <template #meatball="{ item }">
            <meatball :id="item.Id" right @selected="currentElement($event,item)">
              <menu-item v-if="item.PaymentProcessor != 'Branch'" label="Mark as NOT Distributed" :on-click="showPaidPopUp" />
              <div v-for="invoice in item.invoices" :key="invoice.id">
                <menu-item :label="`Invoice: ${invoice.name}`" :on-click="() => viewInvoice(invoice.id)" />
              </div>
              <menu-item label="View Settlement" :on-click="viewSettlement" />
            </meatball>
          </template>
        </data-table>
      </div>
    </div>

    <div v-if="withheldPayments" class="bar-info-contents">
      <h3>WITHHELD PAYMENTS</h3>
      <p>Partners who have withheld payments that are attached to a recent distribution</p>
      <p>
        These will be paid on <b>{{ garnishmentDate }}</b>
      </p>
      <div class="flex tab-content module-m">
        <div class="cell70">
          <div class="search-container">
            <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Partner Name" />
            <button type="submit"><span v-if="searchIcon" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
          </div>
        </div>
      </div>
      <data-table 
      :entries="depositSummaries" 
      :headers="withheldPaymentHeaders" 
      :loading="depositLoader"
      :pagination-total-count="totalCount"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      @onPaginate="fetchData($event)">
        <template #total="{ item }">
          <table>
            <thead>
              <tr>
                {{
                  item.total
                }}
              </tr>
            </thead>
            <tbody v-if="item.id == showTableId">
              <tr v-for="(val, index) in item.depositSummaries" :key="`${val.id}-${index}`">
                <div class="accordian-tr">
                  {{ getDepositDate(val) }} <span class="space">{{ `${item.clientName} - ${val.locationName}` }}</span>
                </div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #amount="{ item }">
          <table>
            <thead>
              <tr>
                {{
                  item.amount | formatMoney
                }}
              </tr>
            </thead>
            <tbody v-if="item.id == showTableId">
              <tr v-for="(val, index) in item.depositSummaries" :key="`${val.id}-${index}`">
                <div class="accordian-tr">
                  {{ val.check.NET.amount | formatMoney }}
                  <div style="display: block">
                    <span class="space right"><a @click="openFlyout(val)" @click.prevent>View</a></span>
                    <span class="space right"><a @click="sendPaymentToBranch(val)" @click.prevent>Send to Branch</a></span>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #accordian="{ item }">
          <a :id="item.id" class="button ghost" @click="toggle(item.id)"
            ><i class="right-icon" :class="{ 'icon-chevron-up': showing && item.id == showTableId, 'icon-chevron-down': !showing }"> </i>
          </a>
        </template>
      </data-table>
    </div>

    <div id="modal-mark-as-paid" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <input v-model="popupHeading" type="hidden" />
              <input v-model="popupBtn" type="hidden" />
              <input v-model="popupText" type="hidden" />
              <h4>{{ popupHeading }}</h4>
              <p>{{ popupText }}</p>
            </div>
            <div class="button-group close-top">
              <a class="button" @click="markAsDistributed">{{ popupBtn }}</a
              ><a class="button secondary" data-micromodal-close>Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-modal invoiceModalName="paymentDistributionInvoice" :invoice-id="selectedInvoiceId" status="APPROVED" :line-items="lineItems" :total-line-items="totalLineItems" :selected-invoice="selectedInvoice"/>
    <deposit-summary-flyout name="deposit-summary" :details="depositSummaryFlyout"></deposit-summary-flyout>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { DataTable, Meatball, MenuItem, DataTableHeader } from "@/components";
import ButtonBar from "@/components/buttons/ButtonBar";
import TableHeader from "@/components/table/TableHeader";
import micromodal from "micromodal";
import { formatDate } from "@/filters";
import { DateTime } from "luxon";
import {
  MARK_AS_DISTRIBUTION_HEADING,
  MARK_AS_DISTRIBUTION_TEXT,
  MARK_AS_DISTRIBUTION_BTN,
  MARK_AS_NOT_DISTRIBUTION_HEADING,
  MARK_AS_NOT_DISTRIBUTION_TEXT,
  MARK_AS_NOT_DISTRIBUTION_BTN,
  BRANCH_PAYMENT_HEADING,
  BRANCH_PAYMENT_TEXT,
  BRANCH_PAYMENT_BTN,
} from "@/modules/admin/constants";
import InvoiceModal from "@/modules/admin/accounting/invoicing/InvoiceModal";
import { formatDatePacific } from "@/filters";
import DepositSummaryFlyout from "@/modules/admin/accounting/invoicing/DepositSummaryFlyout";
import { mapActions } from "vuex";
import debounce from "debounce";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";

export default {
  name: "PaymentDistribution",
  components: {
    InvoiceModal,
    Fragment,
    DataTable,
    ButtonBar,
    Meatball,
    MenuItem,
    DataTableHeader,
    DepositSummaryFlyout,
  },
  data: () => ({
    selectedButton: "to-be-distributed",
    popupHeading: MARK_AS_DISTRIBUTION_HEADING,
    popupBtn: MARK_AS_DISTRIBUTION_BTN,
    popupText: MARK_AS_DISTRIBUTION_TEXT,
    isActive: true,
    targetElement: "",
    toBedistributionloader: true,
    recentlydistributionloader: true,
    checkedPayment: [],
    toBeDistrubutedObj: [],
    recentlyDistrubutedObj: [],
    targetObj: [],
    tableId: "recently-distributed-header",
    selectedInvoiceId: "",
    selectedInvoice:{},
    distributedDate: "",
    ezCostalCsv: [],
    iifDataObj: [],
    paymentData1: [],
    driverDocs: [],
    fileLoaded: false,
    processor: "",
    totalCount: 0,
    searchTerm: "",
    depositLoader: true,
    depositSummaries: [],
    currentPage: 0,
    showTableId: "",
    depositSummaryFlyout: {},
    showing: false,
    branchPaymentData: [],
    invoices: [],
    settelmentID:"",
    lineItems: [],
    totalLineItems: [],
    loadingFile: true,
  }),

  computed: {
    buttons() {
      return [
        { id: "to-be-distributed", label: "To Be Distributed" },
        { id: "recently-distributed", label: "Recently Distributed" },
        { id: "withheld-payment", label: "Withheld Payments" },
      ];
    },
    toBeDistributedHeaders() {
      return [
        new TableHeader({ cellContents: "Name", headerClass: "cell25", label: "Client", name: "Name" }),
        new TableHeader({ cellContents: "PaymentProcessor", headerClass: "cell25", label: "Payment Processor", name: "PaymentProcessor" }),
        new TableHeader({ cellContents: "PayPeriod", headerClass: "cell25", label: "Pay Period", name: "PayPeriod" }),
        new TableHeader({ cellContents: "TotalPayout", label: "Total Payout", name: "TotalPayout" }),
        new TableHeader({ headerClass: "cell15", cellClass: "table-actions", name: "meatball" }),
      ];
    },
    recentlyDistributedHeaders() {
      return [
        new TableHeader({ cellContents: "Name", headerClass: "cell25", label: "Client", name: "Name" }),
        new TableHeader({ cellContents: "Distributed", headerClass: "cell25", label: "Distributed", name: "Distributed" }),
        new TableHeader({ cellContents: "PayPeriod", headerClass: "cell25", label: "Pay Period", name: "PayPeriod" }),
        new TableHeader({ cellContents: "TotalPayout", headerClass: "cell25", label: "Total Payout", name: "TotalPayout" }),
        new TableHeader({ headerClass: "cell25", cellClass: "table-actions", name: "meatball" }),
      ];
    },
    withheldPaymentHeaders() {
      return [
        new TableHeader({ cellContents: "name", headerClass: "cell25", label: "Partner", name: "name" }),
        new TableHeader({ cellContents: "total", headerClass: "cell55 padding-right-0", label: "Deposit Summaries", name: "total" }),
        new TableHeader({ cellContents: "amount", headerClass: "cell15 padding-left-0", label: "Totals", name: "amount" }),
        new TableHeader({ headerClass: "cell5", cellClass: "table-actions", name: "accordian" }),
      ];
    },
    toBeDistrubutedAccount() {
      return this.selectedButton === "to-be-distributed";
    },
    recentlyDistrubutedAccount() {
      return this.selectedButton === "recently-distributed";
    },
    withheldPayments() {
      return this.selectedButton === "withheld-payment";
    },
    perPage() {
      return 50;
    },
    searchIcon() {
      return this.searchTerm !== "";
    },
    garnishmentDate() {
      const today = DateTime.utc();
      return DateTime.utc()
        .plus({ days: `${today.weekday == 1 ? 0 : 8 - today.weekday}` })
        .toFormat("dd LLL yyyy");
    },
    loadFlyout() {
      return Object.keys(this.depositSummaryFlyout).length > 0 ? true : false;
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTerm) {
        this.searchPendingGarnishments();
      } else {
        this.depositLoader = true;
        this.fetchWithheldPayments(50,0)
      }
      this.currentPage = 1;
    },
  },
  mounted() {
    this.fetchToBeDistributed();
    this.searchPendingGarnishments = debounce(this.searchPendingGarnishments, 500);
  },
  methods: {
    ...mapActions(["showFlyout"]),
    buttonsChanged(button) {
      this.selectedButton = button.id;
      switch (this.selectedButton) {
        case "to-be-distributed":
          this.fetchToBeDistributed();
          this.popupHeading = MARK_AS_DISTRIBUTION_HEADING;
          this.popupBtn = MARK_AS_DISTRIBUTION_BTN;
          this.popupText = MARK_AS_DISTRIBUTION_TEXT;
          break
        case "recently-distributed":
        this.fetchDistributed()
          this.popupHeading = MARK_AS_NOT_DISTRIBUTION_HEADING;
          this.popupBtn = MARK_AS_NOT_DISTRIBUTION_BTN;
          this.popupText = MARK_AS_NOT_DISTRIBUTION_TEXT;
          break
        case "withheld-payment":
        this.fetchWithheldPayments(50,0)

          break

      }
    },
    branchPayment() {
      this.processor = "BRANCH";
      this.popupHeading = BRANCH_PAYMENT_HEADING;
      this.popupBtn = BRANCH_PAYMENT_BTN;
      this.popupText = BRANCH_PAYMENT_TEXT;
      micromodal.show("modal-mark-as-paid", {});
    },
    viewInvoice(id) {
      this.selectedInvoiceId = id;
      this.fetchInvoice(this.selectedInvoiceId)
      micromodal.show("paymentDistributionInvoice");
    },
    async markAsDistributed() {
      let changeStatus;
      let today = DateTime.now().toFormat("yyyy-MM-dd");
      const garnishCount = await restApi.post('accounting/getGarnishmentCount',encodeWithParam({distributionID: this.targetElement}))
      if (this.selectedButton === "to-be-distributed") {
        Number(garnishCount?.data?.result[0]?.count) >= 1 ? (changeStatus = "PARTIAL_DISTRIBUTION") : (changeStatus = "DISTRIBUTED");
        this.distributedDate = today;
      } else if (this.selectedButton === "recently-distributed") {
        this.currentObj();
        changeStatus = "READY_FOR_DISTRIBUTION";
        this.distributedDate = today;
      }
      restApi.post('accounting/updateDistributionStatus',encodeWithParam({status: changeStatus, id: this.targetElement, distributedDate: this.distributedDate})).then(() => {
        micromodal.close("modal-mark-as-paid", {});
        if (this.selectedButton === "to-be-distributed" && this.processor == "BRANCH") {
            this.currentObj();
            restApi.post('accounting/updateSettelmentStatus',encodeWithParam({ status: "DISTRIBUTED", id: this.targetObj[0].settlementID })).then(()=>{
              console.log("DISTRIBUTED")
              let payload = {
                  settlementID: this.targetObj[0].settlementID,
                  submissionID: this.targetObj[0].submissionID,
                  action:"BULK_PAYMENTS"
                };
                restApi.post('accounting/disbursePayments',encodeWithParam(payload));
                console.log(payload)

            })
          }
          this.refetchData();
        })
        .catch((response) => this.$log.error(response))
        .finally(() => {
          this.processor = "";
          this.$router.back();
        });
    },
    viewSettlement() {
      this.currentObj();
      this.$router.push({ name: "accountingReview", params: { settlementID: this.targetObj[0].settlementID, client: this.targetObj[0].Name, payPeriod: this.targetObj[0].PayPeriod } });
    },
    showPaidPopUp() {
      micromodal.show("modal-mark-as-paid", {});
    },
    currentElement(elementId) {
      this.targetElement = elementId;
      if (this.selectedButton == "to-be-distributed") {
        this.popupHeading = MARK_AS_DISTRIBUTION_HEADING;
        this.popupBtn = MARK_AS_DISTRIBUTION_BTN;
        this.popupText = MARK_AS_DISTRIBUTION_TEXT;
      } else {
        this.popupHeading = MARK_AS_NOT_DISTRIBUTION_HEADING;
        this.popupBtn = MARK_AS_NOT_DISTRIBUTION_BTN;
        this.popupText = MARK_AS_NOT_DISTRIBUTION_TEXT;
      }
      if (this.targetElement != elementId) {
        this.fileLoaded = false;
      }
    },

    currentObj() {
      this.targetObj = [];
      const that = this;
      if (this.selectedButton === "to-be-distributed") {
        this.toBeDistrubutedObj.forEach((item) => {
          if (that.targetElement === item.Id) {
            that.targetObj.push(item);
          }
        });
      } else if (this.selectedButton === "recently-distributed") {
        this.recentlyDistrubutedObj.forEach((item) => {
          if (that.targetElement === item.Id) {
            that.targetObj.push(item);
          }
        });
      }
    },
    sortDepositSummaries(depositSummaries) {
      const summaries = [];
      const sortedData = depositSummaries.reduce((acc, curr) => {
        const partnerID = curr.partnerID;
        // const locationName = curr.locationName;
        if (acc[partnerID]) {
          acc[curr.partnerID] = [...acc[curr.partnerID], curr];
        } else {
          acc[partnerID] = [curr];
        }
        // acc[locationName] = [curr];
        return acc;
      }, {});

      for (const [key, value] of Object.entries(sortedData)) {
        summaries.push({
          name: `${value[0].firstName} ${value[0].lastName}`,
          total: value.length,
          clientName:process.env.VUE_APP_CLIENT_NAME,
          depositSummaries: value,
          id: key,
          amount: value.reduce((acc, depo) => {
            return depo?.check?.NET?.amount + acc;
          }, 0),
        });
      }
      return summaries;
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
    },
    getDepositDate(item) {
      return `${formatDatePacific(item.generationDate)}`;
    },
    updateTableID(id) {
      this.showTableId = id;
    },
    openFlyout(item) {
      item["payPeriod"] = `${formatDatePacific(item.startDate)} - ${formatDatePacific(item.endDate)}`;
      this.depositSummaryFlyout = item;
      this.showFlyout("deposit-summary");
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      if (this.searchTerm) {
        this.depositLoader = true;
          restApi.post('accounting/searchPendingGarnishments',encodeWithParam({limit: Number(event.limit), offset: Number(event.offset),search: `%${this.searchTerm}%`})).then((data)=>{ 
            this.depositSummaries = this.sortDepositSummaries(data.data.result);
            this.totalCount = this.depositSummaries.length;
            this.depositLoader = false;
          });
      } else {
        this.depositLoader = true;

        restApi.post('accounting/fetchWithheldPayments',encodeWithParam({limit: Number(event.limit), offset: Number(event.offset)})).then((data)=>{
          this.depositSummaries = this.sortDepositSummaries(data.data.result);
        this.depositLoader = false;
        })
      }
    },
    toggle(id) {
      this.showing = !this.showing;
      this.showing ? (this.showTableId = id) : (this.showTableId = "");
    },
    searchPendingGarnishments() {
      this.depositLoader = true;
      restApi.post('accounting/searchPendingGarnishments',encodeWithParam({limit: 50, offset: 0,search: `%${this.searchTerm}%`})).then((data)=>{ 
            this.depositSummaries = this.sortDepositSummaries(data.data.result);
            this.totalCount = this.depositSummaries.length;
            this.depositLoader = false;
          });
      
    },

    async sendPaymentToBranch(data) {
      let payload = {
        depositSummaryID: data.id,
        settlementID: data.settlementID,
        partnerID: data.partnerID,
        distributionID: data.distributionID,
        NET: data.check.NET.amount,
        partnerName: `${data.firstName} ${data.lastName}`,
        distribution: true,
        action:"BRANCH_PAYMENT"
      };
      try {
        restApi.post('accounting/distributionsSingle',encodeWithParam(payload)).then(() => {
          this.fetchWithheldPayments(50,0) 
        })
      } catch (error) {
        this.$log.error(error);
      }
    },

    fetchToBeDistributed(){
      this.toBedistributionloader = true;
      this.toBeDistrubutedObj=[];
      restApi.get('accounting/tobeDistributed').then((data)=>{  
        data.data.result.forEach((item) => {
            let invoices;
            if (item.invoices.length > 0) {
              invoices = item.invoices.map((invoice) => ({ id: invoice.id, name: invoice.name }));
            } else {
              invoices = "";
            }
            let customObj = {
              Id: item.id,
              Name: process.env.VUE_APP_CLIENT_NAME,
              Distributed: formatDate(item.distributedDateTime),
              PaymentProcessor: item.paymentProcessor ? item.paymentProcessor : " ",
              PayPeriod: `${formatDate(item.payPeriodStartDate)} - ${formatDate(item.payPeriodEndDate)}`,
              TotalPayout: item.totalPayout,
              ToBeDistributed: true,
              payPeriodEnd: item.payPeriodEndDate,
              submissionID:item.submissionID,
              settlementID: item?.settlementID[0] ? item.settlementID[0] : "",
              invoices,
            };
            this.toBeDistrubutedObj.push(customObj);
          }, this);
          this.toBedistributionloader = false;
    })
    },

    fetchDistributed(){
      this.recentlydistributionloader = true;
      this.recentlyDistrubutedObj=[];
      restApi.get('accounting/distributed').then((data)=>{
        data.data.result.forEach((item) => {
            let invoices;
            if (item.invoices.length > 0) {
              invoices = item.invoices.map((invoice) => ({ id: invoice.id, name: invoice.name }));
            } else {
              invoices = "";
            }
            let customObj = {
              Id: item.id,
              Name: process.env.VUE_APP_CLIENT_NAME,
              Distributed: formatDate(item.distributedDateTime),
              PaymentProcessor: item.paymentProcessor ? item.paymentProcessor : " ",
              PayPeriod: `${formatDate(item.payPeriodStartDate)} - ${formatDate(item.payPeriodEndDate)}`,
              TotalPayout: item.totalPayout,
              ToBeDistributed: true,
              payPeriodEnd: item.payPeriodEndDate,
              submissionID:item.submissionID,
              settlementID: item?.settlementID[0] ? item.settlementID[0] : "",
              invoices,
            };
            this.recentlyDistrubutedObj.push(customObj);
          }, this);
          this.recentlydistributionloader = false;
    })
    },
     fetchWithheldPayments(limit,offset){
      this.depositLoader = true;;
      this.depositSummaries= [];
      restApi.get('accounting/getWithHeldPaymentsCount').then((res)=>{
        this.totalCount = Number(res.data.result[0].totalcount)  
        restApi.post('accounting/fetchWithheldPayments',encodeWithParam({limit:limit,offset:offset})).then((data)=>{
          this.depositSummaries = this.sortDepositSummaries(data.data.result);
        this.depositLoader = false;
        })
      })

    },
    refetchData(){
      switch (this.selectedButton) {
        case "to-be-distributed":
          this.fetchToBeDistributed();
          break
        case "recently-distributed":
        this.fetchDistributed()
          break
        case "withheld-payment":
        this.fetchWithheldPayments(50,0)

    }
  },

  fetchInvoice(invoiceId) {
    this.loadingFile = true;
      restApi.post('accounting/getInvoice', encodeWithParam({id: invoiceId})).then((data)=>{
        const res = data.data.result[0];

        if (res?.invoice) {
          this.lineItems = res.invoice?.lineItems ? res.invoice.lineItems : [];
          if (typeof this.lineItems[0]?.sortOrder === "number") {
            this.lineItems = this.lineItems.sort((a, b) => a.sortOrder - b.sortOrder);
          }
          this.totalLineItems = this.lineItems;
          this.selectedInvoice = res;
          this.customAddress = res.address; 
          this.loadingFile = false;

        }
      }).catch((e) => {console.log(e)})
    },
    
  }
}
</script>

<style scoped>
tr {
  background: none !important;
}
.accordian-tr {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #dddddd;
}
.space {
  padding-left: 24px;
}
</style>

<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>{{ location.name }}</h1>
        <bread-crumbs :breadcrumbs="breadcrumbs" />
      </div>
      <div class="header-aside">
        <form class="search-container">
          <input type="search" placeholder="Client, Zones, Driver" />
          <button type="submit"><i class="icon-search"></i></button>
        </form>
      </div>
    </div>
    <tabs :tabs="tabs">
      <template #appointments><appointments-tab /></template>
      <template #partnerRequests><partner-requests-tab /></template>
      <template #finances><finance-tab /></template>
    </tabs>
  </fragment>
</template>
<script>
import { BreadCrumbs, Tabs } from "@/components";
import { GET_LOCATION_DETAILS } from "@/modules/clientPortal/queries";
import { Fragment } from "vue-fragment";
import PartnerRequestsTab from "@/modules/clientPortal/locations/partnerRequests/PartnerRequestsTab";
import FinanceTab from "@/modules/clientPortal/locations/finances/FinanceTab";
import AppointmentsTab from "@/modules/clientPortal/locations/appointments/AppointmentsTab";

export default {
  components: { PartnerRequestsTab, FinanceTab, AppointmentsTab, Tabs, Fragment, BreadCrumbs },
  data: () => ({
    tabs: [
      { key: "appointments", label: "Appointments" },
      { key: "partnerRequests", label: "Request Partners" },
      { key: "finances", label: "Finances" },
    ],
    location: {},
  }),
  computed: {
    breadcrumbs() {
      return [{ name: "Locations", path: "cp-locations" }, { name: this.location.name }];
    },
  },
  apollo: {
    location: {
      query: GET_LOCATION_DETAILS,
      variables() {
        return { locationID: this.$route.params.locationID };
      },
      update: (data) => data.clientLocations_by_pk,
    },
  },
};
</script>

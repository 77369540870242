<template>
  <div>
    <hr class="qualifying-line" />
    <expansion-panel ref="qualificationFormPanel" label="View qualification answers">
      <form class="qualification-form">
        <div class="module">
          <a v-if="!editingQualifyingAnswers" class="button secondary" @click="editQualifyingAnswers">Edit Eligibility Answers</a>
          <save-cancel v-if="editingQualifyingAnswers" emit-cancel @onSave="saveQualifyingAnswers" @onCancel="cancelQualifyingAnswers" />
        </div>
        <div v-for="question in eligibilityQuestions" :key="question.id" class="module">
          <eligibility-question v-if="questionCheck(question.key)" v-model="responses" :question="question" :disabled="!editingQualifyingAnswers" @input="updateQuestion" />
        </div>
        <div class="module">
          <save-cancel v-if="editingQualifyingAnswers" emit-cancel @onSave="saveQualifyingAnswers" @onCancel="cancelQualifyingAnswers" />
        </div>
      </form>
    </expansion-panel>
  </div>
</template>
<script>
import EligibilityQuestion from "@/modules/admin/onboarding/components/EligibilityQuestion";
import { ExpansionPanel, SaveCancel } from "@/components";

export default {
  components: { EligibilityQuestion, ExpansionPanel, SaveCancel },
  props: {
    eligibilityQuestions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    editingQualifyingAnswers: false,
    responses: {},
  }),
  computed: {
    eligibilityInfo() {
      return this.$props.eligibilityQuestions.reduce(
        (eligibilityInfo, question) => ({
          ...eligibilityInfo,
          [question.key]: this.responses[question.key] ? this.responses[question.key] : question.response,
        }),
        {}
      );
    },
  },
  mounted() {
    this.responses = this.$props.eligibilityQuestions.reduce(
      (eligibilityInfo, question) => ({
        ...eligibilityInfo,
        [question.key]: this.responses[question.key] ? this.responses[question.key] : question.response,
      }),
      {}
    );
  },
  watch: {
    eligibilityQuestions(newValue) {
       this.responses = newValue.reduce(
      (eligibilityInfo, question) => ({
        ...eligibilityInfo,
        [question.key]:   question.response,
      }),
      {}
    );
    }
  },
  methods: {
    editQualifyingAnswers() {
      this.editingQualifyingAnswers = true;
    },
    saveQualifyingAnswers() {
      this.$emit("onSaveQualifyingAnswers", this.responses);
      this.editingQualifyingAnswers = false;
      //this.$refs.qualificationFormPanel.toggle();
    },
    cancelQualifyingAnswers() {
      this.editingQualifyingAnswers = false;
    },
    updateQuestion(answers) {
      this.responses = { ...this.responses, answers };
    },
    questionCheck(key) {
      if (key == "vehicleType" || key == "isWalmartAssociate") {
        return false;
      }
      return true;
    }

  },
};
</script>

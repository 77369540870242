import authenticationService from "@/services/authentication.service";
import * as Roles from "@/security/rolesHelper";
import {store} from "@/store";

export default async (to, from, next) => {
  let accounting, reports;
  const user = authenticationService.loggedInUser();
  const employee = { role: user.role };

  if (Roles.isAdminRole(employee)) {
    if (from.path === "/" && (!to.redirectedFrom || to.redirectedFrom === "/")) {
      await store.dispatch("fetchPermissions");
      [accounting, reports] = [store.getters.getPermission("viewAccounting"), store.getters.getPermission("viewReports")]
    }
    
    if ((!accounting && to.path.split("/").includes("accounting")) || (!reports && to.path.split("/").includes("reports"))) {
      next({ path: from.path })
    }
    next();
  } else {
    // Maybe we have an error page that tells them they don't have permission to see that page...or just a generic forbidden or something
    next({ name: "login" });
  }
};

const loggingOptions = {
  isEnabled: true,
  logLevel: process.env.VUE_APP_LOGGING_LEVEL || "error",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

export default loggingOptions;

<template>
  <base-filter v-bind="$props">
    <item-picker no-title :search-results="$props.options" placeholder="Zone Name" auto-select @itemsChanged="updateFilters" />
  </base-filter>
</template>
<script>
import { BaseFilter } from "@/components";
import ItemPicker from "@/components/pickers/ItemPicker";
export default {
  components: { BaseFilter, ItemPicker },
  props: {
    name: {
      type: String,
      required: true,
      default: "Filter",
    },
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    minWidth: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    updateFilters(itemChangedEvent) {
      this.$emit("onFilter", itemChangedEvent.items);
    },
  },
};
</script>

<template>
  <fragment>
    <div class="radio-custom">
      <input id="yes" type="radio" :name="$props.transactionModifier.name" value="YES" :checked="'YES' === $props.value" @change="update" />
      <label for="yes"><span class="label-container">Yes</span></label>
    </div>
    <div class="radio-custom">
      <input id="no" type="radio" :name="$props.transactionModifier.name" value="NO" :checked="'NO' === $props.value" @change="update" />
      <label for="no"><span class="label-container">No</span></label>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

export default {
  components: { Fragment },
  props: {
    value: {
      type: String,
      required: false,
      default: "NO",
    },
    transactionModifier: {
      type: Object,
      required: true,
    },
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
      this.$emit("change", event.target.value);
    },
  },
};
</script>

class TableHeader {
  /**
   * @param {string} [slotName] The name of the column. Can be used for slot management.
   * @param {string} [label] The label to be used on the header
   * @param {string | function(item:T):string} [cellContents] the property name of the item or a function that calculates the contents of cells under this header. Required if not using a slot
   * @param {string} [headerClass] class to be applied to the header
   * @param {string | function(item:T): string} [cellClass] class to be applied to the header and all cells underneath it
   * @param {boolean} [sortable] whether the column is sortable. Default is false
   * @param {boolean} [sorted] whether to start with this column as the sorted column
   * @param {string} [direction] the initial direction of the sort. Default is unsorted. Should only apply to one header
   * @param {function(direction: string): function(a: T, b: T):number} [sortFunction] function that includes the sort direction and that returns a comparator function defining how to compare items in the list for sorting purposes. Will default to (b - a) logic
   * @param {boolean} [accordian] whether it should display accordian at header level. Default is false
   * @template T
   */
  constructor({ name, label, cellContents, headerClass, cellClass, sortable, sorted, direction, sortFunction, accordian }) {
    this.name = name;
    this.label = label ? label : "";
    this.cellContents = cellContents;
    this.cellClass = cellClass ? cellClass : "";
    this.headerClass = headerClass ? headerClass : "";
    this.sorted = sorted;
    this.direction = direction ? direction.toString() : "";
    this.sortable = !!sortable;
    this.accordian = !!accordian;
    if (sortable) {
      if (sortFunction !== undefined) {
        this.sortFunction = sortFunction;
      } else {
        this.sortFunction = () => (a, b) => {
          const valueA = typeof this.cellContents === "string" ? a[this.cellContents] : this.cellContents(a);
          const valueB = typeof this.cellContents === "string" ? b[this.cellContents] : this.cellContents(b);
          if (typeof valueA === "string" || typeof valueB === "string") {
            const firstString = valueA ? valueA : "";
            const secondString = valueB ? valueB : "";
            return firstString.localeCompare(secondString);
          }

          if (typeof valueA === "number" || typeof valueB === "number") {
            return valueA - valueB;
          }

          if (valueA instanceof "date" || valueB instanceof "date") {
            return new Date(valueB) - new Date(valueA);
          }

          return 0;
        };
      }
    } else {
      this.sortFunction = undefined;
    }
  }
}

export default TableHeader;

<template>
  <validation-provider v-slot="{ errors }" :name="$props.name" mode="aggressive" rules="required" slim>
    <date-pick :value="value" :input-attributes="{ readonly: true }" v-bind="{ ...$attrs, ...$props }" @input="update" :isDateDisabled="isFutureDate">
      <template #default="{ inputValue, toggle }">
        <div class="field" :style="computeWidth">
          <label :for="dateId" :class="{ invisible: $props.invisible }">{{ $props.label }}{{ $props.required && $props.label.length > 0 ? "*" : "" }}</label>
          <div class="input-has-icon" :class="{ inline: $props.inlineLabel }" @click="toggle">
            <div class="input-icon"><i class="icon-calendar"></i></div>
            <input :id="dateId" :value="inputValue" type="text" :name="$props.name" placeholder readonly required :class="{ 'has-error': errors.length > 0 }" />
          </div>
          <p class="error-message">{{ errors[0] }}</p>
        </div>
      </template>
    </date-pick>
  </validation-provider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import DatePick from "vue-date-pick";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "DatePicker",
  components: { DatePick, ValidationProvider },
  props: {
    value: [String, Date],
    label: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "Start Date",
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    inlineLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    invisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableDates: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computeWidth() {
      if (this.$props.width) {
        return { width: `${this.$props.width}px` };
      }
      return {};
    },
    dateId() {
      return `startDate-${uuidv4()}`;
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
    isFutureDate(date) {
      return this.$props.disableDates ? date < new Date() : this.$props.disableDates;
    }
  },
};
</script>

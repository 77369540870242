<template>
  <div>
    <div v-if="paginationTotalCount > paginationPerPage" class="flex-right row-reverse margin-bottom-16">
      <paginator
        :total="paginationTotalCount"
        :per-page="paginationPerPage"
        :sync-page="paginationCurrentPage"
        :display-rows-per-page-selector="displayRowsPerPageSelector"
        :display-rows-per-page-max-selector="displayRowsPerPageMaxSelector"
        @onPaginate="onPaginate($event)"
        @onChangePerPage="onChangePerPage($event)"
      />
    </div>
    <div v-if="loading">
      <table-loader :min-height="minHeight" />
    </div>
    <div v-else-if="tableContents.length === 0" class="well text-center no-contents">
      <slot name="noContents"><p>No records found</p></slot>
    </div>
    <div v-else>
      <table>
        <thead :class="{'sticky-header':$props.stickyHeader}" v-if="!$props.hideHeaders">
          <tr>
            <data-table-header v-for="(header, index) in sortableHeaders" :key="`${$props.tableId}-${index}`" :index="index" :header="header" @onSort="sort" @onToggle="toggle" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, rowIndex) in tableContents" :key="newKey(item)" :class="$props.rowClassFunction(item)" @click="rowSelected(item)">
            <td v-for="(header, index) in $props.headers" :key="`${index}-${newKey(item)}`" :class="[header.headerClass, cellClass(item, header.cellClass)]">
              <slot :name="header.name" :item="{ ...item, index: $props.useIndex ? rowIndex : undefined }" :cellIndex="index" :rowIndex="rowIndex">{{ cellContents(item, header.cellContents) }}</slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="paginationTotalCount > paginationPerPage" class="flex-right row-reverse mt16">
      <paginator
        :total="paginationTotalCount"
        :per-page="paginationPerPage"
        :sync-page="paginationCurrentPage"
        :display-rows-per-page-selector="displayRowsPerPageSelector"
        :display-rows-per-page-max-selector="displayRowsPerPageMaxSelector"
        @onPaginate="onPaginate($event)"
        @onChangePerPage="onChangePerPage($event)"
      />
    </div>
  </div>
</template>
<script>
import DataTableHeader from "@/components/table/DataTableHeader";
import { v4 as uuidv4 } from "uuid";
import Direction from "@/components/table/direction";
import TableLoader from "@/components/loader/TableLoader";
import Paginator from "@/components/paginator/Paginator";

export default {
  name: "DataTable",
  components: { Paginator, TableLoader, DataTableHeader },
  props: {
    stickyHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    paginationPerPage: {
      type: Number,
      required: false,
      default: 50,
    },
    paginationTotalCount: {
      type: Number,
      required: false,
      default: 0,
    },
    paginationCurrentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    displayRowsPerPageSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayRowsPerPageMaxSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    remoteSort: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableId: {
      type: String,
      default: () => uuidv4(),
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    entries: {
      type: Array,
      required: true,
      default: () => [],
    },
    hideHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClassFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
    rowClickable: Boolean,
    minHeight: String,
    useIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["onChangePerPage", "onPaginate"],
  data: function () {
    return {
      tableContents: [...this.$props.entries],
      sortableHeaders: [...this.$props.headers],
    };
  },
  watch: {
    entries: function (newVal) {
      const sortedHeader = this.sortableHeaders.filter((header) => {
        return header.sorted;
      });
      if (!this.$props.remoteSort && sortedHeader && sortedHeader.length > 0) {
        newVal.sort(sortedHeader[0].sortFunction(sortedHeader[0].direction));
        if (sortedHeader[0].direction === Direction.DESC) {
          newVal.reverse();
        }
      }
      this.tableContents = [...newVal];
    },
    headers: function (newVal) {
      this.sortableHeaders = [...newVal]
    }
  },
  mounted() {
    let newVal = this.tableContents;
    const sortedHeader = this.sortableHeaders.filter((header) => {
      return header.sorted;
    });
    if (sortedHeader && sortedHeader.length > 0) {
      newVal.sort(sortedHeader[0].sortFunction(sortedHeader[0].direction));
      if (sortedHeader[0].direction === Direction.DESC) {
        newVal.reverse();
      }
    }
    this.tableContents = [...newVal];
  },
  methods: {
    onChangePerPage(event) {
      this.$emit("onChangePerPage", event);
    },
    onPaginate(event) {
      this.$emit("onPaginate", event);
    },
    onSort(event) {
      this.$emit("onSort", event);
    },
    cellClass(item, classname) {
      if (typeof classname === "string") return classname;
      if (typeof classname === "function") return classname(item);
      return "";
    },
    cellContents(item, cellValue) {
      if (typeof cellValue === "string") {
        return item ? item[cellValue] : "";
      } else if (typeof cellValue === "function") {
        return cellValue(item);
      } else {
        return "";
      }
    },
    sort(clickedHeader) {
      this.sortableHeaders.forEach((header, index) => {
        if (index === clickedHeader) {
          header.sorted = true;
          header.direction = !header.direction || header.direction === Direction.DESC ? Direction.ASC : Direction.DESC;
          if (this.$props.remoteSort) {
            this.onSort(header);
          } else {
            const sortFunction = header.sortFunction(header.direction);
            this.tableContents.sort(sortFunction);
            if (header.direction === Direction.DESC) {
              this.tableContents.reverse();
            }
          }
        } else {
          header.sorted = false;
          header.direction = null;
        }
      });
      this.sortableHeaders = [...this.sortableHeaders];
    },
    newKey() {
      return `${this.$props.tableId}-${uuidv4()}`;
    },
    rowSelected(item) {
      if (this.$props.rowClickable) {
        this.$emit("onRowSelected", item);
      }
    },
    toggle(value) {
      this.$emit("onToggle", value);
    }
  },
};
</script>

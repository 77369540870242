<template>
  <fragment>
    <div class="filters">
      <div class="filter-toggle" :style="{ minWidth: $props.minWidth }" @click="toggleFilter">
        {{ $props.name }}
        <i :class="{ 'icon-dropdown-right': filterOn, 'icon-dropdown-down': !filterOn }"></i>
      </div>
      <div class="filter-options" :class="{ hidden: !filterOn }">
        <slot>
          <div v-for="option in $props.options" :key="option.id" class="filter-option" :class="{'preSelect': option.name==preSelect && selectedFilter==null}">
            <input
              v-if="$props.type === 'checkbox'"
              :id="'filter-option-' + option.id"
              v-model="option.checked"
              :type="$props.type"
              :name="option.name"
              :checked="option.checked"
              @click="changeFilterList(option.label, $event.target.checked,option)"
            />
            <input
              v-if="$props.type === 'radio'"
              :id="'filter-option-' + option.id"
              v-model="selectedFilter"
              :type="$props.type"
              :value="option.name"
              :name="filterName"
              :checked="option.checked"
              @click="changeFilterList(option.label, $event.target.checked,option)"
            />
            <label :for="'filter-option-' + option.id">{{ option.label }}</label>
          </div>
        </slot>
      </div>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { v4 as uuidv4 } from "uuid";

export default {
  components: { Fragment },
  props: {
    name: {
      type: String,
      required: true,
      default: "Filter",
    },
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    filterToggleOn: {
      type: Boolean,
      required: false,
      default: false,
    },
    minWidth: {
      type: String,
      required: false,
      default: null,
    },
    preSelect: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      filterOn: this.$props.filterToggleOn,
      filterItems: this.initFilterItems(),
      initialFilterItems: [],
      selectedFilter: null,
    };
  },
  computed: {
    filterName() {
      return `filter-${uuidv4()}`;
    },
  },
  methods: {
    initFilterItems() {
      const items = [];
      for (let i = 0; i < this.$props.options.length; i++) {
        if (this.$props.options[i].checked) {
          items.push(this.$props.options[i].label.toLowerCase());
        }
      }
      this.initialFilterItems = [...items];
      return items;
    },
    toggleFilter() {
      this.filterOn = !this.filterOn;
    },
    changeFilterList(optionLabel, optionChecked,option) {
      this.$log.debug(optionLabel, optionChecked);
      if (this.$props.type === "radio") {
        if (this.filterItems.length > 0 && this.filterItems[0] === optionLabel.toLowerCase()) {
          this.filterItems = [];
          this.selectedFilter = null;
        } else {
          this.filterItems = [optionLabel.toLowerCase(),option];
        }
      } else if (this.$props.type === "checkbox") {
        if (optionChecked) {
          this.filterItems.push(optionLabel.toLowerCase());
        } else {
          this.filterItems.splice(this.filterItems.indexOf(optionLabel.toLowerCase()), 1);
        }
      }
      this.$emit("onFilter", this.filterItems);
    },
    reset() {
      this.filterItems = [...this.initialFilterItems];
      this.$props.options.forEach((option) => {
        if (this.filterItems.includes(option.label.toLowerCase())) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });
      this.selectedFilter = null;
      this.$emit("onFilter", this.filterItems);
    },
  },
};
</script>

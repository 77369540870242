<template>
  <div id="modal-delete-settlements" class="modal" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
      <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <div class="modal-content">
          <div class="module">
            <h4>Clear Entries and Delete Settlement Info</h4>
            <p>All entries for this settlement will be completely erased.</p>
          </div>
          <div class="button-group close-top">
            <a class="button warning" @click="deleteSettlement">Delete Submission</a>
            <a class="button secondary" data-micromodal-close="data-micromodal-close">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    locationId: {
      type: String,
      required: true,
      default: "",
    },
  },
  methods: {
    deleteSettlement() {
      this.$router.push({ name: "cp-location", params: { locationID: this.$props.locationId }, hash: "#finances" });
    },
  },
};
</script>

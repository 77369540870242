<template>
  <div class="container sticky-header" :style="{ borderColor: $props.color }">
    <div class="content">
      <h2 v-if="titleExists">{{ $props.title }}</h2>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "StickyHeader",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    titleExists() {
      return this.title && this.title.length > 0;
    },
  },
};
</script>

<template>
  <fragment>
    <div class="flex space-between">
      <div class="col-12" v-if="cDisplayToast">
        <Message class="toast" @close="closeToast" :severity="toastSeverity"> {{toastMessage}}</Message>
      </div>
      <div class="col-6">
        <p class="label">Filters</p>
        <base-filter name="View" :options="viewFilterOptions" type="radio" :filterToggleOn="true" preSelect="disbursementView"
          @onFilter="fetchFilteredView('view', $event)" page="reconciliation"/>
        <base-filter name="Payment Provider" :options="paymentFilterOptions" type="radio"  :preCheck="preCheck"
          @onFilter="fetchFilteredView('provider', $event)" page="reconciliation"/>
        <base-filter name="Status" :options="statusFilterOptions" type="radio" 
          @onFilter="fetchFilteredView('status', $event)" page="reconciliation"/>
        <base-filter name="Type" :options="typeFilterOptions" type="radio" 
          @onFilter="fetchFilteredView('invoicetype', $event)" page="reconciliation"/>
      </div>
      <div class="col-6 p-right-0 cell60">
        <div class="flex justify-end">
          <div class="col-2 p-right-0" v-if="displayView === 'settlement view'">
            <div class="search-container" style="width: 285px">
              <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search" @keyup.enter="fetchFilteredView('view', ['settlement view'])" />
              <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span
                  v-else class="icon-search deposit-icon"></span></button>
            </div>
          </div>
          <div>
            <date-picker v-model="dateRange" range format="DD-MMM-YYYY" :disabled-date="(date) => this.displayView === 'hold'? date >= new Date(): false" @input="fetchFilteredView('date', $event)"></date-picker>
          </div>
          <div class="col-2 tooltip-i p-right-0" @click="downloadReconCsv()">
            <a v-if="!downloadLoader"><img src="@/assets/images/logos/download.svg" /></a>
            <span v-else class="loading"><Loader /></span>
            <span class="tooltip-text summary-tooltip-align">Click to download</span>
          </div>
        </div>
        <div class="flex justify-end">
          <div class="hlgt-block margin-right-20 inline-flex">
            <div class="amount-block">
              <p :class="totalView()" class="totals-num">{{ disbursementTotal | formatMoney }}</p>
            </div>
            <p class="totals-text">{{ getGlobalTotalTextLabels().disburse }}</p> 
          </div>
          <div class="hlgt-block inline-flex">
            <div class="amount-block">
              <p :class="totalView()" class="totals-num">{{ formatMoney(settlementTotal, displayView === 'hold') }}</p>
            </div>
            <p class="totals-text">{{ getGlobalTotalTextLabels().settled }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayView === 'disbursement view'">
      <div class="flex">
        <h6 class="margin-l-8 disbursement-width">Disbursements</h6>
        <h6 class="margin-settlement-invoice">Settlement by Invoice</h6>
      </div>
      <div :class="getPagination()">
        <data-table :sticky-header="true" :entries="disbursementViewEntries" :headers="disbursementViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)" :parentToggle="parentToggle">
        <template #InvoiceType="{ item }">
          {{ item.invoice_report_type }}
        </template>
        <template #disbursed_amount="{ item }"> {{ item.disbursed_amount | formatMoney }} </template>
        <!-- <template #Balance="{ item }"> {{ item.balance | formatMoney }} </template> -->
        <template #Balance="{ item }">
          <div v-if="checkFutureDate(item) && payProvider !== 'ALL'" @click="openReconModal('disbursement-view', item)" class="tooltip-i">
            <div class="dot-small reconciled"></div>
            <a>{{ item.balance | formatMoney }}</a>
            <span class="tooltip-text balance-tooltip-align">Click to view details.</span>
          </div>
          <div v-else>
            <p class="font-12">{{ item.balance | formatMoney }}</p>
          </div>
        </template>
        <template #disbursement_date="{ item }">
          <div class="tooltip-i flex">
             <div v-if="payProvider !== 'ALL'">
               <a @click="downloadDisbursedCSV(item.disbursement_date, item)">{{ formatDate(item.disbursement_date) }}</a>
              </div>
             <div v-else style="color: black">
                {{ formatDate(item.disbursement_date) }}
              </div>
            <div class="dot-small failed" v-if="item.failureCount > 0"></div>
            <!-- <span class="tooltip-text disburse-tooltip-align">Click to download.</span> -->
          </div>
        </template>
        <template #settlement_date="{ item }">
          <table>
            <thead>
              <tr class="tr-height"></tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ formatDate(val.settlement_date) }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #settled_volume="{ item }">
          <table>
            <thead>
              <tr style="height: 18px">
                {{
                item.settled_volume
                }}
              </tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.settled_volume }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #settled_amount="{ item }">
          <table>
            <thead>
              <tr style="height: 18px">
                {{
                item.settled_amount | formatMoney
                }}
              </tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.settled_amount | formatMoney }}
                </div>
              </tr>
              </div>
            </tbody>
           
          </table>
        </template>
        <template #Status="{ item }">
          <div class="dot-col-align">
            <div class="dot"
              :class="{ approved: item.status === 'APPROVED', reconciled: ['RECONCILED', 'ADJUSTED'].includes(item.status), pending: item.status === 'PENDING' }">
            </div>
            <span>{{ item.status }}</span>
          </div>
        </template>
        <template #invoice_date="{ item }">
          <table>
            <thead>
              <tr class="tr-height"></tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ formatDate(val.invoice_date) }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #payment_provider="{ item }">
          <table>
            <thead>
              <tr class="tr-height"></tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr tooltip-i" :class="getHeight(val)">
                <p style="font-size:12px">{{val.settlement_provider}}</p>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #InvoiceNo="{ item }">
          <table>
            <thead>
              <tr class="tr-height"></tr>
            </thead>
            <tbody v-if="disburseTableId">
              <div v-if="isactive === item.index || globalExpansion">
              <tr v-for="(val, index) in disburseCollapseData[`${item.disbursement_date.substr(0,10)}_${item.invoice_report_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr tooltip-i" :class="getHeight(val)">
                  <a @click="downloadInvoiceCSV(val)">{{ val.settlement_invoice }}
                    <!-- <span class="tooltip-text">Click to download</span> -->
                  </a>
                </div>
              </tr>
              </div>
              <!-- <tr v-for="(row, index) in disburseCollapseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{disburseCollapseData.message}}</div>
              </tr> -->
            </tbody>
          </table>
        </template>
        <!-- <template #Expand="{ item }">
          <div @click="toggleDisbursementView(item)" :class="{ active: isactive === item.index }" class="toggle-icon">
            <i :class="{ 'icon-chevron-up': isactive === item.index, 'icon-chevron-down': isactive !== item.index }"></i>
          </div>
        </template> -->
      </data-table>
      </div>
    </div>
    <div v-if="displayView === 'settlement view'">
      <div class="flex">
        <h6 class="margin-l-16 width-invoice">Invoice</h6>
        <h6 class="margin-disbursements">Disbursements</h6>
      </div>
      <data-table :sticky-header="true" :entries="settlementViewEntries" :headers="settlementViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)">
        <template #InvoiceNo="{ item }">
          <div class="tooltip-i">
            <a @click="downloadInvoiceCSV(item)">{{ item.settlement_invoice }}</a>
            <!-- <span class="tooltip-text invoice-tooltip-align">Click to download</span> -->
          </div>
        </template>
        <template #settled_amount="{ item }">{{ item.settled_amount }}</template>
        <template #settlement_date="{ item }">
          <div>{{ formatDate(item.invoice_date_pst) }}</div>
        </template>
        <template #InvoiceType="{ item }">
          <div>
            {{ item.pay_type }}
          </div>
        </template>
        <template #Status="{ item }">
          <div class="dot-col-align">
            <div class="dot"
              :class="{ approved: item.status === 'APPROVED', reconciled: ['RECONCILED', 'ADJUSTED'].includes(item.status), pending: item.status === 'PENDING' }">
            </div>
            <div v-if="item.status !== 'RECONCILED'" class="tooltip-i">
              <a @click="openReconStatusModal('settlement-view',item)">{{ item.status }}</a>
              <span class="tooltip-text balance-tooltip-align">Click to change status.</span>
            </div>
            <span v-else>{{ item.status }}</span>
          </div>
        </template>
        <template #disbursement_date="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ formatDate(val.disbursement_date) }}
                </div>
              </tr>
              <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #disbursement_volume="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> {{ item.disbursed_volume }} </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ val.disbursed_volume }}
                </div>
              </tr>
               <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #disbursement_amount="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> {{ item.disbursed_amount | formatMoney }} </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ val.disbursed_amount | formatMoney }}
                </div>
              </tr>
               <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #Expand="{ item }">
          <div @click="toggleSettlementView(item)" :class="{ active: isactive === item.index }" class="toggle-icon">
            <i :class="{ 'icon-chevron-up': isactive === item.index, 'icon-chevron-down': isactive !== item.index }"></i>
          </div>
        </template>
      </data-table>
    </div>
    <div v-if="displayView === 'hold'">
        <data-table :sticky-header="true" :entries="holdViewEntries" :headers="holdViewHeaders"
          :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
          @onPaginate="fetchData($event)">
          <template #partnerID="{ item }">
            <a @click="redirectProfile(item['partnerID'])">{{ `..........${item['partnerID'].substr(-4)}` }}</a>
          </template>
          <template #DriverName="{ item }">{{ item.DriverName }}</template>
          <template #email="{ item }">{{ item.email }}</template>
          <template #phone="{ item }">{{ item.phone }}</template>
          <template #last_failed_provider="{ item }">{{ item.last_failed_provider }}</template>
          <template #amount="{ item }">{{ item.amount }}</template>
          </data-table>
      </div>
    <!-- <div v-else class="well text-center no-contents">
      <slot name="noContents">
        <p>No records found</p>
      </slot>
    </div> -->
    <reconciliation-transaction-modal recon-modal-name="reconTxnModal" :type="reconModalType" :rowItem="rowItem" />
    <reconciliation-status-modal recon-status-modal-name="reconStatusModal" @close="closeReconStatusModel" :type="reconStatusModalType" :rowItem="statusItem" />
    <div id="recon-loader-modal" class="modal" aria-hidden="true">
      <div :class="{ 'modal-overlay': true }" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <vue-simple-spinner />
      </div>
    </div>
  </fragment>
</template>

<script>
  import { BaseFilter, DataTable, TableHeader, Loader } from "@/components";
  import { Fragment } from "vue-fragment";
  import { reconciliationApi } from "@/http/http.config";
  import { encodeWithParam, downloadFile } from "@/filters";
  import micromodal from "micromodal";
  import DateTime from "luxon/src/datetime";
  import ReconciliationTransactionModal from "./ReconciliationTransactionModal.vue";
  import Message from 'primevue/message';
  import DatePicker from 'vue2-datepicker';
  import VueSimpleSpinner from "vue-simple-spinner";
  import 'vue2-datepicker/index.css';
  import ReconciliationStatusModal from "./ReconciliationStatusModal.vue";
  export default {
    components: {
      BaseFilter,
      Fragment,
      DataTable,
      ReconciliationTransactionModal,
      ReconciliationStatusModal,
      Message,
      Loader,
      DatePicker,
      VueSimpleSpinner
    },
    data: () => ({
      searchTerm: "",
      displayView: "disbursement view",
      showing: false,
      disburseTableId: false,
      settlementTableId: false,
      disbursementEntries: [],
      settlementEntries: [],
      loader: false,
      disburseCollapseData: {},
      settlementCollpaseData: {},
      status: "",
      reconModalType: "disbursement-view",
      rowItem: {},
      showError: false,
      displayToast: false,
      toastMessage: '',
      toastSeverity: '',
      downloadLoader: false,
      holdEntries:[],
      invoiceType: "",
      disbursementTotal: 0,
      settlementTotal: 0,
      dateRange: null,
      provider: "",
      currentPage: 1,
      disbAggStartDt: "",
      disbAggEndDt: "",
      invoiceIds: [],
      chevronStatus: false,
      statusItem: {},
      reconStatusModalType: "disbursement-view",
      preCheck: false,
      parentToggle: false,
      perPage: 50,
      totalPaginationCount: 0,
      globalExpansion: false,
    }),
    mounted() {
      this.fetchFilteredView('view', ['disbursement view']);
    },
    computed: {
      cDisplayToast() {
        return this.displayToast;
      },
      viewFilterOptions() {
        return [
          { id: "disbursement-view", name: "disbursementView", label: "Disbursement View", checked: true },
          { id: "settlement-view", name: "settlementView", label: "Settlement View", checked: false },
          { id: "hold-view", name: "holdView", label: "Hold", checked: false },
        ];
      },
      paymentFilterOptions() {
        return [
          // { id: "one", name: "one", label: "ONE" },
          // { id: "ach", name: "ach", label: "ACH" },
          // { id: "branch", name: "branch", label: "BRANCH"},
          { id: "payfare", name: "payfare", label: "PAYFARE"}
        ];
      },
      statusFilterOptions() {
        if (this.displayView === 'disbursement view') {
          return [
            { id: "all", name: "all", label: "All" },
            { id: "pending", name: "pending", label: "Pending" },
            { id: "reconciled", name: "reconciled", label: "Reconciled" },
            { id: "adjusted", name: "adjusted", label: "Adjusted" }
          ];
        } else {
            return [
              { id: "all", name: "all", label: "All" },
              { id: "pending", name: "pending", label: "Pending" },
              { id: "reconciled", name: "reconciled", label: "Reconciled" },
            ];
        }
      },
      typeFilterOptions() {
        return [
          { id: "allTypes", name: "allTypes", label: "All" },
          { id: "tips", name: "tips", label: "Tips" },
          { id: "main", name: "main", label: "Earnings" }
        ]
      },
       
      disbursementViewHeaders() {
        return [
          new TableHeader({ headerClass: "cell8 padding-th", label: "Type", name: "InvoiceType", cellClass: "font-12", cellContents: "pay_type" }),
          new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "disbursement_date", cellContents: "disbursement_date", cellClass: "font-12 breadcrumbs" }),
          new TableHeader({ headerClass: "cell5 padding-th", label: "Volume", name: "disbursed_volume", cellContents: "disbursed_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Value", name: "disbursed_amount", cellContents: "disbursed_amount", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "settlement_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5 padding-th", label: "Volume", name: "settled_volume", cellContents: "settled_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Value", name: "settled_amount", cellContents: "settled_amount", cellClass: "right-align font-12" }),
          // new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Adjusted Amount", name: "adjusted_amount", cellContents: "adjusted_amount", cellClass: "right-align breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Balance", name: "Balance", cellContents: "balance", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell11 padding-th", label: "Status", name: "Status", cellContents: "status", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "invoice_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8 padding-th", label: "Earnings Provider", name: "payment_provider", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell15 padding-th", label: "Invoice No", name: "InvoiceNo", cellClass: "breadcrumbs font-10" }),
          new TableHeader({ headerClass: "cell5 padding-th", cellClass: "table-actions", name: "accordian", accordian: true }),
        ];
      },
      settlementViewHeaders() {
        return [
          new TableHeader({ headerClass: "cell12", label: "Type", name: "InvoiceType", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20", label: "Invoice No", name: "InvoiceNo", cellContents: "settlement_invoice", cellClass: "breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Date", name: "settlement_date", cellContents: "settlement_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Volume", name: "settlement_volume", cellContents: "settled_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10 right-align", label: "Value", name: "settlement_amount", cellContents: "settled_amount", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Date", name: "disbursement_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Volume", name: "disbursement_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell12 right-align", label: "Value", name: "disbursement_amount", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell12", label: "Status", name: "Status", cellContents: "status", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5", cellClass: "table-actions", name: "accordian", accordian: true }),
        ];
      },
      disbursementViewEntries() {
        this.disbursementEntries?.forEach((item) => {
          item.balance = Math.abs(Number(Number(item.disbursed_amount) - Number(item.settled_amount)).toFixed(2));
        });
        return this.disbursementEntries;
      },
      holdViewHeaders() {
          return [
            new TableHeader({ headerClass: "cell8", label: "Partner ID", name: "partnerID", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell20", label: "Partner Name", name: "DriverName", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Email Id", name: "email", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Phone", name: "phone", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell20 text-center", label: "Last Failed Payment Provider", name: "last_failed_provider", cellClass: "font-12" }),
            new TableHeader({ headerClass: "cell8", label: "Amount", name: "amount", cellClass: "font-12" }),
          ];
        },
      settlementViewEntries() {
        this.settlementEntries?.forEach((item) => {
          item.disbursement_date = this.settlementCollpaseData[item.settlement_invoice] ? this.settlementCollpaseData[item.settlement_invoice][0]?.disbursement_date : "";
          item.disbursement_volume = this.settlementCollpaseData[item.settlement_invoice]?.length > 0 ? this.settlementCollpaseData[item.settlement_invoice]?.reduce((acc, el) => acc + Number(el.disbursed_volume), 0) : 0;
          item.disburement_value = this.settlementCollpaseData[item.settlement_invoice]?.reduce((acc, el) => acc + Number(el.disbursed_amount), 0);
        })
        return this.settlementEntries;
      },
      holdViewEntries(){
          return this.holdEntries;
        },
      limit() {
        return this.dateRange != null && this.dateRange[1] != null ? null : 70
      },
      payProvider() {
        return !this.isValue(this.provider) ?  [ "PAYFARE" ] : [this.provider];
      },
     
    },
    methods: {
      getPagination(){
        return this.loader || this.totalPaginationCount == 0 ? '' : 'data-table';
      },
      redirectProfile(partnerID) {
          document.body.classList.remove("no-scroll");
          this.$router.push({ name: "partner", params: { id: partnerID } });
        },
        async fetchFilteredView(type, value, resetPagination = true) {
        this.loader = true;
        this.disbursementTotal = 0;
        this.settlementTotal = 0;
        this.isactive = null;
        this.expandedRow = null;
        this.toggleDisplay(false);
        if(resetPagination){
          this.disbursementTotal = 0;
          this.settlementTotal = 0;
        }
        if (type === "view") {
          this.toggleDisplay(false);
          this.displayView = value[0] || this.displayView;
          this.dateRange = [];
        }
        if(this.displayView === 'hold' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        if(this.displayView === 'disbursement view' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        if(this.displayView === 'settlement view' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        if (type === "status") this.status = value[0]?.toUpperCase();
        if (type === "invoicetype") this.invoiceType = value[0];
        if (type === "provider") {
          this.preCheck = false;
          this.toggleDisplay(false);
          this.provider = value[0]?.toUpperCase();
        }
        if (["BRANCH", "HOLD"].includes(this.provider) && this.displayView === "settlement view") {
          this.preCheck = true;
          this.provider = "ONE";
        }
        const { start, end } = this.getDateRange();
        const param = {
          status: !this.isValue(this.status) || this.status == "ALL" ? ["PENDING", "RECONCILED", "APPROVED", "ADJUSTED"] : [this.status],
          invoiceId: this.searchTerm,
          type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
          startDate: start,
          endDate: end,
          offset: 0,
          limit: this.limit,
          isAllPaymentProvider: this.payProvider,
          provider: this.payProvider
        };
        if (this.displayView === "disbursement view") {
          const dData = (await reconciliationApi.post("/getDisbursementView", encodeWithParam({ param })))?.data;
          this.disbursementEntries = dData?.result.sort((a,b)=>{
            if(a.disbursement_date.substr(0,10) === b.disbursement_date.substr(0,10)){
              return a.invoice_report_type < b.invoice_report_type ? -1 : 1;
            }else{
              return a.disbursement_date.substr(0,10) < b.disbursement_date.substr(0,10) ? 1 : -1;
            }
          });
          [this.disbursementTotal, this.settlementTotal] = [dData.disburseTotal || 0, dData.settleTotal || 0];
          this.disbAggStartDt = this.disbursementEntries[this.disbursementEntries.length - 1]?.disbursement_date;
          this.disbAggEndDt = this.disbursementEntries[0]?.disbursement_date;
        } if (this.displayView === "settlement view") {
          const sData = (await reconciliationApi.post("/getSettlementView", encodeWithParam({ param })))?.data;
          this.settlementEntries = sData?.result.sort((a,b)=>{
            if(a.invoice_date_pst === b.invoice_date_pst){
              return a.pay_type<b.pay_type?-1:1;
            }else{
              return a.invoice_date_pst<b.invoice_date_pst?1:-1;
            }
          });
          [this.settlementTotal, this.disbursementTotal] = [sData?.settleTotal || 0, sData?.disburseTotal || 0];
          this.settlementEntries?.forEach(el => { el.settled_amount = this.formatMoney(el.settled_amount) });
          this.invoiceIds = this.settlementEntries.map((el) => el.settlement_invoice);
          this.totalPaginationCount = Number(sData?.rowCount);
        }
        if (this.displayView === "hold") {
          param.limit = this.perPage;
          param.offset = this.offset;
          const hData = (await reconciliationApi.post("/getHoldView", encodeWithParam({ param })))?.data;
          this.holdEntries = hData?.result.map((item)=>{
            if(!item.last_failed_provider) item.last_failed_provider = 'No Record';
            return item;
          })
          this.totalPaginationCount = Number(hData?.rowCount);
          this.disbursementTotal = hData.hold_total;
          this.settlementTotal = Number(hData?.rowCount);
        }

        this.loader = false;
      },
      providerFilter(type, value) {
        if (type === "provider") this.provider = value[0]?.toUpperCase();
        if (this.chevronStatus && this.displayView === "disbursement view") this.toggleDisplay(true);
        if (this.displayView === "settlement view") this.fetchFilteredView(type, value)
      },
      async getAggEntries() {
        micromodal.show("recon-loader-modal");
        if (this.displayView === "disbursement view") {
          this.globalExpansion = true;
          const { data } = await reconciliationApi.post("/getDisburseAggEntries", encodeWithParam({ startDate: `${this.disbAggStartDt?.substr(0,10)} 00:00:00.000`, endDate: `${this.disbAggEndDt?.substr(0,10)} 23:59:59.999`, provider : this.payProvider }));
          const obj = {};
          data.result?.forEach((el) => {
            const key = `${el?.disbursement_date.substr(0,10)}_${el?.invoice_report_type}`;
            if (obj[key] != undefined && el?.invoice_date != null) obj[key].push(el);
            else if (el?.invoice_date != null) obj[key] = new Array(el);
          });
          this.disburseCollapseData = obj;
        } else if (this.displayView === "settlement view") {
            const { data } = await reconciliationApi.post("/getSettlementAggEntries", encodeWithParam({ invoiceIds : this.invoiceIds }));
            const obj = {};
            data.result?.forEach((el) => {
              const key = el?.settlement_invoice;
              if (obj[key] != undefined && el?.disbursement_date != null) obj[key].push(el);
              else if (el?.disbursement_date != null) obj[key] = new Array(el);
            });
            this.settlementCollpaseData = obj;
          }
        micromodal.close("recon-loader-modal");
      },
      searchIcon() {
        return this.searchTerm !== "";
      },
      resetSearch() {
        this.searchTerm = "";
        this.fetchFilteredView('view', ['settlement view']);
      },
      isValue(value) {
        return value != "" && value != undefined && value != null;
      },
      formatDate(date) {
        if (date) {
          const [year, month, day] = date.substr(0, 10).split("-");
          return [month, day, year].join("/");
        }
        return date;
      },
      getGlobalTotalTextLabels(){
        return {
          disburse: this.displayView==='disbursement view' ? 'Current Disbursed Total' : this.displayView==='hold' ? 'Total Hold Amount' : 'Disbursed Total',
          settled: this.displayView==='hold' ? 'Number of Drivers' : 'Settled Total'
        }
      },

      getPreSelectValue() {
        if(this.displayView === "disbursement view"){
          return "PAYFARE"
        }else {
          return "PAYFARE"
        }
      },
      formatMoney(amount, formatNotRequired = false) {
        amount == undefined || isNaN(amount) ? (amount = 0) : amount;
        if(formatNotRequired) return amount;
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount)
      },
      totalView(){
        if(this.displayView === 'settlement view' && this.disbursementTotal === this.settlementTotal ){
        return 'green'
      } else if (this.displayView === 'settlement view'){
        return 'red-one'
       } else if (this.displayView === 'disbursement view' && this.disbursementTotal === this.settlementTotal){
        return 'green'
       }
       return 'red-one'
      },
      openReconModal(reconModal, item) {
        this.rowItem = item;
        this.reconModalType = reconModal;
        micromodal.show("reconTxnModal");
        document.body.classList.add("no-scroll");
      },
      openReconStatusModal(reconModal, item) {
        this.statusItem = item;
        this.reconStatusModalType = reconModal;
        micromodal.show("reconStatusModal");
        document.body.classList.add("no-scroll");
      },
      async downloadReconCsv() {
        this.downloadLoader = true;
        const { start, end } = this.getDateRange();
        const param = {
          provider: "PAYFARE",
          status: !this.isValue(this.status) || this.status == "ALL" ? ["PENDING", "RECONCILED", "APPROVED", "ADJUSTED"] : [this.status],
          startDate: start,
          endDate: end,
          type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
          offset: 0,
          limit: this.limit
        };
        const disburseHeaders = [
          "Disbursement date",
          "Type",
          "Disbursement volume",
          "Disbursement amount",
          "Settle volume",
          "Settle amount",
          "Adjusted amount",
          "Balance",
          "Status"
        ];
        const settleHeaders = [
          "Invoice date",
          "Invoice number",
          "Invoice volume",
          "Invoice amount",
          "Disbursement date",
          "Disbursement volume",
          "Disbursement amount",
          "Status",
          "Invoice type"
        ];
        let result;
        const columns = this.displayView === "disbursement view" 
          ? disburseHeaders : this.displayView === "settlement view" 
          ? settleHeaders : [];
        const filename = this.displayView === "disbursement view" 
          ? "Disbursement_summary" : this.displayView === "settlement view" 
          ? "Settlement_summary" : "";
        if (this.displayView === "disbursement view") {
          result = (await reconciliationApi.post("/getDisbursementSummaryCsv", encodeWithParam({ param })))?.data?.result;
        } else if (this.displayView === "settlement view") {
            result = (await reconciliationApi.post("/getSettlementCsv", encodeWithParam({ param })))?.data?.result;
        }else if (this.displayView === "hold") {
              if(!this.dateRange[0] || !this.dateRange[1]){
                param.startDate = null;
                param.endDate = null;
              }
              result = (await reconciliationApi.post("/getHoldCsv", encodeWithParam({ param })))?.data?.result;
          } else {
          this.toastMessage = "Select any one view to download"
          this.displayToast = true;
          this.toastSeverity = "error"
        }
        if (result?.length > 0) {
          const csv = this.$papa.unparse(result, {
            delimiter: ",",
            columns: Array.from(columns),
          });
          this.$papa.download(csv, filename);
        } else if (this.displayView != "") {
            this.toastMessage = "No data available"
            this.displayToast = true;
            this.toastSeverity = "info"
        }
        this.downloadLoader = false;
      },
      closeToast() {
        this.displayToast = false;
      },
      getInvoiceType(type) {
        return type === "earnings" ? "Earnings" : "Tips";
      },
      async downloadDisbursedCSV(date, item) {
        const { data } = await reconciliationApi.post("/getDisbursementCSV", encodeWithParam({ startDate: `${date.substr(0,10)} 00:00:00.000`, endDate: `${date.substr(0,10)} 23:59:59.999`, type: item.invoice_report_type, provider: 'PAYFARE' }));
        if (data.length > 0) {
          downloadFile(data,`${this.formatDate(date).replace(/\//gi, "-")}_${item.invoice_report_type}_disbursed.csv`)
        }
      },
      async downloadInvoiceCSV(item) {
        const payload = encodeWithParam({ invoiceId: item.settlement_invoice })
        const { data } = await reconciliationApi.post("/getInvoiceCSV", payload);
        const type = item.pay_type ? item.pay_type : item.invoice_report_type
        if (data.length > 0) {
          downloadFile(data,`${item.settlement_invoice}_${type}.csv`);
        }
      },
      toggleDisplay(value) {
          if (this.displayView === "disbursement view") {
            this.disburseTableId = value;
          }
          if (this.displayView === "settlement view") {
            this.settlementTableId = value;
          }
          if (this.displayView === "hold") {
            this.holdTableId = value;
          }
  
          this.chevronStatus = value;
          if (value) {
            this.getAggEntries();
          } else {
            this.disburseCollapseData = {}
            this.settlementCollpaseData = {}
          }
        },
      getDateRange() {
        let start = DateTime.now().setZone("America/Los_Angeles").startOf("day").minus({ days: 30 }).toString().replace("T", " ").substr(0,23);
        let end = DateTime.now().setZone("America/Los_Angeles").endOf("day").plus({ days: 9 }).toString().replace("T", " ").substr(0,23);
        if (this.dateRange != null && this.dateRange[1] != null) {
          [start, end] = [`${new Date(this.dateRange[0]).toISOString()?.substr(0,10)} 00:00:00.000`, `${new Date(this.dateRange[1]).toISOString()?.substr(0,10)} 23:59:59.999`];
        }
        return { start, end }
      },
      getHeight(val) {
        return val.payment_provider != "ACH" ? "collapse-height-one" : "collapse-height-ach"
      },
      checkFutureDate(item) {
        const disbDate = new Date(item.disbursement_date.substr(0,10));
        const today = new Date(DateTime.now().setZone("America/Los_Angeles").toFormat("yyyy-MM-dd"));
        return today > disbDate && item.balance > 0 ? true : false
      },
      closeReconStatusModel(rowData){
        this.statusItem = {};
        if(rowData){
          this.disbursementEntries?.forEach((item) => {
            if(item.id === rowData.id){
              item.remarks = rowData.remark;
              item.status = rowData.status;
            }
          });
        } 
      },
    },
    //adding comment
  };
</script>

<style scoped>
  .inherit-flex {
    flex: inherit !important;
  }

  .margin-disbursements {
    margin-left: calc(50% + 2px);
  }

  .margin-settlement-invoice {
    margin-left: calc(20%);
  }

  .accordian-tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .background-white {
    background-color: white;
  }

  .tr-height {
    height: 6px;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .collapse-height-ach {
    height: 64px;
  }

  .collapse-height-one {
    height: 32px;
  }

  tbody tr:nth-child(odd) {
    background: none;
  }

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-small {
    margin-top: 4px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
  }

  .reconciled {
    background-color: #2a8703;
  }

  .pending {
    background-color: #ffab00;
  }

  .approved {
    background-color: #2c92f3;
  }

  .failed {
    background-color: red;
  }

  .settlement-tr {
    width: 100%;
    height: 32px;
  }

  .none a {
    text-decoration: none;
  }

  .invoice-tooltip-align {
    margin-top: 16px;
    margin-left: calc(-15% - 5px) !important;
  }

  .summary-tooltip-align {
    margin-left: -150px;
    margin-top: 40px;
  }

  .disburse-tooltip-align {
    margin-left: -72px;
    margin-top: 16px
  }

  .balance-tooltip-align {
    margin-left: -54px;
    margin-top: 14px;
  }

  .p-right-0 {
    padding-right: 0;
  }
  .totals-text {
    font-size: 13px;
    font-weight: 600;
    margin: 10px;
  }
  .totals-num {
    font-size: 22px;
    font-weight: 600;
    color: red;
    text-align: center;
    margin-top: 4px;
  }
  input[type='search'] {
    width: 272px;
  }
  .green{
    color: green;
  }
  .black{
    color: black;
  }
  .red-one{
    color: red;
  }
  .dot-col-align{
    display: inline-flex;
    align-items: center;
  }
  .dot-col-align .dot{
    margin-right: 3px;
  }
</style>
import Base64 from "base-64";
  export const encodeWithParam=(data)=> {
    const toStr= JSON.stringify(data)
    return {"param":Base64.encode(toStr)}
   };
   export const encodeWithParamutf = (data) => {
    const toStr = JSON.stringify(data);
    const encodedStr = Buffer.from(toStr, 'utf-8').toString('base64');
    return { "param": encodedStr };
  };

   export const encode=(data)=> {
    const toStr= JSON.stringify(data)
    return Base64.encode(toStr)
   };

   export const decode=(data)=> {
    const str=Base64.decode(data)
    return JSON.parse(str)
   };


<template>
  <div>
    <footer class="container site-footer" :style="{ borderColor: $props.color }">
      <div class="content text-center">
        <a href="https://drive4spark.walmart.com/ca/privacy-notice" target="_blank"><u>Spark Driver<sup>&#8482;</sup> Privacy Statement</u></a>
      </div>
    </footer>
    <walmart-privacy-policy @accept="closeModal" />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import WalmartPrivacyPolicy from "@/components/modals/WalmartPrivacyPolicy.vue";
import micromodal from "micromodal";

export default {
  name: "Footer",
  components: { WalmartPrivacyPolicy },
  props: {
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
  watch: {
    $route(newVal, oldVal) {
      if (oldVal.fullPath !== newVal.fullPath) {
        this.closeModal()
      }
    }
  },
  data() {
    return {
      currentYear: DateTime.now().year,
    }
  },
  methods: {
    openModal(modal) {
      micromodal.show(modal);
    },
    closeModal() {
      micromodal.close("walmart-privacy-policy");
    }
  }
};
</script>

<template>
  <base-modal id="modal-submit-settlements">
    <div class="module">
      <h4>Submit Partner Settlements</h4>
      <p>This settlement will be sent to DDI for review and invoicing. You will not be able to edit these settlements after submitting.</p>
      <table class="no-zebra">
        <thead>
          <tr>
            <th>Complete Submissions</th>
            <th>Not Submitting</th>
            <th>Total Payout</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $props.settlement.completeEntries.length }}</td>
            <td>{{ $props.settlement.incompleteEntries.length }}</td>
            <td>{{ format(totalPayout) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="button-group close-top">
      <a class="button" data-micromodal-close="data-micromodal-close" @click="confirmSubmission">Confirm Submission</a>
      <a class="button secondary" data-micromodal-close="data-micromodal-close">Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
export default {
  components: { BaseModal },
  props: {
    settlement: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    totalPayout() {
      return this.$props.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.netAmount, 0);
    },
  },
  methods: {
    confirmSubmission() {
      this.$emit("confirm", { ...this.$props.settlement });
    },
    format(money) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(money);
    },
  },
};
</script>

<template>
  <add-edit-page>
    <template #header="validation">
      <sticky-header title="" :color="color">
        <div class="sticky-flex-container">
          <div class="sticky-header-main">
            <h2>Partner Settlements</h2>
            <h4>{{ location.name }}</h4>
            <div class="button-group">
              <a class="button" data-micromodal-trigger="modal-submit-settlements" :disabled="validation.pristine || validation.invalid" @click.prevent>Submit Settlements</a>
              <a class="button secondary" @click="save('IN_PROGRESS')">Save for Later</a>
              <a class="button secondary" data-micromodal-trigger="modal-delete-settlements" @click.prevent>Delete Settlements</a>
            </div>
          </div>
          <div class="sticky-header-aside">
            <div class="stats">
              <div>
                <h3>Entries Complete</h3>
                <div class="stat">{{ settlement.completeEntries.length }}</div>
              </div>
              <div>
                <h3>Total Payout</h3>
                <div class="stat right">{{ format(totalPayout) }}</div>
              </div>
            </div>
          </div>
        </div>
      </sticky-header>
    </template>
    <div class="module-xlarge">
      <div class="has-tooltip-container">
        <h3>Incomplete ({{ settlement.incompleteEntries.length }} Partners)</h3>
        <tooltip id="incomplete-description" title="Description" :description="`Any payments or advances that driver has already received from ${location.name} during the week.`" hover />
      </div>
      <data-table :headers="incompleteHeaders" :entries="settlement.incompleteEntries">
        <template #name="{ item }"> {{ item.firstName }} {{ item.lastName }}</template>
        <template #lastPay="{ item }">{{ lastPayDay(item) }}</template>
        <template #actions="{ item }"><a @click="openFlyout(item)" @click.prevent>Enter Settlement</a></template>
      </data-table>
    </div>
    <div class="module-xlarge">
      <div class="has-tooltip-container">
        <h3>Complete ({{ settlement.completeEntries.length }} Partners)</h3>
        <tooltip id="complete-description" title="Description" :description="`Any payments or advances that driver has already received from ${location.name} during the week.`" hover />
      </div>
      <data-table :headers="completeHeaders" :entries="settlement.completeEntries">
        <template #notes="{ item }">
          <tooltip :id="`note-${item.id}`" title="Notes" :description="item.notes" icon-class="icon-file" hover />
        </template>
        <template #actions="{ item }"><a @click="openFlyout(item.id)" @click.prevent>Edit</a></template>
      </data-table>
    </div>
    <div class="module-xlarge">
      <table>
        <thead>
          <tr>
            <th class="cell25"></th>
            <th class="cell15">Total Gross</th>
            <th class="cell15">Total Credits</th>
            <th class="cell10">Total Debits</th>
            <th class="cell15">Other Withholdings</th>
            <th class="cell15">Total Payout</th>
            <th class="cell5"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <h3 class="no-margin">Totals</h3>
            </td>
            <td>{{ format(totalGross) }}</td>
            <td>{{ format(totalCredits) }}</td>
            <td>{{ format(totalDebits) }}</td>
            <td>{{ format(totalWithholdings) }}</td>
            <td>{{ format(totalPayout) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <submit-settlements-modal :settlement="settlement" @confirm="save('SUBMITTED')" />
    <delete-settlements-modal :location-id="location.id" />
    <payout-flyout :selected-partner="selectedPartner" :client-id="location.clientID" :location-id="location.id" @onSave="savePayout" />
  </add-edit-page>
</template>
<script>
import { AddEditPage, StickyHeader, DataTable, Tooltip } from "@/components";
import TableHeader from "@/components/table/TableHeader";
import { GET_LOCATION_SETTLEMENTS } from "@/modules/clientPortal/locations/finances/queries";
import { mapActions, mapGetters } from "vuex";
import SubmitSettlementsModal from "@/modules/clientPortal/locations/finances/SubmitSettlementsModal";
import DeleteSettlementsModal from "@/modules/clientPortal/locations/finances/DeleteSettlementsModal";
import PayoutFlyout from "@/modules/clientPortal/locations/finances/PayoutFlyout";
import micromodal from "micromodal";
import { SAVE_PAYOUTS, SAVE_SETTLEMENT } from "@/modules/clientPortal/locations/finances/mutations";
export default {
  components: { SubmitSettlementsModal, DeleteSettlementsModal, PayoutFlyout, DataTable, Tooltip, StickyHeader, AddEditPage },
  data: () => ({
    location: {},
    settlement: {
      completeEntries: [],
      incompleteEntries: [],
      totalPayout: 0,
    },
    selectedPartner: {},
  }),
  computed: {
    ...mapGetters(["getUsername"]),
    color() {
      return this.location.color ? this.location.color : this.location.client && this.location.client.color ? this.location.client.color : null;
    },
    incompleteHeaders() {
      return [
        new TableHeader({ label: "Partner Name", headerClass: "cell25", name: "name", cellContents: "name" }),
        new TableHeader({ label: "Last Pay", name: "lastPay", cellContents: "lastPay" }),
        new TableHeader({ cellContents: "actions", name: "actions", cellClass: "table-actions" }),
      ];
    },
    completeHeaders() {
      return [
        new TableHeader({ label: "Partner Name", headerClass: "cell20", name: "name", cellContents: "name" }),
        new TableHeader({ label: "Notes", headerClass: "cell5", name: "notes", cellContents: "notes" }),
        new TableHeader({ label: "Gross Pay", headerClass: "cell15", cellContents: (item) => this.format(item.grossAmount) }),
        new TableHeader({ label: "Additional Credits", headerClass: "cell15", cellContents: (item) => this.format(item.credits) }),
        new TableHeader({ label: "Debits", headerClass: "cell10", cellContents: (item) => this.format(item.debits) }),
        new TableHeader({ label: "Other Withholdings", headerClass: "cell15", cellContents: (item) => this.format(item.withholdings) }),
        new TableHeader({ label: "Total to Partner", headerClass: "cell15", cellContents: (item) => this.format(item.netAmount) }),
        new TableHeader({ cellContents: "actions", headerClass: "cell5", name: "actions", cellClass: "table-actions" }),
      ];
    },
    totalGross() {
      return this.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.grossAmount, 0);
    },
    totalCredits() {
      return this.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.credits, 0);
    },
    totalDebits() {
      return this.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.debits, 0);
    },
    totalWithholdings() {
      return this.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.withholdings, 0);
    },
    totalPayout() {
      return this.settlement.completeEntries.reduce((total, completeEntry) => total + completeEntry.netAmount, 0);
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    ...mapActions(["showFlyout"]),
    openFlyout(partner) {
      this.selectedPartner = partner;
      this.showFlyout("enter-settlement");
    },
    save(status) {
      const settlementToSave = {
        clientID: this.location.clientID,
        locationID: this.location.id,
        startDate: new Date(),
        endDate: new Date(),
        status,
        submissionDate: status === "SUBMITTED" ? new Date() : null,
        updatedBy: this.getUsername,
      };
      this.$apollo
        .mutate({
          mutation: SAVE_SETTLEMENT,
          variables: {
            settlement: settlementToSave,
          },
        })
        .then(({ data }) => {
          const settlementID = data.insert_settlements_one.id;
          const partnerPayouts = this.settlement.completeEntries.map((partnerPayout) => ({
            settlementID,
            partnerID: partnerPayout.partnerID,
            clientID: this.location.clientID,
            locationID: this.location.id,
            startDate: new Date(),
            endDate: new Date(),
            status,
            credits: partnerPayout.credits,
            debits: partnerPayout.debits,
            withholdings: partnerPayout.withholdings,
            grossAmount: partnerPayout.grossAmount,
            netAmount: partnerPayout.netAmount,
            notes: partnerPayout.notes,
          }));
          return this.$apollo.mutate({
            mutation: SAVE_PAYOUTS,
            variables: { partnerPayouts },
          });
        })
        .finally(() => this.$router.push({ name: "cp-location", hash: "#finances", params: { locationID: this.location.id } }));
    },
    savePayout(payload) {
      this.settlement.incompleteEntries.shift();
      this.settlement.completeEntries.push(payload.payout);
      if (payload.nextPartner) {
        if (this.settlement.incompleteEntries.length > 0) {
          this.selectedPartner = this.settlement.incompleteEntries[0];
        } else {
          this.showFlyout("");
        }
      }
    },
    lastPayDay(partner) {
      if (partner && partner.payouts && partner.payouts.length) {
        return partner.payouts[0].endDate;
      }
      return "";
    },
    setIncompleteSettlements(location) {
      const incompleteSettlements = [];
      location.partners.flatMap((locationPartner) => locationPartner.partner).forEach((partner) => incompleteSettlements.push(partner));
      // TODO make sure the last payout date is coming through
      // TODO remove partners from this list if they have a payout that has been created with the same payout as the settlement payout
      this.settlement.incompleteEntries = incompleteSettlements;
    },
    format(money) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(money);
    },
  },
  apollo: {
    location: {
      // TODO query settlement by ID for edit/continue
      query: GET_LOCATION_SETTLEMENTS,
      variables() {
        return { locationID: this.$route.params.locationID };
      },
      result() {
        if (this.$parent && this.$parent.$data) {
          this.$parent.$data.color = this.color;
        }
        this.setIncompleteSettlements(this.location);
        // TODO come up with method to set complete settlements when looking at existing settlement
      },
      update: (data) => data.clientLocations_by_pk,
    },
  },
};
</script>

export const ONBOARDING_READ_ONLY = "ONBOARDING_READ_ONLY";
export const ONBOARDING_ANALYST = "ONBOARDING_ANALYST";
export const ONBOARDING_ACCOUNT_MANAGER = "ONBOARDING_ACCOUNT_MANAGER";
export const DAS_TIER_1 = "DAS_TIER_1";
export const DAS_TIER_2 = "DAS_TIER_2";
export const DAS_TECHPOD = "DAS_TECHPOD";
export const DAS_MANAGER = "DAS_MANAGER";
export const LOCATION_MANAGER = "LOCATION_MANAGER";
export const ACCOUNTING_SPECIALIST = "ACCOUNTING_SPECIALIST";
export const ACCOUNTING_ANALYST = "ACCOUNTING_ANALYST";
export const ACCOUNTING_SUPERVISOR = "ACCOUNTING_SUPERVISOR";
export const EXECUTIVE = "EXECUTIVE";
export const SUPERUSER = "SUPERUSER";
export const ADJUDICATOR = "ADJUDICATOR";
export const QA_ADJUDICATION = "QA_ADJUDICATION";
export const QA_REVIEWER = "QA_REVIEWER";
export const REVIEWER_INSURANCE = "REVIEWER_INSURANCE";
export const TS_SUPPORT = "TS_SUPPORT";
export const TS_ANALYST = "TS_ANALYST";
export const TS_MANAGER = "TS_MANAGER";
export const SCREENING_MANAGER = "SCREENING_MANAGER";

// enable the required roles only
const ROLES = [
  ONBOARDING_ACCOUNT_MANAGER,
  ACCOUNTING_SUPERVISOR,
  TS_MANAGER,
  SUPERUSER,
  ONBOARDING_ANALYST,
  // ONBOARDING_READ_ONLY,
  // DAS_TIER_1,
  // DAS_TIER_2,
  // DAS_TECHPOD,
  // DAS_MANAGER,
  LOCATION_MANAGER,
  // ACCOUNTING_SPECIALIST,
  // ACCOUNTING_ANALYST,
  // EXECUTIVE,
  // ADJUDICATOR,
  // QA_ADJUDICATION,
  // QA_REVIEWER,
  // REVIEWER_INSURANCE,
  // TS_SUPPORT,
  // TS_ANALYST,
  // SCREENING_MANAGER
];

const ROLES_MAP = {
  [ONBOARDING_READ_ONLY]: "Onboarding Read Only",
  [ONBOARDING_ANALYST]: "Onboarding Analyst",
  [ONBOARDING_ACCOUNT_MANAGER]: "Onboarding Account Manager",
  [DAS_TIER_1]: "DAS Tier 1",
  [DAS_TIER_2]: "DAS Tier 2",
  [DAS_TECHPOD]: "DAS Techpod",
  [DAS_MANAGER]: "DAS Manager",
  [LOCATION_MANAGER]: "Location Manager",
  [ACCOUNTING_SPECIALIST]: "Accounting Specialist",
  [ACCOUNTING_ANALYST]: "Accounting Analyst",
  [ACCOUNTING_SUPERVISOR]: "Accounting Supervisor",
  [EXECUTIVE]: "Executive",
  [SUPERUSER]: "Superuser",
  [ADJUDICATOR]: "Adjudicator",
  [QA_ADJUDICATION]: "QA Adjudication",
  [QA_REVIEWER]: "QA Reviewer",
  [REVIEWER_INSURANCE]: "Reviewer Insurance",
  [TS_SUPPORT]: "T&S Support",
  [TS_ANALYST]: "T&S Analyst",
  [TS_MANAGER]: "TS Manager",
  [SCREENING_MANAGER]: "Screening Manager"
};

export const getDisplayValue = (role) => ROLES_MAP[role];
export const getRolesWithCodes = () => getDDIRoles().map((role) => ({ code: role, label: getDisplayValue(role) }));
export const getDDIRoles = () => ROLES.map((role) => role).sort((first, second) => first.localeCompare(second));
export const getDDIRoleFilters = () => getDDIRoles().map((role, index) => ({ id: index, label: getDisplayValue(role), name: role, checked: false }));
export const isAdminRole = (employee) => employee && getDDIRoles().includes(employee.role);

<template>
  <header class="container site-masthead light" :style="{ borderColor: $props.color }">
    <div class="content">
      <router-link class="main-logo" :to="{ name: 'cp-locations' }"><img :src="$props.logo" class="client-header-logo" alt="client Logo" /></router-link>
      <nav class="main-nav">
        <ul class="list-unstyled">
          <li><router-link :to="{ name: 'cp-locations' }">Zones</router-link></li>
          <!--          <li><router-link :to="{ name: 'cp-account' }">Account</router-link></li>-->
        </ul>
      </nav>
      <div class="user-control" :class="{ 'show-nav': show }">
        <p v-click-outside="hide" @click="show = !show">{{ $store.getters.getFullName }}<i class="icon-user"></i></p>
        <nav class="user-nav" @click="show = false">
          <ul class="list-unstyled">
            <li><router-link to="/settings">Settings</router-link></li>
            <li><router-link to="/profile">Profile</router-link></li>
            <li class="sign-out">
              <a @click="logout">Sign Out</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "ClientHeader",
  directives: { ClickOutside },
  props: {
    name: {
      type: String,
      default: "John Doe",
    },
    logo: {
      type: String,
      required: false,
      default: "@/assets/images/logos/delivery-logo.svg",
    },
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => {
    return {
      drawer: false,
      show: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("userLogout");
      await this.$store.dispatch("clearOnboarding");
      await this.$router.push({ name: "login" });
    },
    hide() {
      this.show = false;
    },
  },
};
</script>

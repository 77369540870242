<template>
  <div class="input-text-after">
    <div class="input-container">
      <div class="field">
        <input type="number" @input="update" />
      </div>
    </div>
    <div class="text">{{ transactionTypeDisplay($props.transactionModifier) }}</div>
  </div>
</template>
<script>
import { TransactionModifierFactory } from "@/modules/shared/finances/services";

export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    transactionModifier: {
      type: Object,
      required: true,
    },
  },
  methods: {
    transactionTypeDisplay(modifier) {
      return TransactionModifierFactory.transactionTypeDisplay(modifier);
    },
    update(event) {
      this.$emit("input", event.target.value);
      this.$emit("change", event.target.value);
    },
  },
};
</script>

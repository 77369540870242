import PortalPage from "@/modules/clientPortal/PortalPage";
import { ClientAccountRoutes } from "@/modules/clientPortal/account";
import { ClientPortalLocationRoutes } from "@/modules/clientPortal/locations";
//import clientGuard from "@/security/clientGuard";

export default [
  {
    path: "/locations",
    component: PortalPage,
  //  beforeEnter: clientGuard,
    children: [...ClientPortalLocationRoutes, ...ClientAccountRoutes],
  },
];

<template>
  <validation-observer v-slot="validation" ref="observer">
    <div id="depositSummaryLineItem" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <h4>Add Deposit Summary Line Item</h4>
              <div class="radio-btns">
                <div class="radio-custom">
                  <input id="recruiting-on" :value="credit" type="radio" name="recruiting" @change="credit('CREDITS', $event.target.value)" />
                  <label for="recruiting-on"><span class="label-container">Credit</span></label>
                </div>
                <div class="radio-custom">
                  <input id="recruiting-off" :value="debit" type="radio" name="recruiting" @change="debit('DEBITS', $event.target.value)" />
                  <label for="recruiting-off"><span class="label-container">Debit</span></label>
                </div>
                <div class="radio-custom">
                  <input id="recruiting-1" :value="withholdings" type="radio" name="recruiting" @change="withholdings('WITHHOLDING', $event.target.value)" />
                  <label for="recruiting-1"><span class="label-container">Other Withholdings</span></label>
                </div>

                <form-input v-model="lineItemName" name="Line Item Name" label="Line Item Name" placeholder="Line Item Name" required @change="lineItem($event.target.value)" />
                <form-input v-model="lineItemAmount" name="Amount" label="Amount" type="number" placeholder="$ Amount" required @change="amountVal($event.target.value)" />
              </div>
            </div>
            <div class="button-group close-top">
              <button class="button" :disabled="validation.pristine || validation.invalid" @click="createDepositLineItem">Create</button>
              <a class="button secondary" data-micromodal-close @click="reset">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
<script>
// import { DEPOSIT_SUMMARY_ADD_LINEITEM, SET_DISTRIBUTION_TOTAL } from "@/modules/admin/accounting/invoicing/mutations";
// import { GET_DISTRIBUTION_TOTAL } from "@/modules/admin/accounting/invoicing/queries";
import micromodal from "micromodal";
import FormInput from "@/components/forms/fields/FormInput";
import { mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
// import { restApi } from "../../../../http/http.config";
// import { encodeWithParam } from "../../../../util/Base64Encoding";
export default {
  name: "DepositLineItem",
  components: { FormInput, ValidationObserver },
  props: {
    details: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      distributionTotal: 0,
      lineItemName: "",
      lineItemAmount: "",
      selectedRadio: "",
      addLineItem: {},
    };
  },
  methods: {
    ...mapActions(["showFlyout"]),
    credit(value) {
      this.selectedRadio = value;
    },
    debit(value) {
      this.selectedRadio = value;
    },
    withholdings(value) {
      this.selectedRadio = value;
    },
    lineItem(value) {
      this.lineItemName = value;
    },
    amountVal(value) {
      this.lineItemAmount = value;
    },

    createUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
          return v.toString(16);
      });
    },
    createDepositLineItem() {
      let amount = parseFloat(this.lineItemAmount);
      let customObj = {};
      customObj = {
        id: this.createUUID(),
        amount: Number(amount.toFixed(2)),
        name: this.lineItemName,
        editable: true,
      };
      let items = this.$props.details.check[this.selectedRadio].items;
      if (items.length > 0) {
        items.push(customObj);
        let total = 0;
        total = Number(this.sum(items));
        this.$props.details.check[this.selectedRadio].amount = total;
        this.netCalc(items[items.length - 1]);
      } else {
        let total = parseFloat(this.lineItemAmount).toFixed(2);
        this.$props.details.check[this.selectedRadio].amount = Number(total);
        this.netCalc(this.$props.details.check[this.selectedRadio]);
        items.push(customObj);
      }
        this.addLineItem = this.$props.details.check;
        this.$emit("updateDistribution", this.$props.details.distributionTotal);
        this.$emit("save", this.addLineItem);
        this.reset();
    },
    reset() {
      micromodal.close("depositSummaryLineItem", {});
      this.lineItemAmount = "";
      this.lineItemName = "";
      this.$refs.observer.reset();
    },
    sum(lineItems) {
      return lineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },
    netCalc(lineItem) {
      let netAmount = 0;
      let lineamount = 0;
      if (this.selectedRadio == "DEBITS" || this.selectedRadio == "WITHHOLDING") {
        netAmount = this.$props.details.check["NET"].amount.toFixed(2);
        lineamount = lineItem.amount;
        this.$props.details.distributionTotal = (parseFloat(this.$props.details.distributionTotal) - parseFloat(lineamount)).toFixed(2);
        this.$props.details.check["NET"].amount = Number((parseFloat(netAmount) - parseFloat(lineamount)).toFixed(2));
      } else {
        netAmount = this.$props.details.check["NET"].amount.toFixed(2);
        lineamount = lineItem.amount;
        this.$props.details.distributionTotal = (parseFloat(this.$props.details.distributionTotal) + parseFloat(lineamount)).toFixed(2);
        this.$props.details.check["NET"].amount = Number((parseFloat(netAmount) + parseFloat(lineamount)).toFixed(2));
      }
      console.log("child",this.$props.details.distributionTotal)
    },
  },
};
</script>

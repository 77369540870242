<template>
  <fragment>
    <h2>Appointments</h2>
    <div class="module-xlarge">
      <h3>Upcoming Interviews This Week (6)</h3>
      <table>
        <thead>
          <tr>
            <th class="cell25">Candidate</th>
            <th class="cell25">Appointment Time</th>
            <th class="cell20">Meeting Type</th>
            <th class="cell20">Result of Interview</th>
            <th class="cell5"></th>
            <th class="cell5"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-0'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-0' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav0'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav0'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-1'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-1' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav1'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav1'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-2'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-2' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav2'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav2'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-3'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-3' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav3'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav3'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-4'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-4' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav4'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav4'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td></td>
            <td>
              <div class="has-tooltip">
                <i class="icon-file" @click="tooltip = 'file-5'"></i>
                <div class="tooltip position-right" :class="{ active: tooltip == 'file-5' }">
                  <p class="tooltip-title">Notes</p>
                  <p>Sed posuere consectetur est at lobortis. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
                </div>
              </div>
            </td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav5'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav5'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="module-xlarge">
      <h3>Past Interviews From This Week (3)</h3>
      <table>
        <thead>
          <tr>
            <th class="cell25">Candidate</th>
            <th class="cell25">Appointment Time</th>
            <th class="cell20">Meeting Type</th>
            <th class="cell25">Result of Interview</th>
            <th class="cell5"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-0'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-0'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-1'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-1'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-2'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-2'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-3'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-3'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-4'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-4'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
          <tr>
            <td><a href="#0" @click.self="flyout = 'individual-info'" @click.prevent>Robert Fox</a></td>
            <td>Wed, Sep 30 at 2:00pm</td>
            <td>In-person</td>
            <td><a href="#0">Input Result </a></td>
            <td>
              <div class="more-menu">
                <i class="icon-more-horizontal" @click.self="popup = 'edit-nav-2-5'"></i>
                <nav class="popup-nav" x-show="popup === 'edit-nav-2-5'" @click="popup = false">
                  <ul class="list-unstyled">
                    <li><a href="">Reschedule</a></li>
                    <li><a href="#0" @click.self="flyout = 'cancel-appointment'" @click.prevent>Cancel</a></li>
                    <li><a href="#0" @click.self="flyout = 'interview-result'" @click.prevent>Input Interview Result</a></li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="module-xlarge">
      <h3>Appointments and Availability</h3>
      <h5>Appointment Settings</h5>
      <table>
        <thead>
          <tr>
            <th class="cell25">Appointment Length</th>
            <th class="cell25">Appointment Length</th>
            <th class="cell20">Group/1-on-1</th>
            <th class="cell25">Meeting Type</th>
            <th class="cell5"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>30 Minutes</td>
            <td>1-on-1</td>
            <td>Virtual</td>
            <td class="table-actions"><a href="#0" @click.self="flyout = 'edit-settings'" @click.prevent>Edit </a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="range-container">
      <div class="date-range">
        <button class="button secondary"><i class="icon-chevron-left"></i></button>
        <div class="range">
          <p>28 Sep - 4 Oct</p>
        </div>
        <button class="button secondary"><i class="icon-chevron-right"></i></button>
      </div>
    </div>
    <!-- For hour slots, use class '.hour-slots' on the table element-->
    <table class="calendar no-zebra">
      <thead>
        <th><span>8am</span></th>
        <th>28 Mon</th>
        <th>29 Tue</th>
        <th>30 Wed</th>
        <th>1 Thu</th>
        <th>2 Fri</th>
        <th>3 Sat</th>
        <th>4 Sun</th>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>9am</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td class="available">Available</td>
          <td></td>
        </tr>
        <tr>
          <td><span>10am</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
          <td class="available">Available</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
          <td class="available">Available</td>
          <td></td>
        </tr>
        <tr>
          <td><span>11am</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
          <td class="available">Available</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
          <td class="available">Available</td>
          <td></td>
        </tr>
        <tr>
          <td><span>12pm</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>1pm</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Brooklyn Simmons</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-2274'"></i>
                  <nav class="popup-nav long position-right" x-show="popup === '-appointment-nav-2274'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td class="available disabled">Available</td>
          <td class="available">Available</td>
          <td class="available">Available</td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Guy Hawkins</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-4725'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-4725'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>2pm</span></td>
          <td class="available disabled">Available</td>
          <td class="available disabled">Available</td>
          <td class="available">Available</td>
          <td class="available">Available</td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Wade Warren</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-9092'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-9092'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="available disabled">Available</td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Leslie Alexander</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-3919'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-3919'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Robert Fox</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-5315'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-5315'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td class="available">Available</td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>3pm</span></td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Cameron Williamson</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-5087'"></i>
                  <nav class="popup-nav long position-right" x-show="popup === '-appointment-nav-5087'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td class="available disabled">Available</td>
          <td>
            <div class="appointment canceled">
              <div class="info">
                <p>Cody Fisher</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-9196'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-9196'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td class="available">Available</td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Eleanor Pena</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-2375'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-2375'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="available disabled">Available</td>
          <td class="available disabled">Available</td>
          <td class="available">Available</td>
          <td>
            <div class="appointment">
              <div class="info">
                <p>Annette Black</p>
              </div>
              <div class="actions">
                <div class="more-menu">
                  <i class="icon-more-horizontal" @click.self="popup = '-appointment-nav-3225'"></i>
                  <nav class="popup-nav long" x-show="popup === '-appointment-nav-3225'" @click="popup = false">
                    <ul class="list-unstyled">
                      <li><a href="#0" @click.self="flyout = 'reschedule'" @click.prevent>Reschedule</a></li>
                      <li><a href="#0" @click.self="flyout = 'cancel'" @click.prevent>Cancel</a></li>
                      <li><a href="#0">Change Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'input-interview-result'" @click.prevent>Input Interview Result</a></li>
                      <li><a href="#0" @click.self="flyout = 'view-attendees'" @click.prevent>View Attendees</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>4pm</span></td>
          <td class="available disabled">Available</td>
          <td class="available disabled">Available</td>
          <td></td>
          <td class="available">Available</td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="available disabled">Available</td>
          <td class="available disabled">Available</td>
          <td></td>
          <td class="available">Available</td>
          <td class="available">Available</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>5pm</span></td>
          <td class="unavailable disabled"></td>
          <td class="unavailable disabled"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>6pm</span></td>
          <td class="unavailable"></td>
          <td class="unavailable"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="flyout interview-result undefined" x-show="flyout === 'interview-result'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Input Interview Result</p>
        </div>
      </div>
      <div class="flyout-content">
        <h4>Cody Fisher</h4>
        <ul class="list-unstyled pair-list">
          <li>
            <p class="light">Appointment Time</p>
            <p>Wed, Sep 30 at 2:30pm</p>
          </li>
        </ul>
        <p>Please enter the result of the interview of the candidate. Some results require an explanation.</p>
        <div class="grid">
          <div class="col-10">
            <div class="field">
              <div class="select-container">
                <select>
                  <option value="">Rejected</option>
                  <option value="">Candidate didn’t show</option>
                  <option value="">Passed - on hold</option>
                  <option value="">Candidate withdrawn</option>
                  <option value="">Candidate cancelled</option>
                  <option value="">Client missed appointment</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label>Explanation of interview result*</label>
          <div class="has-charLimit">
            <textarea placeholder="please explain"></textarea>
            <p class="charlimit">500</p>
          </div>
        </div>
        <div class="button-group close-top"><a class="button" href="client.html">Save</a><a class="button secondary" href="index.html">Cancel</a></div>
      </div>
    </div>
    <!-- Edit Appointment Settings-->
    <div class="flyout edit-settings undefined" x-show="flyout === 'edit-settings'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Edit Appointment Settings</p>
        </div>
      </div>
      <div class="flyout-content">
        <h4>Delivery.com - Bloomington, IN</h4>
        <p>Changes to the appointment settings will take effect on 19 October 2020.</p>
        <div class="multi-input-group">
          <p class="label">Appointment Length</p>
          <div class="inner no-left-space">
            <div class="radio-custom">
              <input id="appointment-length-30" type="radio" name="appointment-length" checked="checked" />
              <label for="appointment-length-30"><span class="label-container">30 Min</span></label>
            </div>
            <div class="radio-custom">
              <input id="appointment-length-60" type="radio" name="appointment-length" />
              <label for="appointment-length-60"><span class="label-container">60 Min</span></label>
            </div>
          </div>
        </div>
        <div class="multi-input-group">
          <p class="label">Appointment Type</p>
          <div class="inner no-left-space">
            <div class="radio-custom">
              <input id="appointment-type-in-person" type="radio" name="appointment-type" checked="checked" />
              <label for="appointment-type-in-person"><span class="label-container">In-Person</span></label>
            </div>
            <div class="radio-custom">
              <input id="appointment-type-virtual" type="radio" name="appointment-type" />
              <label for="appointment-type-virtual"><span class="label-container">Virtual</span></label>
            </div>
            <div class="radio-custom">
              <input id="appointment-type-phone" type="radio" name="appointment-type" />
              <label for="appointment-type-phone"><span class="label-container">Phone</span></label>
            </div>
          </div>
        </div>
        <div class="multi-input-group">
          <p class="label">Group/1-on-1</p>
          <div class="inner no-left-space">
            <div class="radio-custom">
              <input id="appointment-people-1-on-1" type="radio" name="appointment-people" checked="checked" />
              <label for="appointment-people-1-on-1"><span class="label-container">1-on-1</span></label>
            </div>
            <div class="radio-custom">
              <input id="appointment-people-group" type="radio" name="appointment-people" />
              <label for="appointment-people-group"><span class="label-container">Group</span></label>
            </div>
          </div>
        </div>
        <div class="button-group close-top"><a class="button" href="client.html">Save</a><a class="button secondary" href="index.html">Cancel</a></div>
      </div>
    </div>
    <!-- Cancel Appointment-->
    <div class="flyout cancel-appointment undefined" x-show="flyout === 'cancel-appointment'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Cancel Appointment</p>
        </div>
      </div>
      <div class="flyout-content">
        <h4>Cody Fisher</h4>
        <ul class="list-unstyled pair-list">
          <li>
            <p class="light">Appointment Time</p>
            <p>Wed, Sep 30 at 2:30pm</p>
          </li>
        </ul>
        <p>Cancelling this appointment will notify DDI as well as the candidate.</p>
        <p>Please explain why this appointment is being cancelled.</p>
        <div class="field">
          <label>Explanation of interview cancelation*</label>
          <div class="has-charLimit">
            <textarea placeholder="please explain"></textarea>
            <p class="charlimit">500</p>
          </div>
        </div>
        <div class="button-group close-top"><a class="button warning" href="client.html">Cancel Appointment</a><a class="button secondary" href="index.html">Cancel</a></div>
      </div>
    </div>
    <!-- View Attendees-->
    <div class="flyout view-attendees undefined" x-show="flyout === 'view-attendees'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>View Attendees</p>
        </div>
      </div>
      <div class="flyout-content">
        <div class="module-xlarge">
          <h3>Appointment</h3>
          <p>Group Interview</p>
          <p>Wed, Sep 30 at 2:30pm</p>
        </div>
        <div class="module-xlarge">
          <h3>Attendees (10)</h3>
          <p>Albert Flores</p>
          <p>Brooklyn Simmons <em class="light">rescheduled (2)</em></p>
          <p>Cody Fisher</p>
          <p>Courtney Henry</p>
          <p>Esther Howard</p>
          <p>Floyd Miles</p>
          <p>Jacob Jones <em class="light">rescheduled (1)</em></p>
          <p>Jenny Wilson</p>
          <p>Kristin Watson <em class="light">rescheduled (1)</em></p>
          <p>Robert Fox</p>
        </div>
      </div>
    </div>
    <!-- Cancel-->
    <div class="flyout cancel undefined" x-show="flyout === 'cancel'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Cancel</p>
        </div>
      </div>
      <div class="flyout-content">
        <div class="module-xlarge">
          <h5>Select which attendees this event is being cancelled for.</h5>
        </div>
        <div class="module-xlarge">
          <h3>Current Appointment</h3>
          <p>Group Interview</p>
          <p>Wed, Sep 30 at 2:30pm</p>
        </div>
        <div class="module-xlarge">
          <h3>Attendees (10)</h3>
          <div class="multi-input-group">
            <div class="module-large">
              <div class="checkbox-custom">
                <input id="select-all" type="checkbox" />
                <label for="select-all"><span class="label-container">Select All</span> </label>
              </div>
            </div>
            <div class="inner no-left-space">
              <div class="checkbox-custom">
                <input id="attendees-1" type="checkbox" checked="checked" />
                <label for="attendees-1"><span class="label-container">Albert Flores</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-2" type="checkbox" />
                <label for="attendees-2"
                  ><span class="label-container">Brooklyn Simmons <em class="light">rescheduled (2)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-3" type="checkbox" />
                <label for="attendees-3"><span class="label-container">Cody Fisher</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-4" type="checkbox" />
                <label for="attendees-4"><span class="label-container">Courtney Henry</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-5" type="checkbox" />
                <label for="attendees-5"><span class="label-container">Esther Howard</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-6" type="checkbox" />
                <label for="attendees-6"
                  ><span class="label-container">Floyd Miles <em class="light">rescheduled (1)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-7" type="checkbox" />
                <label for="attendees-7"><span class="label-container">Jacob Jones</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-8" type="checkbox" />
                <label for="attendees-8"><span class="label-container">Jenny Wilson</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-9" type="checkbox" />
                <label for="attendees-9"
                  ><span class="label-container">Kristin Watson <em class="light">rescheduled (1)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-10" type="checkbox" />
                <label for="attendees-10"><span class="label-container">Robert Fox</span> </label>
              </div>
            </div>
          </div>
        </div>
        <div class="notification-short warning">
          <div class="icon"><i class="icon-alert-circle"></i></div>
          <div class="message">
            <p>Cancelling appointments! Each candidate selected and DDI will be notified of the cancellation.</p>
          </div>
        </div>
        <div class="button-group close-top"><a class="button warning" href="#0">Cancel Appointments</a><a class="button secondary" href="#0">Cancel</a></div>
      </div>
    </div>
    <!-- Reschedule-->
    <div class="flyout reschedule undefined" x-show="flyout === 'reschedule'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Reschedule</p>
        </div>
      </div>
      <div class="flyout-content">
        <div class="module-xlarge">
          <h5>Select which attendees are to be rescheduled for a diffent appointment time.</h5>
        </div>
        <div class="module-xlarge">
          <h3>Current Appointment</h3>
          <p>Group Interview</p>
          <p>Wed, Sep 30 at 2:30pm</p>
        </div>
        <div class="module-xlarge">
          <h3>Attendees (10)</h3>
          <div class="multi-input-group">
            <div class="module-large">
              <div class="checkbox-custom">
                <input id="select-all" type="checkbox" />
                <label for="select-all"><span class="label-container">Select All</span> </label>
              </div>
            </div>
            <div class="inner no-left-space">
              <div class="checkbox-custom">
                <input id="attendees-reschedule-1" type="checkbox" checked="checked" />
                <label for="attendees-reschedule-1"><span class="label-container">Albert Flores</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-2" type="checkbox" />
                <label for="attendees-reschedule-2"
                  ><span class="label-container">Brooklyn Simmons <em class="light">rescheduled (2)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-3" type="checkbox" />
                <label for="attendees-reschedule-3"><span class="label-container">Cody Fisher</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-4" type="checkbox" />
                <label for="attendees-reschedule-4"><span class="label-container">Courtney Henry</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-5" type="checkbox" />
                <label for="attendees-reschedule-5"><span class="label-container">Esther Howard</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-6" type="checkbox" />
                <label for="attendees-reschedule-6"
                  ><span class="label-container">Floyd Miles <em class="light">rescheduled (1)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-7" type="checkbox" />
                <label for="attendees-reschedule-7"><span class="label-container">Jacob Jones</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-8" type="checkbox" />
                <label for="attendees-reschedule-8"><span class="label-container">Jenny Wilson</span> </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-9" type="checkbox" />
                <label for="attendees-reschedule-9"
                  ><span class="label-container">Kristin Watson <em class="light">rescheduled (1)</em></span>
                </label>
              </div>
              <div class="checkbox-custom">
                <input id="attendees-reschedule-10" type="checkbox" />
                <label for="attendees-reschedule-10"><span class="label-container">Robert Fox</span> </label>
              </div>
            </div>
          </div>
        </div>
        <div class="button-group close-top"><a class="button" href="reschedule-appointment.html">Next: Choose New Time</a><a class="button secondary" href="#0">Cancel</a></div>
      </div>
    </div>
    <!-- Input Interview Result-->
    <div class="flyout input-interview-result undefined" x-show="flyout === 'input-interview-result'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Input Interview Result</p>
        </div>
      </div>
      <div class="flyout-content">
        <div class="module-xlarge">
          <h3>Appointment</h3>
          <p>Group Interview</p>
          <p>Wed, Sep 30 at 2:30pm</p>
        </div>
        <div class="module-xlarge">
          <h3>Enter Results (10)</h3>
          <p>Please enter the result of the interview of the candidate. Some results require an explanation.</p>
          <div class="grid">
            <div class="col-10">
              <h5>Albert Flores</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Brooklyn Simmons<span class="header-small">rescheduled (2)</span></h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Cody Fisher</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Courtney Henry</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>Explanation of interview result*</label>
                <textarea></textarea>
              </div>
            </div>
            <div class="col-10">
              <h5>Esther Howard</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Floyd Miles</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Jacob Jones <span class="header-small">rescheduled (1)</span></h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Jenny Wilson</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Kristin Watson</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-10">
              <h5>Robert Fox</h5>
              <div class="field">
                <div class="select-container">
                  <select>
                    <option value="">Passed</option>
                    <option value="">Passed - On Hold</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-group close-top"><a class="button" href="#0">Save</a><a class="button secondary" href="#0">Cancel</a></div>
      </div>
    </div>
    <!-- Individual Info-->
    <div class="flyout individual-info undefined" x-show="flyout === 'individual-info'">
      <div class="flyout-header">
        <div class="close" @click="flyout = false">
          <a href="#0"><i class="icon-x"></i></a>
        </div>
        <div class="title">
          <p>Cody Fisher</p>
        </div>
      </div>
      <div class="flyout-content">
        <h3>Contact Info</h3>
        <p>Cody Fisher</p>
        <p><a href="#0">ahageman@ddimail.com</a></p>
        <p>(910) 123-4567</p>
        <hr />
        <h3>Appointment Details</h3>
        <div class="module">
          <p>Wednesday, 30 Sep</p>
          <p>2:00 - 2:30pm</p>
          <p>One-on-one</p>
          <p>Virtual</p>
        </div>
        <ul class="list-unstyled pair-list">
          <li>
            <p class="light">Skype Name</p>
            <p>jane.cooper</p>
          </li>
        </ul>
        <hr />
        <ul class="list-unstyled pair-list">
          <li>
            <p class="light">Interview Result</p>
            <p>Passed - on hold</p>
          </li>
          <li>
            <p class="light">Interview Explanation</p>
            <p>Passed - on hold</p>
          </li>
        </ul>
        <hr />
        <h3>Elegibility Questions</h3>
        <ul class="list-unstyled pair-list">
          <li>
            <p class="light">Are you employed?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">Are you a student?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">Do you have a clean driving record?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">Vehicle Year</p>
            <p>2018</p>
          </li>
          <li>
            <p class="light">Vehicle Make</p>
            <p>Mercedes</p>
          </li>
          <li>
            <p class="light">Vehicle Model</p>
            <p>Sprinter</p>
          </li>
          <li>
            <p class="light">Skype Name</p>
            <p>jane.cooper</p>
          </li>
          <li>
            <p class="light">Are you currently a Walmart Associate?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">Do you drive a V8 or larger vehicle?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">Do you have an Android or iOS smartphone?</p>
            <p>Yes</p>
          </li>
          <li>
            <p class="light">How did you hear of us?</p>
            <p>Online</p>
          </li>
          <li>
            <p class="light">Why would you like to work with Walmart?</p>
            <p>This could be a long answer becasue I’m not sure we ever will put a character legnth maximum here.</p>
          </li>
        </ul>
        <hr />
        <div class="field">
          <label>Interview Notes</label>
          <div class="has-charLimit">
            <textarea class="large" placeholder="Type Notes"></textarea>
            <p class="charlimit">500</p>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

export default {
  components: { Fragment },
};
</script>

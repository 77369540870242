import { DateTime } from "luxon"
const partnerMethods = {
    //all details shown in PartnerProfile.vue
    maskDetail(label, value, isMasked) {
        switch(label) {
            case "address1":
                return this.maskType(value, isMasked.address, "*****************")
            case "address2":
                return this.maskType(value, isMasked.address, "*****************")
            case "city":
                return this.maskType(value, isMasked.address, "*********")
            case "addressState":
                return this.maskType(value, isMasked.address, "**")
            case "county":
                return this.maskType(value, isMasked.address, "******")
            case "postalCode":
                return this.maskType(value, isMasked.address, "*****")
            case "country":
                return this.maskType(value, isMasked.address, "**")
            case "dlState":
                return this.maskType(value, isMasked.driversLicense, "**")
            case "dlNumber":
                return this.maskType(value, isMasked.driversLicense, "********")
            case "dl_state":
                return this.maskType(value, isMasked.driversLicense, "****")    
            case "businessName" :
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))
            case "businessAddress1":
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))
            case "businessAddress2":
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))
            case "businessCity" :
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))
            case "businessState" :
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))
            case "businessZipcode" :
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*'))    
            case "businessCountry" :
                return this.maskType(value, isMasked.bAddress, value.replace(/./g, '*')) 
            case "dlExp":
                if (value == '') {
                    return this.maskType(value, isMasked.driversLicense, "** ** ****")
                } else {
                    return this.maskType(DateTime.fromISO(value, { zone: "utc" }).toFormat("dd LLL yyyy"), isMasked.driversLicense, "** ** ****")
                }
            case "dateOfBirth":
                if (value == '') {
                    return this.maskType(value, isMasked.sensitive, "** ** ****")
                } else {
                    return this.maskType(DateTime.fromISO(value, { zone: "utc" }).toFormat("dd LLL yyyy"), isMasked.sensitive, "** *** ****")
                }
            case "ein":
                return this.maskType(value, isMasked.sensitive, "*********")
            case "ssn":
                if (value == '') {
                    return this.maskType(value, isMasked.sensitive, "**-***-****")
                } else {
                    return this.maskType(this.formatSSN(value), isMasked.sensitive, `***-**-${value?.slice(-4)}`)
                }
            default:
              return
          }
    },
    maskType(value, isMasked, maskType) {
        if (isMasked) {
            return maskType
        } else {
            return value
        }
    },
    maskDate(value) {
        return value ? "** *** ****" : null;
    },
    maskField(value) {
        return value ? value.replace(new RegExp(value, "g"), new Array(value.length+1).join("*")) : null;
    },
    formatSSN(ssn) {
        if (ssn && ssn?.split("-").length == 1) {
            return `${ssn?.substring(0,3)}-${ssn?.substring(3,5)}-${ssn?.substring(5,9)}`
        }
        return ssn
    },
    formatMaskedSSN() {
        
    }
  } 

  
  export default partnerMethods;
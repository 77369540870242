<template>
  <flyout name="enter-settlement" title="Enter Partner Settlement" large>
    <h2>{{ selectedPartner.firstName }} {{ selectedPartner.lastName }}</h2>
    <div v-for="section in modifierSections" :key="section.id" class="module-large">
      <h5>{{ section.name }}</h5>
      <data-table :table-id="section.id" :entries="section.entries" :headers="transactionModifierHeaders" hide-headers>
        <template #type="{ item }">
          <count-cell v-if="isCount(item)" :transaction-modifier="item" @change="updateModifier(item, $event)" />
          <credit-debit-cell v-if="isCreditDebit(item)" @change="updateModifier(item, $event)" />
          <yes-no-cell v-if="isYesNo(item)" v-model="item.condition" :transaction-modifier="item" @change="updateModifier(item, $event)" />
        </template>
        <template #description="{ item }">
          <div class="side-icon-container">
            <div class="side-icon">
              <tooltip :id="`description-${item.id}`" title="Description" :description="item.description" hover />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </template>
        <template #amount="{ item }">
          <div class="tag-type green" :class="{ green: isCredit(item), red: !isCredit(item) }">
            <span class="right">{{ computeTotal(item) }}</span>
            {{ isCredit(item) ? "Credit" : "Debit" }}
          </div>
        </template>
      </data-table>
    </div>
    <div class="module grid">
      <div class="col-6">
        <h5>Totals</h5>
        <p><a class="button secondary" href="#0">Update Totals</a></p>
        <table>
          <tfoot>
            <tr>
              <td>Total to Partner</td>
              <td>
                <strong>{{ format(partnerNet) }}</strong>
              </td>
              <td class="table-actions"></td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>Partner Gross</td>
              <td>
                <strong>{{ format(partnerGross) }}</strong>
              </td>
              <td class="table-actions"></td>
            </tr>
            <tr>
              <td>Credits</td>
              <td>
                <strong>{{ format(partnerCredits) }}</strong>
              </td>
              <td class="table-actions"></td>
            </tr>
            <tr>
              <td>Debits</td>
              <td>
                <strong>{{ format(partnerDebits) }}</strong>
              </td>
              <td class="table-actions"></td>
            </tr>
            <tr>
              <td>Other Withholdings</td>
              <td><strong>$0.00</strong></td>
              <td class="table-actions">
                <tooltip id="withholdings-tooltip" title="Description" description="Any payments or advances that driver has already received from delivery.com during the week." hover />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <h5>Notes</h5>
        <div class="field">
          <textarea v-model="notes"></textarea>
        </div>
      </div>
    </div>
    <div class="button-group close-top module-large">
      <a class="button" @click="completeEntry">Complete Entry</a>
      <a class="button secondary" data-micromodal-trigger="modal-incomplete-settlement" @click.prevent>Cancel</a>
    </div>
    <div class="checkbox-custom">
      <input id="next-partner" v-model="nextPartner" type="checkbox" :checked="nextPartner" @click="nextPartner = !nextPartner" />
      <label for="next-partner"><span class="label-container">Open next partner upon Entry Completion</span> </label>
    </div>

    <incomplete-settlement-modal />
  </flyout>
</template>
<script>
import IncompleteSettlementModal from "@/modules/clientPortal/locations/finances/IncompleteSettlementModal";
import Flyout from "@/components/flyouts/Flyout";
import micromodal from "micromodal";
import { mapActions } from "vuex";
import { GET_FINANCE_TEMPLATE } from "@/modules/clientPortal/locations/finances/queries";
import { SettlementItemType, TransactionType } from "@/modules/shared/finances/model";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import Tooltip from "@/components/popups/Tooltip";
import { TransactionModifierFactory } from "@/modules/shared/finances/services";
import CountCell from "@/modules/clientPortal/locations/finances/CountCell";
import CreditDebitCell from "@/modules/clientPortal/locations/finances/CreditDebitCell";
import YesNoCell from "@/modules/clientPortal/locations/finances/YesNoCell";

export default {
  components: { YesNoCell, CreditDebitCell, CountCell, Tooltip, DataTable, Flyout, IncompleteSettlementModal },
  props: {
    selectedPartner: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: false,
      default: null,
    },
    locationId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    nextPartner: true,
    financeTemplate: {},
    notes: "",
  }),
  computed: {
    modifierSections() {
      return [
        { name: "Services Rendered", id: "servicesRendered", entries: this.servicesRendered },
        { name: "Adjustments", id: "adjustments", entries: this.adjustments },
        { name: "Payments", id: "payments", entries: this.payments },
      ];
    },
    servicesRendered() {
      if (this.financeTemplate && this.financeTemplate.transactionModifiers) {
        return this.financeTemplate.transactionModifiers.filter((modifier) => SettlementItemType.isServicesRendered(modifier.modifierType));
      }
      return [];
    },
    adjustments() {
      if (this.financeTemplate && this.financeTemplate.transactionModifiers) {
        return this.financeTemplate.transactionModifiers.filter((modifier) => SettlementItemType.isAdjustments(modifier.modifierType));
      }
      return [];
    },
    payments() {
      if (this.financeTemplate && this.financeTemplate.transactionModifiers) {
        return this.financeTemplate.transactionModifiers.filter((modifier) => SettlementItemType.isPayments(modifier.modifierType));
      }
      return [];
    },
    transactionModifierHeaders() {
      return [
        new TableHeader({ cellContents: "type", name: "type", cellClass: "cell25" }),
        new TableHeader({ cellContents: "description", name: "description", cellClass: "cell45" }),
        new TableHeader({ cellContents: "amount", name: "amount", cellClass: "cell30" }),
      ];
    },
    partnerGross() {
      return this.servicesRendered.reduce((total, value) => total + (value.amount && value.amount > 0 ? value.amount : 0), 0);
    },
    partnerCredits() {
      return [...this.adjustments, ...this.payments]
        .filter((modifier) => TransactionModifierFactory.isCredit(modifier))
        .reduce((total, value) => total + (value.amount && value.amount > 0 ? value.amount : 0), 0);
    },
    partnerDebits() {
      return [...this.adjustments, ...this.payments]
        .filter((modifier) => !TransactionModifierFactory.isCredit(modifier))
        .reduce((total, value) => total + (value.amount && value.amount > 0 ? value.amount : 0), 0);
    },
    partnerNet() {
      return this.partnerGross + this.partnerCredits - this.partnerDebits;
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    ...mapActions(["showFlyout"]),
    completeEntry() {
      if (!this.nextPartner) {
        this.showFlyout("");
      }
      const payout = {
        grossAmount: this.partnerGross,
        netAmount: this.partnerNet,
        credits: this.partnerCredits,
        debits: this.partnerDebits,
        withholdings: 0,
        notes: this.notes,
        name: `${this.selectedPartner.firstName} ${this.selectedPartner.lastName}`.trim(),
        partnerID: this.selectedPartner.id,
      };
      this.financeTemplate = {};
      this.notes = "";
      this.$emit("onSave", { payout, nextPartner: this.nextPartner });
    },
    moreGranularTemplate(selectedTemplate, template) {
      if (!selectedTemplate) return true;
      if (selectedTemplate.locationPartnerID) return false;
      if (selectedTemplate.locationID && template.locationPartnerID) return true;
      if (selectedTemplate.clientID && template.locationID) return true;
      return false;
    },
    transactionTypeDisplay(modifier) {
      return TransactionModifierFactory.transactionTypeDisplay(modifier);
    },
    isCredit(modifier) {
      return TransactionModifierFactory.isCredit(modifier);
    },
    isCount(modifier) {
      return TransactionType.isCount(modifier);
    },
    isCreditDebit(modifier) {
      return TransactionType.isCreditDebit(modifier);
    },
    isYesNo(modifier) {
      return TransactionType.isYesNo(modifier);
    },
    updateModifier(modifier, newValue) {
      if (TransactionType.isCustom(modifier)) {
        return;
      }
      const transactionModifiers = this.financeTemplate.transactionModifiers;
      const selectedModifiers = transactionModifiers.filter((transactionModifier) => transactionModifier.id === modifier.id);
      if (selectedModifiers && selectedModifiers.length) {
        const selectedModifier = selectedModifiers[0];
        if (TransactionType.isYesNo(selectedModifier)) {
          selectedModifier.condition = newValue;
          selectedModifier.amount = parseFloat(TransactionModifierFactory.computeYesNoAmount(modifier, transactionModifiers));
        } else {
          selectedModifier.amount = parseFloat(TransactionModifierFactory.computeAmount(modifier, newValue));
        }
        transactionModifiers
          .filter((transactionModifier) => TransactionType.isCustom(transactionModifier))
          .forEach((transactionModifier) => {
            if (transactionModifier.additionalData.existingTransaction === modifier.id) {
              const newAmount = TransactionModifierFactory.computeCustomAmount(transactionModifier, selectedModifier, newValue);
              transactionModifier.amount = parseFloat(newAmount);
            }
          });
      }

      // This is here to prompt the page to redraw
      this.financeTemplate.transactionModifiers = [...this.financeTemplate.transactionModifiers];
    },
    computeTotal(modifier) {
      return this.format(modifier.amount ? modifier.amount : 0);
    },
    format(money) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(money);
    },
  },
  apollo: {
    financeTemplate: {
      query: GET_FINANCE_TEMPLATE,
      variables() {
        return { clientID: this.clientId, locationID: this.locationId, partnerID: this.selectedPartner.id };
      },
      result({ data }) {
        const templates = data.financeTemplate;
        let selectedTemplate = null;
        if (templates && templates.length > 0) {
          for (const template of templates) {
            if (this.moreGranularTemplate(selectedTemplate, template)) {
              selectedTemplate = template;
            }
          }
          this.financeTemplate = selectedTemplate;
          this.financeTemplate.transactionModifiers.forEach((transactionModifier) => {
            this.updateModifier(transactionModifier, TransactionType.isYesNo(transactionModifier) ? "NO" : 0);
          });
        }
      },
      skip() {
        return !this.$props.clientId;
      },
    },
  },
};
</script>

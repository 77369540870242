<template>
  <div class="current-step"> 
    <h5>{{ currentStep.screeningStep }} - {{ currentStep.screeningStatus }}</h5>
    <div>
      <div style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.screeningDate | formatDateTimePacific }}</span>
      </div>
      <!-- <div v-if="currentStep.screeningStatus === 'IDV Pending'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.idvPendingDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'IDV Fail'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.idvFailDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Personal Details Pending'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.pdDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'OCR failed'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.ocrfailedDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Manual Clear'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.manualClearDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Insurance Image Invalid'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.imageInvalidDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'OCR Clear'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.ocrClearDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Invitation Sent'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chkInviteDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Package Submitted'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chksubmittedDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Package Suspended'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chksuspendedDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Package Clear'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chkClearDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Package Consider'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chkConsiderDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Checkr Package Denied'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.chkDinedDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Application Waitlist'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.applicationwaitDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'DMS Data Issue'" style="padding-bottom: 10px">
        <span style="color: #c2272c">{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.dmsDataDate | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Approved Manual'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.approvedTimeStamp | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Approved Auto'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.approvedAutoStamp | formatDateTimePacific }}</span>
      </div>
      <div v-if="currentStep.screeningStatus === 'Withdrawn'" style="padding-bottom: 10px">
        <span>{{ currentStep.screeningStatus }}</span>
        <span style="color: #747474; padding-left: 5px">- {{ currentStep.withdrwnDate | formatDateTimePacific }}</span>
      </div> -->

      


      <!-- this is manual part -->
      <!-- <status-date v-if="manual" :status="'Approved Manual'" :current-step="currentStep"> </status-date> -->
      <!-- <div v-if="manual">
        <div style="padding-bottom: 10px">
          <span> Approved Manual</span>
          <span style="color: #747474; padding-left: 5px"> {{ currentStep.approvedTimeStamp }}</span>
        </div>
      </div> -->
      <!-- <div v-if="approvedAutoC">
        <div style="padding-bottom: 10px">
          <span> Approved Auto</span>
          <span style="color: #747474; padding-left: 5px"> {{ currentStep.approvedTimeStamp }}</span>
        </div>
      </div> -->
      <div v-if="displayCheckrLink" style="padding-bottom: 20px; padding-top: 20px">
        <a :href="reportLinks" target="_blank">View in Checkr<i class="icon-external-link right-icon"></i></a>
      </div>
      <div v-if="currentStep.displayScreeningFlag" style="padding-top: 30px">
        <label>Screening Status</label>
        <span style="display: block; padding-top: 5px">{{ currentStep.screeningStatus }}</span>
      </div>

      <div v-if="currentStep.screeningStatus === 'MVR Returned Consider'" style="padding-top: 20px">
        <div v-if="hideRunPackage">
          <div class="radio-custom">
            <input id="approveMVR" v-model="runScreening" type="radio" name="screening-run" value="yes" @click="enableSaveButton" />
            <label for="approveMVR"><span class="label-container">Run CBC</span></label>
          </div>
          <div style="padding-bottom: 15px" class="radio-custom">
            <input id="denyMVR" v-model="runScreening" type="radio" name="screening-run" value="no" @click="enableSaveButton" />
            <label for="denyMVR"><span class="label-container">Deny</span></label>
          </div>
          <button class="button" :disabled="!saveEnabled" @click="runPackage">Save</button>
        </div>
      </div>
      <div v-if="currentStep.screeningStatus === 'CBC Returned Consider' && $props.data.currentStep.step === 'CBC'" style="padding-top: 20px">
        <div class="radio-custom">
          <input id="approveCBC" v-model="runScreening" type="radio" name="screening-run" value="yes" @click="enableSaveButton" />
          <label for="approveCBC"><span class="label-container">Approve</span></label>
        </div>
        <div style="padding-bottom: 15px" class="radio-custom">
          <input id="denyCBC" v-model="runScreening" type="radio" name="screening-run" value="no" @click="enableSaveButton" />
          <label for="denyCBC"><span class="label-container">Deny</span></label>
        </div>
        <button class="button" :disabled="!saveEnabled" @click="manualApproval('')">Save</button>
        <p class="error-message">{{ checkrError }}</p>
      </div>

      <div v-if="currentStep.screeningStatus === 'Checkr Package Consider' && $props.data.currentStep.step === 'Checkr Package' && getRole !='TS_MANAGER' && getRole !='ACCOUNTING_SUPERVISOR' && getRole !='LOCATION_MANAGER' " style="padding-top: 20px">
        <div class="radio-custom">
          <input id="approveCBC" v-model="runScreening" type="radio" name="screening-run" value="yes" @click="enableSaveButton" />
          <label for="approveCBC"><span class="label-container">Approve</span></label>
        </div>
        <div style="padding-bottom: 15px" class="radio-custom">
          <input id="denyCBC" v-model="runScreening" type="radio" name="screening-run" value="no" @click="enableSaveButton" />
          <label for="denyCBC"><span class="label-container">Deny</span></label>
        </div>
        <button class="button" :disabled="!saveEnabled" @click="manualApproval('')">Save</button>
        <p class="error-message">{{ checkrError }}</p>
      </div>
    </div>

    <!-- <p v-if="candidateNeedsDirection">Direct a candidate to their application</p> -->
    <!-- <status-date v-if="screeningSubmitted" :current-step="currentStep" status="Not Returned"> </status-date> -->
    <communications-status :current-step="currentStep" />
    <!-- <div v-if="currentStep.screeningStatus === 'withdrawn'">
      <status-date :current-step="currentStep"> </status-date>
      <div class="margin-left-12 withdraw-reason-header">
        Reason: <br />
        <p class="withdraw-reason-content">{{ currentStep.stepDetail }}</p>
      </div>
    </div> -->
    <!-- <div v-if="screeningPastNeeded">
      <label>Screening Status</label>
      <p>{{ currentStep.status }}</p>
    </div> -->
    <div v-if="isCompleted && showForceApprovalSelection && hideHCSelectionCBCDenied">
      <label>Location HC fulfillment</label>
      <p>{{ candidate.locationCountCurrent === null ? 0 : candidate.locationCountCurrent }} Approved / {{ candidate.locationCountTotal === null ? 0 : candidate.locationCountTotal }} Requested</p>
      <div class="module inner">
        <div class="radio-custom" v-if="getPermission('approveApplicant')" >
          <input id="force-approve" type="radio" :checked="approval.forced === 'FORCE'" value="FORCE" name="approval-stat" @change="updateForceApproval($event.target.value)" />
          <label for="force-approve"><span class="label-container">Force Approval</span></label>
        </div>
        <div class="radio-custom" v-if="getPermission('withdrawApplicant')">
          <input id="withdraw-approve" type="radio" :checked="approval.forced === 'WITHDRAW'" value="WITHDRAW" name="approval-stat" @change="updateForceApproval($event.target.value)" />
          <label for="withdraw-approve"><span class="label-container">WITHDRAW</span></label>
        </div>
      </div>
      <button class="button" v-if="getPermission('approveApplicant') || getPermission('withdrawApplicant')" @click="saveForce">Save</button>
    </div>
    <div v-if="screeningNeeded" class="needs-screening margin-top-44">
      <!-- <p>Verify this personal info before running a screening package for this candidate:</p> -->
      <screening-table
        v-model="screeningVerified"
        :candidate="$props.candidate"
        :personal-details="$props.personalDetails"
        :partner-steps="$props.partnerSteps"
        @onRemoveInsurance="removeInsuranceImage"
        @imageChanged="insuranceImageUploaded"
        @triggerMVR="runScreeningPackage"
      />
      <!-- <button class="button" :disabled="checkrLoading" @click="runScreeningPackage">
        Run Screening Package <span v-if="checkrLoading" class="loading"><Loader /></span>
      </button> -->
      <p class="error-message">{{ checkrError }}</p>
    </div>
    <!-- <status-date v-if="isFullyApproved" :current-step="currentStep" :status="approvedLabel" /> -->
    <eligibility-questions v-if="showQualifyingQuestions" :eligibility-questions="data.eligibilityQuestions" @onSaveQualifyingAnswers="saveQualifyingAnswers" />
    <personal-details v-if="showPersonalDetails && getPermission('viewAllPII')" :candidate="candidate" :personal-details="$props.personalDetails" @onSavePersonalDetail="savePersonalDetails" />
    <vehicle-info v-if="showVehicleInfo" :candidate="candidate" :personal-details="$props.personalDetails" @onSaveVehicleInfo="saveVehicleDetails"/>
    <documents v-if="showDocuments" :funnel-documents="funnelDocuments" :partner-id="$props.candidate.userId" />
    <preview-insurance-modal
    :id="'maintenance-table-insurance-preview'"
      :data="insuranceData"
      :partnerPageInsuranceMicroModal="partnerPageInsuranceMicroModal"
      :modalKey="modalKey"
      :showPreview="isInsuranceImageLoaded"
      @onRemove="removeInsuranceImage"
      @onSave="updateInsurance"
      @onCancel="closeModal"
      @imageChanged="insuranceImageUploaded"
    />
    <div v-if="viewInsPreview && getPermission('viewUpldInsurance')">
      <hr class="qualifying-line" />
      <a class="button ghost" @click="showInsuranceModal"> View/Edit Insurance <i class="icon icon-external-link"></i></a>
    </div>
  </div>
</template>
<script>
import { OnboardingStatus } from "@/modules/admin/partners/model";
import ScreeningTable from "@/modules/admin/onboarding/components/ScreeningTable";
// import { Loader } from "@/components";
import { CommunicationsStatus } from "@/modules/admin/onboarding/components/status";
import EligibilityQuestions from "@/modules/admin/onboarding/components/EligibilityQuestions";
import PersonalDetails from "@/modules/admin/onboarding/components/PersonalDetails";
import VehicleInfo from "@/modules/admin/onboarding/components/VehicleInfo";
import Documents from "@/modules/admin/onboarding/components/Documents";
import { DateTime } from "luxon";
import PreviewInsuranceModal from "@/modules/admin/onboarding/modals/PreviewInsuranceModal";
import micromodal from "micromodal";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { mapGetters } from "vuex";
// import { formatDatePacific } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
// import { formatDate } from "@/filters";


import {FunnelStages,FunnelSteps,FunnelStatus,FunnelProgress} from "@/util/funnelProgress";
// import VehicleInfo from "./VehicleInfo.vue";

export default {
  components: { VehicleInfo, PersonalDetails, EligibilityQuestions, CommunicationsStatus, ScreeningTable, Documents, PreviewInsuranceModal },
  filters: {
    formatStatus(status) {
      return status ? OnboardingStatus.getDisplay(status) : "";
    },
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
    partnerSteps: {
      type: Array,
      required: false,
      default: () => [],
    },
    isPartnerPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fp: new FunnelProgress(),
      isInsuranceImageLoaded: false,
      screeningVerified: false,
      approval: {},
    insuranceData: {},
      checkrLoading: false,
      checkrError: "",
      tempExpiry: "",
      displayScreeningFlag: true,
      runScreening: "",
      insuranceImageURL: "",
      saveEnabled: false,
      hideRunPackage: true,
      reportsConfig: {},
      modalKey: 0,
      partnerPageInsuranceMicroModal: false,
      showForceApprovalSelection: true,
      hideHCSelectionCBCDenied:true
    };
  },
  computed: {
    ...mapGetters(["getUsername", "getPermission" , "getRole"]),
    approvedAutoC() {
      return this.$props.candidate.status === FunnelStatus.APPROVED_AUTO;
    },
    currentStep() {
      return this.$props.data && this.$props.data.currentStep ? this.$props.data.currentStep : {};
    },
    isScreening() {
      return this.currentStep.stage === FunnelStages.RISK_MITIGATION;
    },
    isCompleted() {
      return this.$props.partnerSteps[0].status === FunnelStatus.APPLICATION_WAITLIST ? true: false
    },
    isApproved() {
      return this.currentStep.step === FunnelSteps.APPLICATION_APPROVED;
    },
    isFullyApproved() {
      return this.isApproved;
    },
    manual() {
      return this.currentStep.status === FunnelStatus.APPROVED_MANUAL;
    },
    screeningNeeded() {
      return (
        [FunnelStatus.OCR_FAILED,FunnelStatus.INSURANCE_IMAGE_INVALID].includes(this.currentStep.status) 
      );
    },
    viewInsPreview() {
      return (
        [FunnelStatus.INSURANCE_UPLOAD_COMPLETE,FunnelStatus.PAYMENT_AGREEMENT_PENDING,FunnelStatus.PAYMENT_AGREEMENT_COMPLETE,
        FunnelStatus.OCR_FAILED,FunnelStatus.INSURANCE_IMAGE_INVALID,FunnelStatus.OCR_MANUAL_CLEAR,FunnelStatus.OCR_CLEAR,
        FunnelStatus.MVR_SUBMITTED,FunnelStatus.MVR_PENDING,FunnelStatus.MVR_SUSPENDED,FunnelStatus.MVR_CLEAR,FunnelStatus.MVR_CONSIDER,
        FunnelStatus.MVR_DENIED,FunnelStatus.CHECKER_PACKAGE_SUSPENDED,FunnelStatus.CHECKER_PACKAGE_DENIED,FunnelStatus.CHECKER_PACKAGE_CLEAR,FunnelStatus.CHECKER_PACKAGE_SUBMITTED,
        FunnelStatus.CHECKER_PACKAGE_CONSIDER,FunnelStatus.APPROVED_AUTO,FunnelStatus.APPROVED_MANUAL,FunnelStatus.WITHDRAWN,FunnelStatus.APPLICATION_WAITLIST
        ].includes(this.currentStep.status) 
      )
    },
    displayLicencePreview() {
      return this.isScreening || this.isCompleted || this.isApproved ? true : false;
    },
    showQualifyingQuestions() {
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.PERSONAL_DETAILS_PENDING) >= 0;
    },
    showPersonalDetails() {
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.PERSONAL_DETAILS_COMPLETE ) >= 0;
    },
    showVehicleInfo(){
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.INSURANCE_UPLOAD_COMPLETE ) >= 0;
    },
    showDocuments() {
      return this.$props.data.steps.findIndex((step) => step.status === FunnelStatus.DOCUMENTS_COMPLETE) >= 0;
    },
    funnelDocuments() {
      return this.$props.candidate.allDocs ? this.$props.candidate.allDocs : [];
    },
    approvedLabel() {
      return `${OnboardingStatus.getDisplay(this.currentStep.status)} ${this.currentStep.statusDetail}`;
    },
    insuranceModalData() {
      return {
        insuranceImageURL: this.$props.candidate.insuranceImageURL,
        insuranceExpiry: this.formatDate(this.$props.candidate.insuranceExpiry),
        funnelFilesID: this.funnelFilesId,
        locationPartnerID: this.$props.candidate.id,
        locationID: this.$props.candidate.locationID,
        clientID: this.$props.candidate.clientID,
        partnerID: this.$props.candidate.partnerID,
        documentTemplateID: this.getInsuranceDocumentsTemplateID(this.$props.candidate.allDocs),
        signature: this.$props.candidate.electronicSignature ? this.$props.candidate.electronicSignature : "",
        userID: this.$props.candidate.userId,
        specialist: this.$props.candidate.specialist,
        phone:this.$props.candidate.phone

      };
    },
    reportLinks() {
      return `${this.reportsConfig.rootCheckrLink}/candidates/${this.$props.candidate.externalID}${this.reportsConfig.checkrTestQuery}`;
    },
    displayCheckrLink() {
      return this.$props.data.currentStep.chkInviteDate ? true : false;
    },
  },
  mounted() {
    restApi.get("onboard/getCheckConfig").then((data) => {
      this.reportsConfig = data.data.result.length ? JSON.parse(data.data.result[0].value) : { rootCheckrLink: "", checkrTestQuery: "" };
    });
  },
  methods: {
    insuranceImageUploaded(data) {
      // this.$emit("insuranceImageUploaded");
      this.insuranceData = {...this.insuranceData, insuranceImageURL:data.fileURL, isPdf: this.checkFileFormat(data.filename)}

    },
    removeInsuranceImage() {
      // this.$emit("onRemoveInsurance");
      this.insuranceData = {...this.insuranceData, insuranceImageURL:null}

    },
    async runScreeningPackage() {
      restApi.post("/onboard/getFunnelProgress", encodeWithParam({ candidateID: this.$props.candidate.id })).then(async (data)=>{
      const isCompleted = data.data.result.filter((progress) => {
        return progress.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_COMPLETE;
      });
      const insuranceVerified = data.data.result.filter((progress) => progress.candidateStatus == FunnelStatus.OCR_MANUAL_CLEAR ||progress.candidateStatus === FunnelStatus.OCR_CLEAR);
      const isMVR = data.data.result.filter((progress) => {
        return progress.candidateStatus == FunnelStatus.MVR_SUBMITTED
      });
      if (isCompleted.length > 0 && isMVR.length == 0 && insuranceVerified.length > 0)  {
        this.checkrLoading = true;
        this.checkrError = "";
        let candidate = this.$props.candidate;
        //candidate["allDocs"] = [];
        let updatedlocationID = await restApi.post("/onboard/getLocationID", encodeWithParam({ partnerID: this.$props.candidate.partnerID }));
        candidate.locationID = updatedlocationID.data.result[0].clientLocationID;
        this.$toast.success("Checkr invitation sent");
        restApi.post("/onboard/triggerMVR", encodeWithParam({id:candidate.partnerID,locationID: candidate.locationID})).then(()=>{
            this.screeningVerified = true;
            this.checkrLoading = false;
            this.$emit("refresh", {});
        }).catch((error)=>{
           this.checkrLoading = false;
            this.checkrError = error.error;
        })
      } else {
        if(insuranceVerified.length === 0) {
          this.$toast.error("Insurance is not verified");
        } else if(isCompleted.length === 0) {
          this.$toast.error("Payment Agreement is Pending");
        }
        else {
          this.$toast.error("MVR already triggered");
        }
      }
      })
    },
    enableSaveButton() {
      this.saveEnabled = true;
    },
    async runPackage() {
      if (this.runScreening === "yes") {
        let candidate = this.$props.candidate;
        //candidate["allDocs"] = [];
        let updatedlocationID = await restApi.post("/onboard/getLocationID", encodeWithParam({ partnerID: this.$props.candidate.partnerID }));
        candidate.locationID = updatedlocationID.data.result[0].clientLocationID;
        restApi.post("/onboard/triggerCBC", encodeWithParam({id:this.$props.candidate.partnerID,locationID: candidate.locationID})).catch((error)=>{
            this.checkrError = error.error;
        })
      } else {
        const candidateProgress = this.candidateProgressObject(FunnelStatus.MVR_DENIED);
        this.updateCandidateProgress(candidateProgress);
      }
      this.hideRunPackage = false;
    },
    saveQualifyingAnswers(eligibilityInfo) {
      this.$emit("onSaveQualifyingAnswers", eligibilityInfo);
    },

    savePersonalDetails(personalDetailsInfo) {
      this.$emit("onSavePersonalDetails", personalDetailsInfo);
    },
    saveVehicleDetails(driverVehicleData){
      this.$emit("onSaveVehicleInfo",driverVehicleData)
    },
    updateForceApproval(e) {
      this.approval.forced = e;
    },
    removeInsurance() {
      this.insuranceImageURL = null;
      this.insuranceData = {...this.insuranceData, insuranceImageURL:null}

      // TODO hard delete the image?
    },
    imageChanged(data) {
      this.insuranceData = {...this.insuranceData, insuranceImageURL:data.fileURL, isPdf: this.checkFileFormat(data.filename)}
    },
    sendToDms() {
      restApi.post(`/walmart/onboarding`, encodeWithParam({ partnerID:this.candidate.partnerID,approvalType:FunnelStatus.APPROVED_MANUAL }))
      this.$toast.success("Request Sent to DMS")
    },

    approvedTotalCount() {
      let approvedCount = this.candidate.locationCountCurrent === null ? 0 : this.candidate.locationCountCurrent;
      let totalCount = this.candidate.locationCountTotal === null ? 0 : this.candidate.locationCountTotal;
      return {approvedCount,totalCount}
    },
    saveForce() {
      this.showForceApprovalSelection = false;
      if (this.approval.forced === "FORCE") {
          const candidateProgress = this.candidateProgressObject(FunnelStatus.APPLICATION_WAITLIST);
          this.updateCandidateProgress(candidateProgress);
          this.sendToDms()
      }
      if (this.approval.forced === "WITHDRAW") {
          const candidateProgress = this.candidateProgressObject(FunnelStatus.WITHDRAWN);
          this.updateCandidateProgress(candidateProgress);
          this.$toast.error("Withdrawn")
      }
    },
    async manualApproval() {
      if (this.runScreening === "yes") {
         const candidateProgress = this.candidateProgressObject(FunnelStatus.APPLICATION_WAITLIST);
         this.updateCandidateProgress(candidateProgress);
         const {approvedCount,totalCount} = this.approvedTotalCount();
         if(approvedCount < totalCount ) {
            this.hideHCSelectionCBCDenied = false;
            this.sendToDms()
         } else {
          this.$toast.error("Head Count Request Full")
         }

      } else {
         const candidateProgress = this.candidateProgressObject(FunnelStatus.CHECKER_PACKAGE_DENIED);
         this.updateCandidateProgress(candidateProgress);
      }
     
    },
    updateCandidateProgress(candidateProgress) {
      return restApi.post("/onboard/saveCandidateStatus", encodeWithParam(candidateProgress));
    },
    setApprovalDate() {
      restApi.post("/onboard/setApprovalDate", encodeWithParam({ id: this.candidate.id, approvalDate: DateTime.utc().toJSDate() }));
    },
    candidateProgressObject(status) {
      return {
        assignedOS: this.candidate.specialist ,
        partnerID: this.candidate.partnerID,
        stepStatus: status
      };
    },

    checkMvrSubmitted(progress) {
      let data = progress?.data.result.filter((item)=>( item.candidateStatus == FunnelStatus.CHECKER_PACKAGE_SUBMITTED));
      return data.length > 0 ? true :false;
    },
    updateAutoInsurance() {
      if (this.$props.candidate.partnerID) {
        let mvrSubmittedFlag = false;
        restApi
          .post("/partner/updateInsurance", encodeWithParam({ insuranceExpiry: this.tempExpiry, partnerID: this.$props.candidate.partnerID, verified: true }))
          .then(async () => {
            const obj = this.candidateProgressObject(FunnelStatus.OCR_MANUAL_CLEAR);
            await this.updateCandidateProgress(obj);
            restApi.post("/onboard/getFunnelProgress", encodeWithParam({ candidateID: this.$props.candidate.id })).then((data) => {
              mvrSubmittedFlag =this.checkMvrSubmitted(data);
              if(!mvrSubmittedFlag) {
                this.runScreeningPackage();
              }
            });
          })
          .catch(() => this.$toast.error("Updating insurance failed"));
      }
    },
    updateInsurance(updateInsuranceData) {
      if (typeof updateInsuranceData.expirationDate == "string") {
        this.tempExpiry = updateInsuranceData.expirationDate;
      } else {
        this.tempExpiry = this.formatDate(updateInsuranceData.expirationDate.toISOString());
      }
      this.updateAutoInsurance();
      this.closeModal();
    },
    formatDate(dateString) {
      return DateTime.fromISO(dateString).toFormat("dd MMM yyyy");
    },
    async showInsuranceModal() {
      if (this.$props.isPartnerPage) {
        this.partnerPageInsuranceMicroModal = true  
      }
      this.isInsuranceImageLoaded = false;
      micromodal.show("maintenance-table-insurance-preview", {});
      try{
      await restApi.post('/onboard/getDocuments',encodeWithParam({
        clientLocationPartnerID: this.$props.candidate.id,
        clientID: this.$props.candidate.clientID,
        clientLocationID: this.$props.candidate.locationID,
        userID: this.$props.candidate.userID
      })).then((docs) => {
        
        let insuranceFileDetails = docs.data.result.filter((item)=>item.title.toLowerCase() === "insurance");
        restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: insuranceFileDetails[0].fileID})).then((data)=>{
          // let imageData= data.data.bufferInHex ? convertBufferToContent(data.data.bufferInHex,data.data.contentType) : null
          this.insuranceData = {
          insuranceImageURL: data.data.bufferInHex ? convertBufferToContent(data.data.bufferInHex, data.data.contentType) : null,
          isPdf: false,
          insuranceExpiry: this.formatDate(this.$props.candidate.insuranceExpiry),

          funnelFilesID: this.funnelFilesId,
          locationPartnerID: this.$props.candidate.id,
          locationID: this.$props.candidate.locationID,
          partnerID: this.$props.candidate.partnerID,
          documentTemplateID: insuranceFileDetails[0].id,
          userID: this.$props.candidate.userID,
          phone: this.$props.data.phone,
        };
        this.isInsuranceImageLoaded = true;
       
        // console.log(this.insuranceData,"IMAGE DSTAATTA")
        })
      })
      }catch (error) {
        console.log(error);
      }
     
     
    },
    checkFileFormat(filename) {
      if (!filename) return false;
      return filename.toLowerCase().includes(".pdf");
    },
    closeModal() { 
      if (this.$props.isPartnerPage) {
        this.partnerPageInsuranceMicroModal = false
      }
      this.insuranceData = {};
      this.isInsuranceImageLoaded = false
      micromodal.close("maintenance-table-insurance-preview");
    },
    getInsuranceDocumentsTemplateID(docs) {
      // console.log(docs, "ROHIT")
      if (docs?.length > 0) {
        let insuranceID;
        insuranceID = docs?.filter((item) => item.title.toLowerCase() === "insurance");
        return insuranceID[0].id;
      } else {
        return "";
      }
    },
 
  }, 
  watch: {
      modalKey(oldKey, newKey) {
        if (oldKey != newKey) {
          console.log(newKey)
          return newKey
        }
      }
    }
};
</script>

<style scoped>
.margin-top-44 {
  margin-top: 44px !important;
}
</style>

import SettlementItemType from "./SettlementItemType";

class TransactionType {
  static COUNT = "COUNT";
  static CREDIT = "CREDIT";
  static DEBIT = "DEBIT";
  static STRUCTURED_RATE = "STRUCTURED_RATE";
  static STRUCTURED_PARTNER_RATE = "STRUCTURED_PARTNER_RATE";
  static CLIENT_INPUT = "CLIENT_INPUT";
  static CREDIT_DEBIT = "CREDIT_DEBIT";
  static CUSTOM = "CUSTOM";
  static PRESET = "PRESET";
  static YES_NO = "YES_NO";
  static COST_PER_LOCATION = "COST_PER_LOCATION";
  static COST_PER_PARTNER = "COST_PER_PARTNER";
  static CHILD_CARE_SUPPORT = "CHILD_CARE_SUPPORT";
  static EDUCATIONAL_GARNISHMENT = "EDUCATIONAL_GARNISHMENT";
  static TAX_GARNISHMENT = "TAX_GARNISHMENT";
  static COURT_LEIN = "COURT_LEIN";
  static OTHER = "OTHER";
  static PRESET_AMOUNT = "PRESET_AMOUNT";

  static typeMap = {
    [TransactionType.COUNT]: { optionLabel: "Count", displayValue: "Count" },
    [TransactionType.CREDIT]: { optionLabel: "Enter Credit", displayValue: "Credit" },
    [TransactionType.DEBIT]: { optionLabel: "Enter Debit", displayValue: "Debit" },
    [TransactionType.CLIENT_INPUT]: { optionLabel: "Client Input", displayValue: "Client Input" },
    [TransactionType.STRUCTURED_RATE]: { optionLabel: "Structured Partner Rate - Pay Received", displayValue: "Structured Partner Rate - Pay Received" },
    [TransactionType.STRUCTURED_PARTNER_RATE]: { optionLabel: "Structured Partner Rate - Services Rendered", displayValue: "Structured Partner Rate - Services Rendered" },
    [TransactionType.CREDIT_DEBIT]: { optionLabel: "Enter Credit/Debit", displayValue: "Credit/Debit" },
    [TransactionType.CUSTOM]: { optionLabel: "Custom Formula (use other transactions)", displayValue: "Custom" },
    [TransactionType.PRESET]: { optionLabel: "Preset Dollar Amount", displayValue: "Preset" },
    [TransactionType.YES_NO]: { optionLabel: "Yes/No", displayValue: "Yes/No" },
    [TransactionType.COST_PER_LOCATION]: { optionLabel: "Cost Per Location (per pay cycle)", displayValue: "Cost Per Location" },
    [TransactionType.COST_PER_PARTNER]: { optionLabel: "Cost Per Partner (per pay cycle)", displayValue: "Cost Per Partner" },
    [TransactionType.CHILD_CARE_SUPPORT]: { optionLabel: "Child Care Support", displayValue: "Child Care Support" },
    [TransactionType.EDUCATIONAL_GARNISHMENT]: { optionLabel: "Educational Garnishment", displayValue: "Educational Garnishment" },
    [TransactionType.TAX_GARNISHMENT]: { optionLabel: "Tax Garnishment", displayValue: "Tax Garnishment" },
    [TransactionType.COURT_LEIN]: { optionLabel: "Court Lein", displayValue: "Court Lein" },
    [TransactionType.OTHER]: { optionLabel: "Other", displayValue: "Other" },
    [TransactionType.PRESET_AMOUNT]: { optionLabel: "Preset Amount", displayValue: "Preset Amount" },
  };

  static isType = (transactionModifier, transactionType) => transactionModifier && transactionModifier.transactionType === transactionType;
  static isCount = (transactionModifier) => this.isType(transactionModifier, TransactionType.COUNT);
  static isCredit = (transactionModifier) => this.isType(transactionModifier, TransactionType.CREDIT);
  static isDebit = (transactionModifier) => this.isType(transactionModifier, TransactionType.DEBIT);
  static isClientInput = (transactionModifier) => this.isType(transactionModifier, TransactionType.CLIENT_INPUT);
  static isStructuredRate = (transactionModifier) => this.isType(transactionModifier, TransactionType.STRUCTURED_RATE);
  static isStructuredPartnerRate = (transactionModifier) => this.isType(transactionModifier, TransactionType.STRUCTURED_PARTNER_RATE);
  static isCreditDebit = (transactionModifier) => this.isType(transactionModifier, TransactionType.CREDIT_DEBIT);
  static isCustom = (transactionModifier) => this.isType(transactionModifier, TransactionType.CUSTOM);
  static isPreset = (transactionModifier) => this.isType(transactionModifier, TransactionType.PRESET);
  static isYesNo = (transactionModifier) => this.isType(transactionModifier, TransactionType.YES_NO);
  static isGarnishment = (transactionModifier) => this.isType(transactionModifier, TransactionType.GARNISHMENTS);
  static isCostPerLocation = (transactionModifier) => this.isType(transactionModifier, TransactionType.COST_PER_LOCATION);
  static isCostPerPartner = (transactionModifier) => this.isType(transactionModifier, TransactionType.COST_PER_PARTNER);
  static isPresetAmount = (transactionModifier) => this.isType(transactionModifier, TransactionType.PRESET_AMOUNT);

  static getOptions = (modifierType) => {
    if (SettlementItemType.isServicesRendered(modifierType)) {
      return [
        { id: this.COUNT, label: this.typeMap[this.COUNT].optionLabel },
        { id: this.CLIENT_INPUT, label: this.typeMap[this.CLIENT_INPUT].optionLabel },
      ];
    }
    if (SettlementItemType.isAdjustments(modifierType)) {
      return [
        { id: this.COUNT, label: this.typeMap[this.COUNT].optionLabel },
        { id: this.CREDIT_DEBIT, label: this.typeMap[this.CREDIT_DEBIT].optionLabel },
        { id: this.YES_NO, label: this.typeMap[this.YES_NO].optionLabel },
        { id: this.CUSTOM, label: this.typeMap[this.CUSTOM].optionLabel },
      ];
    }
    if (SettlementItemType.isClientRevenue(modifierType)) {
      return [
        /*{ id: this.PRESET, label: this.typeMap[this.PRESET].optionLabel },
        { id: this.COST_PER_LOCATION, label: this.typeMap[this.COST_PER_LOCATION].optionLabel },
        { id: this.COST_PER_PARTNER, label: this.typeMap[this.COST_PER_PARTNER].optionLabel },*/
        { id: this.STRUCTURED_RATE, label: this.typeMap[this.STRUCTURED_RATE].optionLabel },
        { id: this.STRUCTURED_PARTNER_RATE, label: this.typeMap[this.STRUCTURED_PARTNER_RATE].optionLabel },
      ];
    }
    if (SettlementItemType.isGarnishments(modifierType)) {
      return [
        { id: this.CHILD_CARE_SUPPORT, label: this.typeMap[this.CHILD_CARE_SUPPORT].optionLabel },
        { id: this.EDUCATIONAL_GARNISHMENT, label: this.typeMap[this.EDUCATIONAL_GARNISHMENT].optionLabel },
        { id: this.TAX_GARNISHMENT, label: this.typeMap[this.TAX_GARNISHMENT].optionLabel },
        { id: this.COURT_LEIN, label: this.typeMap[this.COURT_LEIN].optionLabel },
        { id: this.OTHER, label: this.typeMap[this.OTHER].optionLabel },
      ];
    }
    if (SettlementItemType.isPayments(modifierType)) {
      return [
        { id: this.CREDIT_DEBIT, label: this.typeMap[this.CREDIT_DEBIT].optionLabel },
        { id: this.YES_NO, label: this.typeMap[this.YES_NO].optionLabel },
        { id: this.CUSTOM, label: this.typeMap[this.CUSTOM].optionLabel },
      ];
    }
    if (SettlementItemType.isPartnerRevenue(modifierType)) {
      return [{ id: this.PRESET_AMOUNT, label: this.typeMap[this.PRESET_AMOUNT].optionLabel }];
    }
  };

  static getYesNoOptions = () => {
    return [
      { id: this.PRESET, label: this.typeMap[this.PRESET].optionLabel },
      { id: this.CUSTOM, label: this.typeMap[this.CUSTOM].optionLabel },
    ];
  };

  static getDisplay = (transactionType) => (this.typeMap[transactionType] ? this.typeMap[transactionType].displayValue : "");
}

export default TransactionType;

class SettlementItemType {
  static SERVICES_RENDERED = "SERVICES_RENDERED";
  static ADJUSTMENTS = "ADJUSTMENTS";
  static PAYMENTS = "PAYMENTS";
  static GARNISHMENTS = "GARNISHMENTS";
  static CLIENT_REVENUE = "CLIENT_REVENUE";
  static PARTNER_REVENUE = "PARTNER_REVENUE";

  static isType = (modifierType, SettlementItemType) => modifierType === SettlementItemType;
  static isServicesRendered = (modifierType) => this.isType(modifierType, this.SERVICES_RENDERED);
  static isAdjustments = (modifierType) => this.isType(modifierType, this.ADJUSTMENTS);
  static isPayments = (modifierType) => this.isType(modifierType, this.PAYMENTS);
  static isGarnishments = (modifierType) => this.isType(modifierType, this.GARNISHMENTS);
  static isClientRevenue = (modifierType) => this.isType(modifierType, this.CLIENT_REVENUE);
  static isPartnerRevenue = (modifierType) => this.isType(modifierType, this.PARTNER_REVENUE);
}

export default SettlementItemType;

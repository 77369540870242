import VueJwtDecode from "vue-jwt-decode";
//import { GET_EMPLOYEE_NAME } from "@/store/graph/queries";
import * as Roles from "@/security/rolesHelper";
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding"
const getInitialState = () => {
  return {
    userID: "",
    personID: "",
    firstName: "",
    lastName: "",
    clientID: "",
    username: "",
    role: "",
  };
};

const state = getInitialState();

const getters = {
  getUserID: (state) => {
    return state.userID;
  },
  getPersonID: (state) => {
    return state.personID;
  },
  getClientID: (state) => {
    return state.clientID;
  },
  getFullName: (state) => {
    return state.firstName || state.lastName ? `${state.firstName} ${state.lastName}` : "";
  },
  getUsername: (state) => {
    return state.username;
  },
  getRole: (state) => {
    return state.role;
  },
};

const mutations = {
  updateUserID(state, id) {
    state.userID = id;
  },
  updatePerson(state, obj) {
    state.firstName = obj.firstName;
    state.lastName = obj.lastName;
    state.personID = obj.id;
  },
  updateUsername(state, username) {
    state.username = username;
  },
  userLogout(state) {
    Object.assign(state, getInitialState());
  },
  updateClientID(state, id) {
    state.clientID = id;
  },
  updateRole(state, role) {
    state.role = role;
  },
};

const actions = {
  userLogout:({ commit })=>{
    localStorage.clear();
    sessionStorage.clear();
    Vue.$cookies.remove("_ddiRefresh");
    Sentry.configureScope((scope) => scope.setUser(null));
    commit("userLogout");
  },
  userLogin: async ({ commit }) => {
    const token = window.localStorage.getItem("access_token");
    if (token) {
      const decoded = VueJwtDecode.decode(token);
      const claims = decoded.role;
      const userRole = decoded.role;
      const userID = decoded.userID;
      const clientIdsString = claims["x-hasura-client-ids"];
      localStorage.setItem("role",userRole);
      localStorage.setItem("expiration", decoded.exp);
      localStorage.setItem("country",decoded.country)
      if (clientIdsString && clientIdsString.length > 0) {
        // Need to convert from a PG array back into a JS array because Hasura
        const clientIds = clientIdsString.replaceAll("{", "").replaceAll("}", "").replaceAll('"', "").split(",");
        commit("updateClientID", clientIds[0].trim());
      }
      if (Roles.isAdminRole({ role: userRole })) {
        commit("updateRole", userRole);
        commit("updateUserID", userID);
        // await apollo
        //   .query({
        //     query: GET_EMPLOYEE_NAME,
        //     variables: { userID },
        //   })
          restApi.post('/onboard/getUserName',encodeWithParam({username: userID})).then(({ data }) => {
           // const user = data.result[0];
            const person = data.result[0];
            commit("updateUsername", person.username);
            commit("updatePerson", person);
            Sentry.setUser({
              id: person.id,
              name: `${person.firstName} ${person.lastName}`,
              username: person.username,
              role: userRole,
            });
          });
      } 
      else {
        this.actions.userLogout(commit);
      }
    }
  },
};

export default { state, getters, mutations, actions };
//
// dieubonracine01@hotmail.com
// darilojose120@gmail.com
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"module-large"},[_c('h2',[_vm._v("Finances")]),_c('h3',[_vm._v("Partner Settlements")])]),_c('div',{staticClass:"module-xlarge"},[_c('h4',[_vm._v("Upload Settlement")]),(!_vm.file)?_c('p',{staticClass:"file-upload"},[_c('a',{staticClass:"button secondary"},[_vm._v("Upload Complete Template")]),_c('input',{attrs:{"type":"file","accept":".csv"},on:{"change":_vm.changeFile}})]):_vm._e(),(_vm.file)?_c('div',{staticClass:"file-upload-full"},[_c('div',{staticClass:"full-upload"},[_c('div',{staticClass:"file-name"},[_c('p',[_vm._v(_vm._s(_vm.file.name))])]),_c('a',{staticClass:"button secondary",on:{"click":function($event){_vm.file = null}}},[_vm._v("Cancel")])]),_c('a',{staticClass:"button",on:{"click":_vm.uploadTemplate}},[_vm._v("Review Uploaded Entries")])]):_vm._e(),_c('p',[_c('vue-json-to-csv',{attrs:{"csv-title":"Settlement_Template","json-data":_vm.clientCSVLabels,"show-labels":true}},[_c('a',{staticClass:"button ghost",on:{"click":_vm.generateCSVLabels}},[_c('i',{staticClass:"icon-download"}),_vm._v("Download The Template ")])])],1)]),_c('div',{staticClass:"module-large"},[_c('h4',[_vm._v("Settlement Manual Entry")]),_c('p',[_c('router-link',{staticClass:"button secondary",attrs:{"to":{ name: 'cp-location-settlements' }}},[_vm._v("Input Manual Entries")])],1)]),(_vm.needsCorrection.length > 0)?_c('div',{staticClass:"module-large"},[_c('h4',{staticClass:"red"},[_vm._v("Settlement Correction Needed")]),_c('data-table',{attrs:{"headers":_vm.needsCorrectionHeaders,"entries":_vm.needsCorrection,"table-id":"needs-correction-table"},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"icon-alert-octagon"}),_vm._v(" "+_vm._s(item.date))]}},{key:"notes",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"id":("note-" + (item.id)),"title":"Notes","description":item.notes,"hover":"","icon-class":"icon-file"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.editSettlement(item.id)}}},[_vm._v("Correct Settlement")])]}}],null,false,847405257)})],1):_vm._e(),_c('div',{staticClass:"module-large"},[_c('h4',[_vm._v("Previously Submitted Settlements")]),_c('data-table',{attrs:{"headers":_vm.otherSettlementsHeaders,"entries":_vm.otherSettlements,"table-id":"other-settlements-table"},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"icon-octogon"}),_vm._v(_vm._s(item.date))]}},{key:"notes",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"id":("note-" + (item.id)),"title":"Notes","description":item.notes,"hover":"","icon-class":"icon-file"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.editSettlement(item.id)}}},[_vm._v("Correct Settlement")])]}}])})],1),_c('prepare-upload-modal',{on:{"continue":_vm.continueUpload}}),_c('upload-in-progress-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from "axios";
import router from "@/router";
import { ACCESS_TOKEN_KEY, EXPIRY } from "@/modules/login/constants";
export const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});
api.interceptors.request.use((config) => {
  config.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    withCredentials: true,
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
  return config;
});

export const identityApi = axios.create({
  baseURL: process.env.VUE_APP_AUTH_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

export const fileApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});
fileApi.interceptors.request.use((config) => {
  config.headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    withCredentials: true,
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
  return config;
});


export const clientApi = axios.create({
  baseURL: process.env.VUE_APP_CLIENT_BASE_URL,
});
clientApi.interceptors.request.use((config) => {
  config.headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    withCredentials: true,
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
  return config;
});

export const carApi = axios.create({
  baseURL: "https://vpic.nhtsa.dot.gov/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const restApi = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE_URL,
});
restApi.interceptors.request.use((config) => {
  config.headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    withCredentials: true,
    Authorization: localStorage.getItem("access_token"),
  };
  return config;
});

// Add a response interceptor
restApi.interceptors.response.use(function (response) {
  if(response.data.invalidToken){
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(EXPIRY);
    return router.push({ name: "logout" });
  }
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export const reconciliationApi = axios.create({
  baseURL: process.env.VUE_APP_RECONCILIATION_API_BASE_URL,
});
reconciliationApi.interceptors.request.use((config) => {
  config.headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    withCredentials: true,
    Authorization: localStorage.getItem("access_token"),
  };
  return config;
});
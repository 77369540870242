<template>
  <loader v-if="$apollo.loading" />
  <fragment v-else>
    <div v-if="hasLocations" class="grid">
      <div class="col-8">
        <h2>{{ totalLocations }} Total Zones</h2>
        <h3>{{ locationsWithRequests }} Requesting Additional Drivers</h3>
      </div>
    </div>
    <div v-if="hasLocations" class="grid">
      <div class="col-8">
        <p class="label">Filters</p>
        <base-filter name="Locations" :options="filterOptions" @onFilter="selectedFilters = $event" />
      </div>
      <div class="col-4 flex-right">
        <progress-bar label="Locations Requests Fulfilled" :complete="totalComplete" :total="locationsRecruiting" />
        <progress-bar label="Total Head Count Fulfilled" :complete="totalHeadCountFulfilled" :total="totalHeadCount" />
      </div>
    </div>
    <div v-if="hasLocations" class="module location-header">
      <p>Showing {{ filteredList.length }} Locations</p>
      <div class="bulk-actions">
        <p v-if="false">Bulk Actions<i class="icon-more-vertical"></i></p>
      </div>
    </div>
    <div>
      <data-table :headers="headers" :entries="filteredList">
        <template #name="{ item }">
          <router-link :to="{ name: 'cp-location', params: { locationID: item.id } }">{{ item.name }}</router-link>
        </template>
        <template #progress="{ item }">
          <location-progress-cell :location="item" />
        </template>
        <template #noContents>The selected employee does not have any locations assigned to them. An account manager can make assignments!</template>
      </data-table>
    </div>
  </fragment>
</template>
<script>
import DateTime from "luxon/src/datetime";
import LocationProgressCell from "@/modules/admin/clients/locations/LocationProgressCell";
import { Fragment } from "vue-fragment";
import { mapGetters } from "vuex";
import { Loader, BaseFilter, DataTable, TableHeader, ProgressBar, Direction } from "@/components";
import { GET_LOCATIONS_BY_CLIENT } from "@/modules/admin/clients/locations/queries";

export default {
  components: { ProgressBar, LocationProgressCell, DataTable, BaseFilter, Fragment, Loader },
  data() {
    return {
      selectedFilters: [],
    };
  },
  methods: {
    sortProgress() {
      return (location1, location2) => {
        const location1Progress = this.getHeadCountFulfilledPercent(this.approvedCount(location1), location1.recruitingGoal);
        const location2Progress = this.getHeadCountFulfilledPercent(this.approvedCount(location2), location2.recruitingGoal);
        return location2Progress - location1Progress;
      };
    },
    sortOnboarding() {
      return (location1, location2) => {
        return location2.onboardingPartners.aggregate.count - location1.onboardingPartners.aggregate.count;
      };
    },
    sortApproved() {
      (location1, location2) => {
        return this.approvedCount(location2) - this.approvedCount(location1);
      };
    },
    onboardingCount(location) {
      return location.stageCounts.filter((stageCount) => stageCount.stage !== "APPROVAL").reduce((start, next) => start + next.count, 0);
    },
    approvedCount(location) {
      return location.stageCounts.filter((stageCount) => stageCount.stage === "APPROVAL").reduce((start, next) => start + next.count, 0);
    },
    requestedCount(location) {
      return location.headCountRequests.reduce((start, next) => start + next.count, 0);
    },
    getHeadCountFulfilledPercent(complete, total) {
      return !total || total === 0 ? 100 : complete / total / Math.pow(10, -2);
    },
  },
  computed: {
    ...mapGetters(["getUserID", "getSelectedEmployee", "getStartDate", "getEndDate", "getClientID"]),
    filterOptions() {
      return [
        { id: "cp-locations-unfulfilled-requests", name: "unfulfilledRequests", label: "Unfulfilled Requests", checked: false },
        { id: "cp-locations-request-made", name: "requestMade", label: "Request Made", checked: false },
        { id: "cp-locations-launching", name: "launching", label: "Launching", checked: false },
        { id: "cp-locations-less-than-30-days", name: "lessThan30Days", label: "Less than 30 Days", checked: false },
      ];
    },
    headers() {
      return [
        new TableHeader({ label: "Location", headerClass: "cell20", cellContents: "name", name: "name", sortable: true }),
        new TableHeader({ label: "Progress", headerClass: "cell20", name: "progress", sortable: true, sortFunction: this.sortProgress, sorted: true, direction: Direction.DESC }),
        new TableHeader({ label: "Requested", headerClass: "cell10", cellContents: this.requestedCount, sortable: true }),
        new TableHeader({ label: "Onboarding", headerClass: "cell10", cellContents: this.onboardingCount, sortable: true, sortFunction: this.sortOnboarding }),
        new TableHeader({ label: "Approved", headerClass: "cell10", cellContents: this.approvedCount, sortable: true, sortFunction: this.sortApproved }),
      ];
    },
    filteredList: function () {
      return this.locations
        ? this.locations.filter((location) => {
            if (this.selectedFilters.includes("unfulfilled requests") && location.recruitingGoal - location.approvedPartners.aggregate.count < 1) return false;
            if (this.selectedFilters.includes("request made") && !location.recruitingGoal > 0) return false;

            const today = DateTime.utc(DateTime.utc().year, DateTime.utc().month, DateTime.utc().day);

            if (this.selectedFilters.includes("launching") && location.startDate > today) return false;
            return !(this.selectedFilters.includes("less than 30 days") && today < location.startDate && location.startDate <= today.plus({ days: 30 }));
          })
        : [];
    },
    hasLocations() {
      return this.totalLocations > 0;
    },
    totalLocations: function () {
      return this.locations.length;
    },
    locationsRecruiting: function () {
      return this.locations.filter((location) => location.isRecruiting).length;
    },
    locationsWithRequests: function () {
      return this.locations.filter((location) => location.isRecruiting && location.recruitingGoal > this.approvedCount(location)).length;
    },
    totalComplete: function () {
      return this.locations.filter((location) => location.progress === 100).length;
    },
    totalHeadCount: function () {
      return this.locations.reduce((total, location) => total + location.recruitingGoal, 0);
    },
    totalHeadCountFulfilled: function () {
      return this.locations.reduce((total, location) => {
        const approvedCount = this.approvedCount(location);
        if (location.isRecruiting) {
          const toAdd = approvedCount > location.recruitingGoal ? location.recruitingGoal : approvedCount;
          return total + toAdd;
        }
        return total;
      }, 0);
    },
    startOfWeek() {
      return DateTime.utc().startOf("day").set({ weekday: 1 });
    },
    locations() {
      if (this.client) {
        return this.client.locations;
      }
      return [];
    },
  },
  apollo: {
    client: {
      fetchPolicy: "no-cache",
      query: GET_LOCATIONS_BY_CLIENT,
      variables() {
        return {
          id: this.getClientID,
          startDate: this.startOfWeek.toJSDate(),
        };
      },
      skip() {
        return !this.getSelectedEmployee;
      },
    },
  },
};
</script>

<template>
  <fragment>
    <div class="grid">
      <div class="col-8">
        <h2>{{ totalLocations }} Total Zones</h2>
        <h3>{{ locationsWithRequests }} Requesting Additional Drivers</h3>
      </div>
    </div>
    <div class="grid">
      <div class="col-8">
        <!-- <p class="label">Filters</p>
        <base-filter name="Locations" :options="filterOptions" @onFilter="selectedFilters = $event" /> -->
      </div>
      <div class="col-4 flex-right">
        <!-- <progress-bar label="Locations Requests Fulfilled" :complete="totalComplete" :total="locationsRecruiting" /> -->
        <progress-bar label="Total Head Count Fulfilled" :complete="totalHeadCountFulfilled" :total="totalHeadCount" />
      </div>
    </div>
    <div class="module location-header">
      <p>Showing {{ filteredList.length }} Locations</p>
    </div>
    <location-enrollment-status  :sticky-header="true" :loading="locationsLoading" :entries="filteredList" />
  </fragment>
</template>

<script>
import DateTime from "luxon/src/datetime";
import { Fragment } from "vue-fragment";
import { mapGetters } from "vuex";
import { ProgressBar } from "@/components";
import LocationEnrollmentStatus from "@/components/LocationEnrollmentStatus";
import { restApi } from "@/http/http.config";

export default {
  name: "OnboardingLocations",
  components: { LocationEnrollmentStatus, ProgressBar, Fragment },
  title: "Onboarding Locations",
  data() {
    return {
      selectedFilters: [],
      dataLocations:[],
      user:{},
      locationsLoading: true,
      existingHCR: []
    };
  },

  methods: {
    approvedCount(location) {
      //why num > denominator
      return location.stageCounts.filter((item) => item.step === "Application Approved").reduce((start, next) => start + next.count, 0);
    },
    startOfWeek() {
      return DateTime.fromISO(this.existingHCR[0]?.startDate)
    },
    requestedCount(location) {
      return location.headCountRequests.length>0 ? location.headCountRequests[0].count : 0
    },
    async fetchLocationsApiCall() {
      await restApi.post(`/employee/onboardingLocations`, { 
        "param": window.btoa(JSON.stringify({
          userID: this.getSelectedEmployee.id,
          startDate: this.startOfWeek()
        }))
      })
      .then(({data}) => {
        this.locationsLoading = false;
        this.user = data.result;
      })
    }
  },
  computed: {
    ...mapGetters(["getUserID", "getSelectedEmployee", "getStartDate", "getEndDate"]),
    filterOptions() {
      return [
        { id: 1, name: "unfulfilledRequests", label: "Unfulfilled Requests", checked: false },
        { id: 2, name: "requestMade", label: "Request Made", checked: false },
        { id: 3, name: "launching", label: "Launching", checked: false },
        { id: 4, name: "lessThan30Days", label: "Less than 30 Days", checked: false },
      ];
    },
    filteredList: function () {
      return this.locations
        ? this.locations.filter((location) => {
            if (this.selectedFilters.includes("unfulfilled requests") && location.recruitingGoal - location.approvedPartners.aggregate.count < 1) return false;
            if (this.selectedFilters.includes("request made") && !location.recruitingGoal > 0) return false;

            const today = DateTime.utc(DateTime.utc().year, DateTime.utc().month, DateTime.utc().day);

            if (this.selectedFilters.includes("launching") && location.startDate > today) return false;
            return !(this.selectedFilters.includes("less than 30 days") && today < location.startDate && location.startDate <= today.plus({ days: 30 }));
          })
        : [];
    },
    totalLocations: function () {
      return this.locations.length;
    },
    locationsWithRequests: function () {
      return this.locations.filter((location) => location.isRecruiting && this.requestedCount(location) > 0).length;
    },
    totalHeadCount: function () {
      return this.locations.reduce((total, location) => total + this.requestedCount(location), 0);
    },
    totalHeadCountFulfilled: function () {
      return this.locations.reduce((total, location) => {
        const approvedCount = this.approvedCount(location);
        return total + approvedCount
      }, 0);
    },
    locations() {
      if (this.user && this.user.employees && this.user.employees.length > 0) {
        const employee = this.user.employees[0];
        return employee.locations;
      }
      return [];
    },
  },
  mounted() {
   localStorage.setItem("fromPage","OandM");
    restApi.get(`/location/getPendingHCR`).then((data) => {
      this.existingHCR = data.data.pendingHCR
      this.fetchLocationsApiCall();
    })
  },
  watch: {
    getSelectedEmployee() {
      this.locationsLoading = true;
      this.fetchLocationsApiCall();
    }
  }
};
</script>
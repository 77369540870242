var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('add-edit-page',{scopedSlots:_vm._u([{key:"header",fn:function(validation){return [_c('sticky-header',{attrs:{"title":"","color":_vm.color}},[_c('div',{staticClass:"sticky-flex-container"},[_c('div',{staticClass:"sticky-header-main"},[_c('h2',[_vm._v("Partner Settlements")]),_c('h4',[_vm._v(_vm._s(_vm.location.name))]),_c('div',{staticClass:"button-group"},[_c('a',{staticClass:"button",attrs:{"data-micromodal-trigger":"modal-submit-settlements","disabled":validation.pristine || validation.invalid},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("Submit Settlements")]),_c('a',{staticClass:"button secondary",on:{"click":function($event){return _vm.save('IN_PROGRESS')}}},[_vm._v("Save for Later")]),_c('a',{staticClass:"button secondary",attrs:{"data-micromodal-trigger":"modal-delete-settlements"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("Delete Settlements")])])]),_c('div',{staticClass:"sticky-header-aside"},[_c('div',{staticClass:"stats"},[_c('div',[_c('h3',[_vm._v("Entries Complete")]),_c('div',{staticClass:"stat"},[_vm._v(_vm._s(_vm.settlement.completeEntries.length))])]),_c('div',[_c('h3',[_vm._v("Total Payout")]),_c('div',{staticClass:"stat right"},[_vm._v(_vm._s(_vm.format(_vm.totalPayout)))])])])])])])]}}])},[_c('div',{staticClass:"module-xlarge"},[_c('div',{staticClass:"has-tooltip-container"},[_c('h3',[_vm._v("Incomplete ("+_vm._s(_vm.settlement.incompleteEntries.length)+" Partners)")]),_c('tooltip',{attrs:{"id":"incomplete-description","title":"Description","description":("Any payments or advances that driver has already received from " + (_vm.location.name) + " during the week."),"hover":""}})],1),_c('data-table',{attrs:{"headers":_vm.incompleteHeaders,"entries":_vm.settlement.incompleteEntries},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName))]}},{key:"lastPay",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.lastPayDay(item)))]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":[function($event){return _vm.openFlyout(item)},function($event){$event.preventDefault();}]}},[_vm._v("Enter Settlement")])]}}])})],1),_c('div',{staticClass:"module-xlarge"},[_c('div',{staticClass:"has-tooltip-container"},[_c('h3',[_vm._v("Complete ("+_vm._s(_vm.settlement.completeEntries.length)+" Partners)")]),_c('tooltip',{attrs:{"id":"complete-description","title":"Description","description":("Any payments or advances that driver has already received from " + (_vm.location.name) + " during the week."),"hover":""}})],1),_c('data-table',{attrs:{"headers":_vm.completeHeaders,"entries":_vm.settlement.completeEntries},scopedSlots:_vm._u([{key:"notes",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"id":("note-" + (item.id)),"title":"Notes","description":item.notes,"icon-class":"icon-file","hover":""}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":[function($event){return _vm.openFlyout(item.id)},function($event){$event.preventDefault();}]}},[_vm._v("Edit")])]}}])})],1),_c('div',{staticClass:"module-xlarge"},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"cell25"}),_c('th',{staticClass:"cell15"},[_vm._v("Total Gross")]),_c('th',{staticClass:"cell15"},[_vm._v("Total Credits")]),_c('th',{staticClass:"cell10"},[_vm._v("Total Debits")]),_c('th',{staticClass:"cell15"},[_vm._v("Other Withholdings")]),_c('th',{staticClass:"cell15"},[_vm._v("Total Payout")]),_c('th',{staticClass:"cell5"})])]),_c('tbody',[_c('tr',[_c('td',[_c('h3',{staticClass:"no-margin"},[_vm._v("Totals")])]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.totalGross)))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.totalCredits)))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.totalDebits)))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.totalWithholdings)))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.totalPayout)))]),_c('td')])])])]),_c('submit-settlements-modal',{attrs:{"settlement":_vm.settlement},on:{"confirm":function($event){return _vm.save('SUBMITTED')}}}),_c('delete-settlements-modal',{attrs:{"location-id":_vm.location.id}}),_c('payout-flyout',{attrs:{"selected-partner":_vm.selectedPartner,"client-id":_vm.location.clientID,"location-id":_vm.location.id},on:{"onSave":_vm.savePayout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import ClientPortalLocations from "@/modules/clientPortal/locations/ClientPortalLocations";
import ClientPortalLocation from "@/modules/clientPortal/locations/ClientPortalLocation";
import { AddEditLayout, ClientPortalLayout } from "@/components";
import LocationSettlements from "@/modules/clientPortal/locations/finances/LocationSettlements";

export default [
  {
    path: "",
    name: "cp-locations",
    component: ClientPortalLocations,
    meta: { layout: ClientPortalLayout },
  },
  {
    path: ":locationID",
    name: "cp-location",
    component: ClientPortalLocation,
    meta: { layout: ClientPortalLayout },
  },
  {
    path: ":locationID/settlements",
    name: "cp-location-settlements",
    component: LocationSettlements,
    meta: { layout: AddEditLayout },
  },
];

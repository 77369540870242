import gql from "graphql-tag";

export const GET_LOCATION_SETTLEMENTS = gql`
  query getLocationDetails($locationID: uuid!) {
    clientLocations_by_pk(id: $locationID) {
      id
      name
      color
      clientID
      client {
        color
        id
      }
      partners {
        id
        partner {
          id
          firstName
          lastName
          payouts(order_by: { endDate: desc }, limit: 1) {
            endDate
          }
        }
      }
    }
  }
`;

export const GET_FINANCE_TEMPLATE = gql`
  query findFinanceTemplate($partnerID: uuid, $locationID: uuid, $clientID: uuid!) {
    financeTemplates(
      where: {
        _or: [
          { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _is_null: true } }, { locationPartnerID: { _is_null: true } }] }
          { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _eq: $locationID } }, { locationPartnerID: { _is_null: true } }] }
          { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _eq: $locationID } }, { locationPartnerID: { _eq: $partnerID } }] }
        ]
      }
    ) {
      id
      clientID
      locationID
      locationPartnerID
      payPeriod {
        partnerSettlementDay
        payCycle
        paymentProcessor
        startDay
      }
      setttlementItems {
        id
        additionalData
        billTo
        category
        description
        settlementItemType
        name
        taxSetting
        transactionType
      }
    }
  }
`;

<template>
  <div class="module-form">
    <h3>Basic Info</h3>
    <form-input :value="value.name" :name="nameLabel" :label="nameLabel" required @input="update('name', $event)" />
    <p  class="error-message" v-if="locationExists">Zone already exists</p>

    <form-select
      :value="value.timeZone"
      label="Time Zone"
      name="Time Zone"
      :options="timeZones"
      option-key="value"
      option-value="value"
      option-label="displayString"
      :required="true"
      @input="update('timeZone', $event)"
    />
    <div class="field">
      <date-pick :value="value.startDate" display-format="MM/DD/YYYY" :input-attributes="{ readonly: true }" @input="update('startDate', $event)">
        <template #default="{ inputValue, toggle }">
          <validation-provider v-slot="{ errors }" name="Start Date" mode="eager" rules="required">
            <label for="startDate">Start Date*</label>
            <div class="input-has-icon">
              <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
              <input id="startDate" :value="inputValue" type="text" name="Start Date" placeholder readonly required @click="toggle" />
              <p class="error-message">{{ errors[0] }}</p>
            </div>
          </validation-provider>
        </template>
      </date-pick>
    </div>
    <form-input :value="value.phone" :mask="true" name="Office Phone" label="Public/Office Phone" required rules="required|phone" type="phone" @input="update('phone', $event)" />
  </div>
</template>
<script>
import { timeZones } from "@/util/timeZones";
import { ValidationProvider } from "vee-validate";
import DatePick from "vue-date-pick";
import { FormSelect, FormInput } from "@/components";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "BasicInfo",
  components: { FormInput, FormSelect, ValidationProvider, DatePick },
  props: {
    value: { type: Object, required: true, default: () => {} },
    nameLabel: { type: String, required: false, default: "Client Name" },
    // finalOne : {type: Boolean}

  },
  data() {
    return {
      timeZones: timeZones,
      locationExists:false
    };
  },
  methods: {
    async update(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });

      if(key=='name'){
        let param = {
          name : value
        };
       await restApi.post(`/location/getLocationNames`, encodeWithParam(param)).then((data) => {
        // console.log(data.data.isExists,"sdsdsdsdsds")
        if(data.data.isExists==true){
         this.locationExists=true
        //  console.log(this.locationExists,"error222")
        }else{
          this.locationExists=false
        }
      })
      }
      this.$emit('name-event', this.locationExists)

    },
  },
};
</script>
